export const REPORTS = [
  {
    title: 'Sent SMS',
    iconClass: 'bx bx-check',
    key: 'totalSentMessages',
    description: '0',
    selectCardName: 'Sent',
    percentageKey: 'sentPercentage',
    iconSize: '35px',
    iconColor: '#6c757d', // Gray color for success
  },
  {
    title: 'Delivered SMS',
    iconClass: 'bx bx-check-double',
    key: 'totalDeliveredMessages',
    description: '0',
    selectCardName: 'Delivered',
    percentageKey: 'deliveredPercentage',
    iconSize: '35px',
    iconColor: '#6c757d', // Gray color for delivered
  },
  {
    title: 'Read SMS',
    iconClass: 'bx bx-check-double',
    key: 'totalReadMessages',
    description: '0',
    selectCardName: 'Read',
    percentageKey: 'readPercentage',
    iconSize: '35px',
    iconColor: '#28a745', // Green color for read
  },
  {
    title: 'Failed SMS',
    iconClass: 'bx bx-x',
    key: 'totalFailedMessages',
    description: '0',
    selectCardName: 'Failed',
    percentageKey: 'failedPercentage',
    iconSize: '35px',
    iconColor: '#dc3545', // Red color for failed
  },
  {
    title: 'Queued SMS',
    iconClass: 'bx bx-plus',
    key: 'totalQueuedMessages',
    description: '0',
    selectCardName: 'Queued',
    percentageKey: 'queuedPercentage',
    iconSize: '35px',
    iconColor: '#ffc107', // Yellow color for queued
  },
  {
    title: 'Not Respond',
    iconClass: 'bx bx-time',
    key: 'totalNotRespondMessages',
    description: '0',
    selectCardName: 'Not_Respond',
    percentageKey: 'notRespondPercentage',
    iconSize: '30px',
    iconColor: '#6c757d', // Gray color for not responded
  },
  {
    title: 'Success Respond',
    iconClass: 'bx bx-check-circle',
    key: 'totalSuccessRespondMessages',
    description: '0',
    selectCardName: 'Success_Respond',
    percentageKey: 'successRespondPercentage',
    iconSize: '30px',
    iconColor: '#28a745', // Green color for success response
  },
];
export const ANALYTICS_TYPES = {
  DELIVERY: 'delivery',
  REPLY: 'reply',
  TAG: 'tag',
};

export const BROADCAST_OVERVIEW = [
  {
    title: 'Broadcast Name',
    iconClas: 'fas fa-bullhorn',
    iconSize: '16px',
    key: 'broadcastName',
  },
  {
    title: 'Message Template',
    iconClas: 'bx bx-news',
    iconSize: '20px',
    key: 'broadcastTemplateName',
  },
  {
    title: 'Audience',
    iconClas: 'bx bxs-group',
    iconSize: '20px',
    key: 'audience',
  },
  {
    title: 'Total Credit Usage',
    iconClas: 'bx bx-dollar-circle',
    iconSize: '20px',
    key: 'totalCreditUsage',
  },
];

export const FILTER_OPTIONS = {
  Sent: [],
  Delivered: [
    { id: 'all', label: 'All' },
    { id: 'hasRead', label: 'Has Read' },
    { id: 'notRead', label: 'Not Read' },
  ],
  Read: [
    { id: 'all', label: 'All' },
    { id: 'hasClicked', label: 'Has Clicked' },
    { id: 'notClicked', label: 'Not Clicked' },
    { id: 'hasReplied', label: 'Has Replied' },
    { id: 'notReplied', label: 'Not Replied' },
  ],
  Failed: [],
  Queued: [],
  Not_Respond: [],
  Success_Respond: [
    { id: 'all', label: 'All' },
    { id: '1hour', label: '1 Hour' },
    { id: '3hours', label: '3 Hours' },
    { id: '24hours', label: '24 Hours' },
    { id: '48hours', label: '48 Hours' },
  ],

};

export const CARD_FILTER = {
  SENT: 'Sent',
  DELIVERED: 'Delivered',
  READ: 'Read',
  FAILED: 'Failed',
  QUEUED: 'Queued',
  NOT_RESPOND: 'Not_Respond',
  SUCCESS_RESPOND: 'Success_Respond',
};

export const CARD_FILTER_TYPES = {
  HAS_READ: 'hasRead',
  NOT_READ: 'notRead',
  HAS_REPLIED: 'hasReplied',
  NOT_REPLIED: 'notReplied',
  REPLIED_1H: '1hour',
  REPLIED_3H: '3hours',
  REPLIED_24H: '24hours',
  REPLIED_48H: '48hours',
};

export const DATE_RANGE_OPTIONS = [
  { value: 6, label: 'Last 7 days' },
  { value: 14, label: 'Last 15 days' },
  { value: 29, label: 'Last 1 month' },
  { value: 59, label: 'Last 2 months' },
];
