import styled from 'styled-components';
import { Card } from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import ChannelCard from './ChannelCard';

const StyledCard = styled(Card)`
  .p-card-body {
    padding: 1.5rem;
  }
`;

function CategorySection({ category, channels, channelConfig }) {
  return (
    <div className="p-grid">
      <h4 className="mb-2 font-size-17">{category}</h4>
      <div className="p-col-12 p-md-6 p-lg-4">
        <StyledCard>
          <div className="d-flex" style={{ gap: '5rem' }}>
            {channels.map((channelType) => {
              const config = channelConfig[channelType];
              return (
                <ChannelCard
                  key={channelType}
                  icon={config.type === 'icon' ? `${config.icon} ${config.iconClass}` : config.icon}
                  bgClass={config.bgClass}
                  name={config.name}
                  type={config.type}
                  isConnected={config.isConnected}
                  connecting={config.disabled}
                  onClick={config.handleConnection}
                  buttonText={config.getButtonText()}
                  buttonClass={config.buttonClass}
                  customStyle={config.buttonStyle}
                />
              );
            })}
          </div>
        </StyledCard>
      </div>
    </div>
  );
}
CategorySection.propTypes = {
  category: PropTypes.string.isRequired, // The category title
  channels: PropTypes.arrayOf(PropTypes.string).isRequired, // An array of channel types
  channelConfig: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['icon', 'image']).isRequired, // Type of icon
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Icon (className or image URL)
      iconClass: PropTypes.string, // Additional class for icon
      bgClass: PropTypes.string.isRequired, // Background class
      name: PropTypes.string.isRequired, // Channel name
      isConnected: PropTypes.bool.isRequired, // Connection status
      disabled: PropTypes.bool, // Whether the button is disabled
      handleConnection: PropTypes.func.isRequired, // Connection handler
      getButtonText: PropTypes.func.isRequired, // Function to get button text
      buttonClass: PropTypes.string.isRequired, // Button class
      buttonStyle: PropTypes.object, // Inline styles for the button
    }),
  ).isRequired, // Config for each channel
};

export default CategorySection;
