/* eslint-disable import/prefer-default-export */
import { SIZE_PER_PAGE, PAGE } from '../../constants/constants';
import { axiosGet, axiosPost } from '../http.service';

export const getSenderGroups = async ({
  page = PAGE,
  limit = SIZE_PER_PAGE,
  searchQuery = '',
  sortBy = 'createdAt:desc',
}) => {
  try {
    const response = await axiosGet(`sender-groups?page=${page}&limit=${limit}${
      searchQuery ? `&q=${searchQuery}` : ''
    }&sortBy=${sortBy}`);
    return response;
  } catch (error) {
    console.error('error at get sender groups :', error);
    throw error;
  }
};

export const getTemplates = async ({
  page = PAGE,
  limit = SIZE_PER_PAGE,
  searchQuery = '',
  sortBy = 'createdAt:desc',
}) => {
  try {
    const response = await axiosGet(`message-template?page=${page}&limit=${limit}${
      searchQuery ? `&q=${searchQuery}` : ''
    }&sortBy=${sortBy}`);
    return response;
  } catch (error) {
    console.error('error at get sender groups :', error);
    throw error;
  }
};

// Function to exchange WhatsApp token
export const createBulkCampaign = async (data) => {
  try {
    const response = await axiosPost('campaign/bulk-sms', data);
    return response;
  } catch (error) {
    console.error('error at get sender groups :', error);
    throw error;
  }
};

export const getTextMessageTemplate = async () => {
  try {
    const response = await axiosGet('message-template');
    return response;
  } catch (error) {
    console.error('error at get sender groups :', error);
    throw error;
  }
};
