import React from 'react';
import { CardTitle, Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import UserSelect from '../../components/Common/UserSelect';
import { UserContext } from '../../components/UserProvider/UserProvider';
import MultiSelect from '../Campaign/Analytics/Dropdown/MultiSelect';
import DateRangeSelector from './DateRangeSelector';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';
import { ROLES } from '../../constants/users/role.constant';

class FilterComponent extends React.Component {
  static contextType = UserContext;

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props;
    if (prevProps.activeTab !== activeTab) {
      if (this.formikRef) {
        this.formikRef.resetForm();
      }
    }
  }

  handleApplyFilter = (values) => {
    const { onApplyFilter } = this.props;
    // Pass the current filter criteria to the parent component
    onApplyFilter(values);
  };

  setFormikRef = (ref) => {
    this.formikRef = ref;
  };

  render() {
    const { showCampaigns, initialFilters, activeTab } = this.props;
    const { userData } = this.context;
    return (
      <Formik
        initialValues={{
          selectedCampaigns: initialFilters.campaigns,
          selectedUser: initialFilters.user || null,
          startDate: initialFilters.startDate,
          endDate: initialFilters.endDate,
        }}
        onSubmit={(values) => {
          this.handleApplyFilter(values);
        }}
        onReset={() => {
          this.handleApplyFilter({
            selectedCampaigns: [],
            selectedUser: {},
            startDate: moment().subtract(30, DATE_CONSTANTS.UNITS.DAYS).startOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
            endDate: moment().endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
          });
        }}
        innerRef={this.setFormikRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <CardTitle tag="h5">Filter</CardTitle>
              <div className="date-picker-container">
                <DateRangeSelector
                  startDate={values.startDate}
                  endDate={values.endDate}
                  onDateChange={([startDate, endDate]) => {
                    setFieldValue('startDate', startDate);
                    setFieldValue('endDate', endDate);
                  }}
                />
              </div>
            </div>
            {showCampaigns && (
              <div className="mb-3">
                <label htmlFor="campaign-select">Campaigns</label>
                <MultiSelect
                  fetchAllCampaigns={false}
                  channel={activeTab}
                  selectedOptions={values.selectedCampaigns}
                  onChange={(selectedCampaigns) => setFieldValue('selectedCampaigns', selectedCampaigns)}
                  className="campaign-select"
                />
              </div>
            )}
            {userData.role === ROLES.ADMIN && (
              <div className="mb-3">
                <label htmlFor="user-select" className="me-3 font-size-14">
                  User
                </label>
                <div className="user-select-container">
                  <UserSelect className="user-select" onChange={(selectedUser) => setFieldValue('selectedUser', selectedUser)} selectedOptions={values.selectedUser} />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit" className="me-2">
                <i className="bx bx-filter me-1 font-size-15" />
                Apply Filter
              </Button>
              <Button color="secondary" type="reset">
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

FilterComponent.propTypes = {
  onApplyFilter: PropTypes.func,
  showCampaigns: PropTypes.bool,
  initialFilters: PropTypes.object,
  activeTab: PropTypes.string,
  // 'initialFilters.campaigns': PropTypes.array,
  // 'initialFilters.user': PropTypes.object,
  // 'initialFilters.startDate': PropTypes.string,
  // 'initialFilters.endDate': PropTypes.string,
};

export default FilterComponent;
