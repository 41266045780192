import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PageLoader from '../Common/PageLoader';
import { changeSidebarTheme as changeSidebarThemeAction, toggleCallVisibility } from '../../store/actions';
import { UserContext } from '../UserProvider/UserProvider';
import { getDataFromLocalStorage, setDataInLocalStorage } from '../../helpers/commonHelpers';
// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import CallFeatureEnable from '../../pages/Dashboard/models/CallFeatureEnable';
import { getChannels } from '../../utils/channels';
import { CHANNEL_CONNECT_STATUS } from '../../constants/channels/channels.constant';

function Layout({
  isPreloader,
  leftSideBarTheme,
  leftSideBarType,
  children,
}) {
  const dispatch = useDispatch();
  const showLoader = useSelector((state) => state.Dashboard.showLoader);
  const [isMobile, setIsMobile] = useState(false);
  const { userData } = useContext(UserContext);
  const isCallVisible = useSelector((state) => state.onboarding.isCallVisible);
  const [callFeatureModalShown] = useState(getDataFromLocalStorage('callFeatureModalShown'));
  const [isChannelConnected, setIsChannelConnected] = useState(false);

  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    const handleResize = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);

    if (leftSideBarTheme) {
      dispatch(changeSidebarThemeAction(leftSideBarTheme));
    }
  }, [isPreloader, leftSideBarTheme, dispatch]);

  const toggleMenuCallback = () => {
    const { body } = document;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  };

  useEffect(() => {
    const channels = userData?.organization?.channels;
    const channelsStatus = getChannels(channels);
    const ChannelConnected = Object.values(channelsStatus).some((channel) => channel?.status === CHANNEL_CONNECT_STATUS.CONNECTED);
    setIsChannelConnected(ChannelConnected);
    // If the modal has not been shown before and there are no channels, show it
    if (!callFeatureModalShown && !ChannelConnected) {
      dispatch(toggleCallVisibility(true));

      // Set a flag in localStorage to remember that the modal has been shown
      setDataInLocalStorage('callFeatureModalShown', 'true');
    } else {
      dispatch(toggleCallVisibility(false));
    }
  }, [isChannelConnected, callFeatureModalShown, userData?.organisation?.channels, dispatch]);

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        {showLoader && <PageLoader />}
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        {isCallVisible && (
          <CallFeatureEnable
            show={isCallVisible}
            onClose={() => {
              dispatch(toggleCallVisibility(false));
            }}
          />
        )}
        <div className="main-content">{children}</div>
        <Footer />
      </div>
    </>
  );
}

Layout.propTypes = {
  isPreloader: PropTypes.bool,
  leftSideBarTheme: PropTypes.string,
  leftSideBarType: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
