import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Badge, Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import DataGrid from '../../components/DataTable';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import DeleteModal from '../../components/Common/DeleteModal';
import { axiosDel, axiosGet } from '../../services/http.service';

import {
  setShowReassignNumberModal,
  setShowWhatsappNumberModal,
} from '../../store/phone-number/actions';
import { UserContext } from '../../components/UserProvider/UserProvider';
import { setLoader } from '../../store/actions';
import ManageWhatsappNumber from './Modal/ManageWhatsappNumber';
import ReassignNumber from './Modal/ReassignNumber';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import { ROLES } from '../../constants/users/role.constant';
import { CHANNEL_TYPES } from '../../constants/channels/channels.constant';
import { NUMBER_STATUS } from '../../constants/status.constant';

class WhatsappNumber extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      numbers: [],
      number: '',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      whatsappNumberColumns: [
        {
          header: 'No',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const index = row?.id;
            return <span className="mb-1">{parseInt(index, 10) + 1}</span>;
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          Cell: ({ row }) => {
            const status = row?.original?.status;
            return (
              <Badge
                color={status === NUMBER_STATUS.ACTIVE ? 'success-subtle' : 'danger-subtle'}
                pill
              >
                <span className={`${status === NUMBER_STATUS.ACTIVE ? 'text-success' : 'text-danger'}`}>{status}</span>
              </Badge>
            );
          },
        },
        {
          header: 'Phone Number',
          accessorKey: 'phoneNumber',
          Cell: ({ renderedCellValue, row }) => {
            const originalRow = row?.original;
            return (
              <div>
                <div className="fw-medium">{originalRow.phoneAlias}</div>
                <PhoneNumberWithFlag phoneNumber={renderedCellValue} />
              </div>
            );
          },
        },
        {
          header: 'Phone Number Id',
          accessorKey: 'numberId',
        },
        {
          header: 'Assigned User',
          accessorKey: 'assignedUserIds',
          Cell: ({ row }) => {
            const originalRow = row?.original;
            return (
              <div>
                {originalRow?.assignedUserIds?.map((assignedUser, index) => (
                  <Badge
                    key={`${assignedUser?.id}-${index + 1}`}
                    color="info"
                    className="ms-2"
                    pill
                  >
                    {assignedUser?.name}
                  </Badge>
                ))}
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page, whatsappNumberColumns } = this.state;
    this.handleGetWhatsappNumbers(page);
    const { userData } = this.context;

    if (userData?.role === ROLES.ADMIN) {
      this.setState({
        whatsappNumberColumns: [
          ...whatsappNumberColumns,
          {
            accessorKey: 'menu',
            header: 'Actions',
            enableSorting: false,
            Cell: ({ row }) => {
              const numberInfo = row?.original;
              const isInactive = numberInfo?.status === NUMBER_STATUS.INACTIVE;
              return (
                <div className="d-flex gap-3">
                  <Badge
                    color="success-subtle"
                    className={`text-success ${isInactive ? 'opacity-50' : ''}`}
                    style={{ cursor: isInactive ? 'not-allowed' : 'pointer' }}
                  >
                    <i
                      className="mdi mdi-redo-variant font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="re-assign number"
                      onClick={() => !isInactive && this.handleUserClick(numberInfo)}
                    />
                  </Badge>

                  <Badge
                    color="danger-subtle"
                    className={`text-danger ${isInactive ? 'opacity-50' : ''}`}
                    style={{ cursor: isInactive ? 'not-allowed' : 'pointer' }}
                  >
                    <i
                      className="mdi mdi-trash-can-outline font-size-18 text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="delete number"
                      onClick={() => !isInactive && this.onClickDelete(numberInfo)}
                    />
                  </Badge>
                </div>
              );
            },
          },
        ],
      });
    }
  }

  // toggle = () => {
  //   const { onSetShowWhatsappNumberModal } = this.props;
  //   onSetShowWhatsappNumberModal();
  // };

  toggleReassignNumberModal = () => {
    const { onSetShowReassignNumberModal } = this.props;
    onSetShowReassignNumberModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (numberInfo) => {
    this.setState({ number: numberInfo, deleteModal: true });
  };

  // handles when user clicks buy number btn
  // handleUserClicks = () => {
  //   this.setState({ number: '' });
  //   this.toggle();
  // };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetWhatsappNumbers(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetWhatsappNumbers(1, newPageSize);
    });
  };

  // handles when user tries to re-assign a number to another user
  handleUserClick = (numberInfo) => {
    this.setState({
      number: numberInfo,
    });

    this.toggleReassignNumberModal();
  };

  // to get list of all whatsapp numbers
  handleGetWhatsappNumbers = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      onSetLoader(true);
      const numbers = await axiosGet(
        `whatsapp-numbers?populate=assignedUserIds&page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (numbers?.status) {
        this.setState({
          numbers: numbers?.data?.results,
          totalSize: numbers?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleGetWhatsappNumbers :', error);
    }
  };

  // to release a twilio number
  handleWhatsappNumberDeletion = async () => {
    try {
      const { number, page } = this.state;
      const response = await axiosDel(`whatsapp-numbers/${number?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Whatsapp Number deleted successfully', {
          position: 'top-right',
        });
        this.handleGetWhatsappNumbers(page);
      } else {
        toast.error(response?.message || 'Oops! Something went wrong', {
          position: 'top-right',
        });
      }
      this.toggleDeleteModal();
    } catch (error) {
      console.error('error at handleWhatsappNumberDeletion :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetWhatsappNumbers(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'Phone Numbers';

    // const { userData } = this.context;
    const { activeTab } = this.props;
    const {
      deleteModal, numbers, number, page, whatsappNumberColumns, totalSize, selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleWhatsappNumberDeletion()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ManageWhatsappNumber
          getAllNumbers={() => {
            this.handleGetWhatsappNumbers(page);
          }}
          activeTab={activeTab}
        />
        <ReassignNumber
          channeltype={CHANNEL_TYPES.WHATSAPP}
          numberInfo={number}
          getAllNumbers={() => {
            this.handleGetWhatsappNumbers(page);
          }}
        />
        <div className="page-content pt-0">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <Col sm="12" className="d-flex justify-content-end align-items-center">
                      {userData?.role === ROLES.ADMIN ? (
                        <div className="text-sm-end mb-3">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={this.handleUserClicks}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add Whatsapp Number
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col> */}
                    <div className="mt-3">
                      <DataGrid
                        data={numbers}
                        columns={whatsappNumberColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

WhatsappNumber.propTypes = {
  // onSetShowWhatsappNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  onSetShowReassignNumberModal: PropTypes.func,
  activeTab: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = ({ phoneNumber }) => ({
  showReassignNumberModal: phoneNumber.showReassignNumberModal,
});

const mapDispatchToProps = (dispatch) => ({
  onSetShowWhatsappNumberModal: () => dispatch(setShowWhatsappNumberModal()),
  onSetShowReassignNumberModal: () => dispatch(setShowReassignNumberModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WhatsappNumber));
