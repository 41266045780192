import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col, Row, Modal, ModalHeader, ModalBody, Label,
} from 'reactstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { axiosPatch, axiosPost } from '../../../../services/http.service';
import ModalLoader from '../../../../components/Common/ModalLoader';
import { setShowContactFormModal } from '../../../../store/contacts/actions';
import PhoneInputComponent from '../../../../components/PhoneInput';
import { addContactsToGroup } from '../../../../services/api/contactGroups.service';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
    };
  }

  toggle = () => {
    const { onSetShowContactFormModal } = this.props;
    onSetShowContactFormModal();
  };

  createNewContact = async (contactInfo) => {
    const { match } = this.props;
    try {
      this.setState({ showModalLoader: true });
      const { getAllContacts } = this.props;
      const { groupId } = match.params;
      let response;
      if (groupId) {
        response = await addContactsToGroup(groupId, {
          contacts: contactInfo,
        });
      } else {
        response = await axiosPost('contacts', contactInfo[0]);
      }
      if (response?.status) {
        getAllContacts();
        this.toggle();
      }
    } catch (error) {
      console.error('error while creating a contact :', error);
    } finally {
      this.setState({ showModalLoader: false });
    }
  };

  handleEditContact = async (contactId, updatedData) => {
    try {
      this.setState({ showModalLoader: true });
      const { editContactInbox, getAllContacts, onContactUpdate } = this.props;
      const response = await axiosPatch(`contacts/${contactId}`, updatedData);
      if (response?.status) {
        toast.success(response?.message || 'Contact updated successfully!', {
          position: 'top-right',
        });
        if (!editContactInbox) {
          getAllContacts();
        }
        if (onContactUpdate) {
          onContactUpdate(response.data); // Call the callback with updated contact data
        }
        this.toggle();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error('error while updating a contact :', error);
    }
  };

  onSubmit = (values) => {
    const { isEdit, contact } = this.props;
    const contactInfo = {
      countryCode: values?.countryCode,
      phoneNumber: `${values?.phoneNumber}`,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      address: values?.address,
      zip: `${values?.zip}`,
      state: values?.state,
      city: values?.city,
    };
    if (!isEdit) {
      this.createNewContact([contactInfo]);
    } else {
      // Update existing contact

      // Assuming existingContactInfo is the object representing the current contact's details
      const updatedFields = {};

      // Compare each field and update only if it's different
      Object.entries(contactInfo).forEach(([key, value]) => {
        if (value !== contact[key]) {
          updatedFields[key] = value;
        }
      });

      if (!_.isEmpty(updatedFields)) {
        this.handleEditContact(contact?.id, updatedFields);
      } else {
        this.toggle();
      }
    }
  };

  render() {
    const {
      isEdit, showContactFormModal, contact, editContactInbox, className,
    } = this.props;

    const { showModalLoader } = this.state;

    const initialValues = {
      countryCode: contact?.countryCode || '',
      phoneNumber: contact?.phoneNumber?.replace(/[^\d]/g, '') || '',
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
      email: contact?.email || '',
      address: contact?.address || '',
      zip: contact?.zip || '',
      state: contact?.state || '',
      city: contact?.city || '',
    };

    const validationSchema = Yup.object().shape({
      countryCode: Yup.string().required('Please select county code'),
      phoneNumber: Yup.number().required('Please enter the contact number'),
      firstName: Yup.string().required('Please enter the first name '),
      lastName: Yup.string().required('Please enter the last name '),
      email: Yup.string().email().required('Please enter your email address'),
      address: Yup.string(),
      zip: Yup.number(),
      state: Yup.string(),
      city: Yup.string(),
    });

    return (
      <Modal isOpen={showContactFormModal} className={className}>
        {showModalLoader ? <ModalLoader /> : ''}
        <ModalHeader toggle={this.toggle} tag="h4">
          {isEdit ? 'Edit Contact' : 'Add Contact'}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
          >
            {({
              errors, touched, setFieldValue, values,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    {!editContactInbox && (
                    <div className="mb-3">
                      <Label className="form-label">Contact Number</Label>
                      <div className="d-flex me-2">
                        <PhoneInputComponent
                          name="phoneNumber"
                          value={`${values.countryCode}${values.phoneNumber}`} // Replace with the value from Formik's values object
                          handleChangePhoneNumber={(phoneNumber, countryCode) => {
                            setFieldValue('phoneNumber', phoneNumber);
                            setFieldValue('countryCode', countryCode);
                          }}
                          placeholder="Enter your phone number"
                          required
                          isError={errors.phoneNumber && touched.phoneNumber} // Conditionally apply error state
                          errorText={errors.phoneNumber} // Display error message if present
                          defaultCountry="US"
                          inputStyle={{ width: '100%', maxHeight: '100%' }} // Adjust styles via props or directly in styled component
                          parseNumber
                          disableDialCodePrefill
                        />
                      </div>
                    </div>
                    )}
                    <div className="mb-3">
                      <Label className="form-label">First name</Label>
                      <Field
                        name="firstName"
                        type="text"
                        className={`form-control${
                          errors.firstName && touched.firstName ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Last name</Label>
                      <Field
                        name="lastName"
                        type="text"
                        className={`form-control${
                          errors.lastName && touched.lastName ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Field
                        name="email"
                        type="email"
                        className={`form-control${
                          errors.email && touched.email ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Address</Label>
                      <Field
                        name="address"
                        type="text"
                        className={`form-control${
                          errors.address && touched.address ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="address" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Zip Code</Label>
                      <Field
                        name="zip"
                        type="number"
                        className={`form-control${errors.zip && touched.zip ? ' is-invalid' : ''}`}
                      />
                      <ErrorMessage name="zip" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">State</Label>
                      <Field
                        name="state"
                        type="text"
                        className={`form-control${
                          errors.state && touched.state ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="state" component="div" className="invalid-feedback" />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">City</Label>
                      <Field
                        name="city"
                        type="text"
                        className={`form-control${
                          errors.city && touched.city ? ' is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage name="city" component="div" className="invalid-feedback" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user">
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

ContactForm.propTypes = {
  className: PropTypes.any,
  onSetShowContactFormModal: PropTypes.func,
  contact: PropTypes.object,
  match: PropTypes.any,
  getAllContacts: PropTypes.func,
  isEdit: PropTypes.bool,
  editContactInbox: PropTypes.bool,
  showContactFormModal: PropTypes.bool,
  onContactUpdate: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowContactFormModal: () => dispatch(setShowContactFormModal()),
});

const mapStateToProps = ({ contacts }) => ({
  showContactFormModal: contacts.showContactFormModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactForm));
