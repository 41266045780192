import { SHOW_TWILIO_CREDENTIALS_MODAL, SHOW_WHATSAPP_CREDENTIALS_MODAL, SHOW_OPENAI_CREDENTIALS_MODAL } from './actionTypes';

export const setShowTwilioCredentialsModal = () => ({
  type: SHOW_TWILIO_CREDENTIALS_MODAL,
});

export const setShowWhatsappCredentialsModal = () => ({
  type: SHOW_WHATSAPP_CREDENTIALS_MODAL,
});

export const setShowOpenAiCredentialsModal = () => ({
  type: SHOW_OPENAI_CREDENTIALS_MODAL,
});
