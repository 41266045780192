import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNumber } from 'lodash';
import { setContactGroupsOfStep3, setLoader, setStepThreeData } from '../../../../../store/actions';
import * as contactGroupServices from '../../../../../services/api/contactGroups.service';
import Label from '../../../../../components/Label';
import IconText from '../../../../../components/IconText';
import Card from '../../../../../components/Card';
import DataGrid from '../../../../../components/DataTable';
import { renderUserAvatar } from '../../../../../helpers/commonHelpers';
import { DATE_CONSTANTS } from '../../../../../constants/datetime.constant';

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const StyledSpan = styled.span`
  font-size: 1.1rem;
`;

const ViewCount = styled.div`
  cursor: pointer;
  color: ${({ $countFounded }) => ($countFounded ? '' : 'var(--blue-600)')};
`;

ViewCount.propTypes = {
  $countFounded: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const RowPropType = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contactCount: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

const RenderedValuePropType = {
  renderedCellValue: PropTypes.string.isRequired,
};

function NumberOfAudienceCell({ row, onViewCount }) {
  const currentRowData = row?.original;
  return (
    <ViewCount
      onClick={() => {
        if (!isNumber(currentRowData?.contactCount)) {
          onViewCount(currentRowData?.id);
        }
      }}
      $countFounded={isNumber(currentRowData?.contactCount)}
    >
      {currentRowData?.contactCount ?? 'View count'}
    </ViewCount>
  );
}

NumberOfAudienceCell.propTypes = {
  ...RowPropType,
  onViewCount: PropTypes.func.isRequired,
};

function CreatedByCell({ renderedCellValue }) {
  return renderUserAvatar(renderedCellValue, 'me-3');
}

function CreatedAtCell({ renderedCellValue }) {
  const formattedDate = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
  const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
  return (
    <div>
      <IconText icon="bx bx-calendar" text={formattedDate} />
      <IconText icon="bx bx-time" text={formattedTime} />
    </div>
  );
}

CreatedByCell.propTypes = RenderedValuePropType;
CreatedAtCell.propTypes = RenderedValuePropType;

function Step3({ formCompletion, contactGroupId }) {
  const dispatch = useDispatch();

  const {
    selectedContactGroup,
    contactGroups,
    totalSize,
    page: initialPage,
    size: initialSize,
    searchValue: initialSearchValue,
  } = useSelector((state) => state.bulkWhatsapp.step3);

  const [state, setState] = useState({
    page: initialPage,
    size: initialSize,
    searchValue: initialSearchValue,
  });

  const getSelectedRows = useMemo(() => {
    if (!selectedContactGroup?.id) return {};

    const selectedIndex = contactGroups.findIndex(
      (group) => group.id === selectedContactGroup.id,
    );

    return selectedIndex !== -1 ? { [selectedIndex]: true } : {};
  }, [selectedContactGroup, contactGroups]);

  const handleViewCountClick = useCallback(async (id) => {
    try {
      dispatch(setLoader(true));
      const response = await contactGroupServices.getContactCount(id);

      if (response?.status) {
        const updatedContactGroups = contactGroups.map((group) => (
          group.id === id
            ? { ...group, contactCount: response.data.contactCount }
            : group
        ));

        dispatch(setContactGroupsOfStep3({ contactGroups: updatedContactGroups }));

        if (selectedContactGroup?.id === id) {
          dispatch(setStepThreeData({
            selectedContactGroup: {
              ...selectedContactGroup,
              contactCount: response.data.contactCount,
            },
          }));
        }
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('Error at handleViewCountClick:', error);
    } finally {
      dispatch(setLoader(false));
    }
  }, [contactGroups, dispatch, selectedContactGroup]);

  const contactListColumns = useMemo(() => [
    {
      header: 'Segment Name',
      accessorKey: 'name',
    },
    {
      header: 'Created By',
      accessorKey: 'assignedUserName',
      Cell: CreatedByCell,
    },
    {
      header: 'Number of Audience',
      accessorKey: 'contactIds',
      Cell: (props) => <NumberOfAudienceCell {...props} onViewCount={handleViewCountClick} />,
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      Cell: CreatedAtCell,
    },
  ], [handleViewCountClick]);

  const handleContactGroupSelection = useCallback((selectedRows) => {
    const selectedGroup = selectedRows?.[0];

    if (selectedGroup) {
      dispatch(setStepThreeData({ selectedContactGroup: selectedGroup }));
      formCompletion(true);
    } else {
      dispatch(setStepThreeData({ selectedContactGroup: null }));
      formCompletion(false);
    }
  }, [dispatch, formCompletion]);

  const handleGetAllContactGroups = useCallback(async () => {
    const { searchValue, page, size } = state;

    try {
      dispatch(setLoader(true));
      const response = await contactGroupServices.getAllContactGroupsInPages(
        page,
        size,
        searchValue,
      );

      if (response?.status) {
        const newContactGroups = response.data.results || [];
        dispatch(setContactGroupsOfStep3({
          contactGroups: newContactGroups,
          totalSize: response.data.totalPages || 0,
        }));

        return newContactGroups; // Return the groups for use in other functions
      }
      toast.error(response?.message || 'Oops! Something went wrong');
      return null;
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('Error at handleGetAllContactGroups:', error);
      return null;
    } finally {
      dispatch(setLoader(false));
    }
  }, [state, dispatch]);

  const handleSearch = useCallback((searchValue) => {
    setState((prev) => ({ ...prev, searchValue, page: 1 }));
    dispatch(setContactGroupsOfStep3({ searchValue, page: 1 }));
  }, [dispatch]);

  const handlePageChange = useCallback((newPage) => {
    setState((prev) => ({ ...prev, page: newPage }));
    dispatch(setContactGroupsOfStep3({ page: newPage }));
  }, [dispatch]);

  const handleSizeChange = useCallback((newSize) => {
    setState((prev) => ({ ...prev, size: newSize, page: 1 }));
    dispatch(setContactGroupsOfStep3({ page: 1, size: newSize }));
  }, [dispatch]);

  // Effect to handle initial load and contactGroupId changes
  useEffect(() => {
    const updateSelection = async () => {
      let groups = contactGroups;

      // If we don't have contact groups, fetch them
      if (!groups?.length) {
        groups = await handleGetAllContactGroups();
      }

      const effectiveContactGroupId = contactGroupId || (groups[0]?.id);
      // Only proceed with selection if we have a contactGroupId
      if (effectiveContactGroupId) {
        const groupToSelect = groups.find((group) => group.id === effectiveContactGroupId);
        if (groupToSelect) {
          handleContactGroupSelection([groupToSelect]);
        }
      }
    };

    updateSelection();
  }, [contactGroupId, handleGetAllContactGroups, handleContactGroupSelection, dispatch, contactGroups]);

  return (
    <StyledCard>
      <div className="mb-3">
        <Label className="form-label mb-3">
          <StyledSpan>Select Contact Group</StyledSpan>
        </Label>
        <DataGrid
          data={contactGroups}
          columns={contactListColumns}
          enableColumnResizing={false}
          enableColumnFilterModes
          enableEditing={false}
          enableMultiRowSelection={false}
          selectedRows={getSelectedRows}
          onRowSelectionChange={handleContactGroupSelection}
          totalSize={totalSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handleSizeChange}
          initialPage={0}
          initialPageSize={10}
          onSearch={handleSearch}
        />
      </div>
    </StyledCard>
  );
}

Step3.propTypes = {
  formCompletion: PropTypes.func.isRequired,
  contactGroupId: PropTypes.string,
};

Step3.defaultProps = {
  contactGroupId: '',
};

export default Step3;
