import plivo from '../assets/images/channels/plivo.png';
import dialoagFlow from '../assets/images/channels/dialogFlow.png';
import openai from '../assets/images/channels/openai.png';
import twilio from '../assets/images/channels/twilio.svg';
import webchat from '../assets/images/channels/webchat.png';

const IMAGES = {
  plivo,
  dialoagFlow,
  openai,
  twilio,
  webchat,
};

export default IMAGES;
