import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import styled from 'styled-components';

const StyledButton = styled.button`
  color: black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .pi {
    margin-left: 0.5rem;
  }
`;

function CustomOverlay({
  buttonContent, children, buttonIcon, className, onClick, isOpen,
}) {
  const op = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Force show/hide based on isOpen prop
    if (op.current && buttonRef.current) {
      if (isOpen) {
        // Force show the overlay
        op.current.show({ currentTarget: buttonRef.current });
      } else {
        // Force hide the overlay
        op.current.hide();
      }
    }
  }, [isOpen]);

  const handleOnClick = (event) => {
    if (op.current.isVisible()) {
      op.current.hide();
      if (onClick) onClick();
    } else {
      op.current.show(event);
    }
  };

  return (
    <div className="custom-overlay">
      <StyledButton
        onClick={handleOnClick}
        className={className}
        ref={buttonRef}
      >
        {buttonIcon && <i className={buttonIcon} />}
        {buttonContent}
      </StyledButton>
      <OverlayPanel
        ref={op}
        onShow={() => console.info('Overlay shown')}
        onHide={() => {
          console.info('Overlay hidden');
          if (onClick) onClick();
        }}
      >
        {children}
      </OverlayPanel>
    </div>
  );
}

CustomOverlay.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  buttonIcon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CustomOverlay;
