import BroadcastList from '../pages/Campaign/Broadcasts/BroadcastList';
import ChangePassword from '../pages/Authentication/ChangePassword';
import User from '../pages/AdminManagement/User/User';
import ImportContacts from '../pages/Contacts/ImportContacts/ImportContacts';
import ContactGroups from '../pages/Contacts/Contact Groups/ContactGroups';
import ViewContacts from '../pages/Contacts/Contact Groups/ViewContacts';
import SenderGroup from '../pages/Sender Group/SenderGroup';
import MessageOld from '../pages/Campaign/Message/MessageOld';
import Message from '../pages/Campaign/Message/Message';
import WhatsappMessage from '../pages/Campaign/Whatsapp Message/WhatsappMessage';
import Report from '../pages/Campaign/Report/Report';
import ViewReport from '../pages/Campaign/Report/ViewReport';
import CallLogs from '../pages/Logs/CallLogs';
import Voicemail from '../pages/Logs/Voicemail';
import QuickMessage from '../pages/Quick Message/QuickMessage';
import Inbox from '../pages/Inbox/Inbox';
import Opt from '../pages/AdminManagement/Opt/Opt';
import BlockedNumbers from '../pages/Blocked Numbers/BlockedNumbers';
import Templates from '../pages/Campaign/MessageTemplate/Templates';
import Numbers from '../pages/PhoneNumber/Numbers';
import InboxWhatsappMessage from '../pages/Inbox/InboxMessage';
import ContactsList from '../pages/ContactsList/ContactsList';
import Analytics from '../pages/Campaign/Analytics/Analytics';
import Register from '../pages/Authentication/Register';
import retryEmailVerification from '../pages/AuthenticationInner/retry-email-verification';
import ResetPassword from '../pages/Authentication/ResetPassword';
import EmailVerification from '../pages/AuthenticationInner/auth-email-verification';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Login from '../pages/Authentication/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import UserProfile from '../pages/Authentication/UserProfile';
import Channels from '../pages/Channels/Channels';
import MessageBills from '../pages/Billing/MessageBills';
import CallBills from '../pages/Billing/CallBills';
import AlertNotification from '../pages/AlertsManagement/AlertNotification';
import CommunicationSetting from '../pages/CommunicationSettings/CommunicationSetting';

const authProtectedRoutes = [

  // //profile
  { path: '/profile', component: UserProfile },
  { path: '/change-password', component: ChangePassword },

  // Dashboard
  { path: '/', component: Dashboard },

  // Phone Numbers
  { path: '/phone-number', component: Numbers },

  // Admin Management
  // user
  { path: '/user-management', component: User },

  // opt
  { path: '/opt-management', component: Opt },

  // Contacts
  { path: '/contacts-list', component: ContactsList },
  { path: '/import-contacts', component: ImportContacts },
  { path: '/contact-groups', component: ContactGroups },
  { path: '/view-contacts/:groupId', component: ViewContacts },

  // Sender Groups
  { path: '/sender-group', component: SenderGroup },

  // Campaign
  { path: '/send-message-old', component: MessageOld },
  { path: '/send-message', component: Message },
  { path: '/send-whatsapp-message', component: WhatsappMessage },
  { path: '/whatsapp-broadcast', component: BroadcastList },
  { path: '/text-broadcast', component: BroadcastList },
  { path: '/report', component: Report },
  { path: '/view-report/:id', component: ViewReport },
  { path: '/message-template', component: Templates },
  { path: '/analytics', component: Analytics },

  // Logs
  { path: '/call-logs', component: CallLogs },
  { path: '/voicemail', component: Voicemail },

  // Quick-sms
  { path: '/quick-message', component: QuickMessage },

  // Alert Notification
  { path: '/alerts', component: AlertNotification },
  { path: '/message-billing', component: MessageBills },
  { path: '/call-billing', component: CallBills },
  // Inbox
  { path: '/inbox', component: Inbox },
  { path: '/inbox/whatsapp', component: InboxWhatsappMessage },
  // Blocked-Numbers
  { path: '/do-not-disturb', component: BlockedNumbers },

  // Channels
  { path: '/channels', component: Channels },

  // Communication-settings
  { path: '/communication-settings', component: CommunicationSetting },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login/:userId', component: Login },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/reset-password/:token', component: ResetPassword },
  { path: '/reset-password', component: ResetPassword },

  { path: '/retry-email-verification', component: retryEmailVerification },
  { path: '/auth-email-verification/:token', component: EmailVerification },
  { path: '/auth-email-verification', component: EmailVerification },
];

export { authProtectedRoutes, publicRoutes };
