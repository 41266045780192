export const StatusOptions = {
  ACTIVE: { value: 'active', label: 'Active' },
  AWAY: { value: 'away', label: 'Away' },
  BUSY: { value: 'busy', label: 'Busy' },
  OFFLINE: { value: 'offline', label: 'Offline' },
};

export const STATUS_CLASS_MAP = {
  active: 'bg-success bg-opacity-25',
  away: 'bg-warning bg-opacity-25',
  busy: 'bg-danger bg-opacity-25',
  offline: 'bg-secondary bg-opacity-25',
};

export const STATUS_TEXT_MAP = {
  active: 'text-success',
  away: 'text-warning',
  busy: 'text-danger',
  offline: 'text-secondary',
};

export const STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'deactivated' },
];

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'deactiveated',
};

export const CHATBOX_USER_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  INTERMEDIATE: 'intermediate',
};

export const STATUS_TYPES = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
  NO_ANSWER: 'no-answer',
  BUSY: 'busy',
  CANCELED: 'canceled',
  FAILED: 'failed',
  QUEUED: 'queued',
  RINGING: 'ringing',
};

export const BADGE_COLORS = {
  [STATUS_TYPES.COMPLETED]: 'success-subtle',
  [STATUS_TYPES.IN_PROGRESS]: 'primary-subtle',
  [STATUS_TYPES.NO_ANSWER]: 'danger-subtle',
  [STATUS_TYPES.BUSY]: 'warning-subtle',
  [STATUS_TYPES.CANCELED]: 'secondary-subtle',
  [STATUS_TYPES.FAILED]: 'danger-subtle',
  [STATUS_TYPES.QUEUED]: 'info-subtle',
  [STATUS_TYPES.RINGING]: 'warning-subtle',
};

export const TEXT_COLORS = {
  [STATUS_TYPES.COMPLETED]: 'text-success',
  [STATUS_TYPES.IN_PROGRESS]: 'text-primary',
  [STATUS_TYPES.NO_ANSWER]: 'text-danger',
  [STATUS_TYPES.BUSY]: 'text-warning',
  [STATUS_TYPES.CANCELED]: 'text-secondary',
  [STATUS_TYPES.FAILED]: 'text-danger',
  [STATUS_TYPES.QUEUED]: 'text-info',
  [STATUS_TYPES.RINGING]: 'text-warning',
};

export const NUMBER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
