import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, Row, Col,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'simplebar-react/dist/simplebar.min.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  SIZE_PER_PAGE,
} from '../../constants/constants';
import { axiosGet, axiosPatch } from '../../services/http.service';
import { updateAlert } from '../../store/actions';
import { sliceStringWithEllipsis } from '../../helpers/commonHelpers';
import { generateAlertTypeMessage } from '../../utils/alert';
import { MESSAGE_TYPE_ICONS } from '../../constants/inbox/message.constant';

class AlertNotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      viewAll: false,
      alerts: [],
      totalResults: 0,
      page: 1,
      totalPages: 1,
      size: SIZE_PER_PAGE,
      hoverAlertId: null,
    };
  }

  // componentDidMount() {
  //   this.fetchAlerts();
  // }

  fetchAlerts = async (reset = false) => {
    const { page, size } = this.state;
    try {
      const response = await axiosGet(
        `/alert?isRead=false&page=${page}&limit=${size}&sortBy=createdAt:desc`,
      );
      if (response?.data && response?.data?.results) {
        this.setState((prevState) => ({
          alerts: reset
            ? response?.data?.results
            : [...prevState.alerts, ...response.data.results],
          totalResults: response.data.totalResults,
          totalPages: response.data.totalPages,
        }));
      }
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
  };

  markAsRead = async (alertId) => {
    try {
      const { onUpdateAlert } = this.props;
      await axiosPatch(`/alert/${alertId}`, { isRead: true });
      onUpdateAlert({ id: alertId, isRead: true });
      this.setState((prevState) => ({
        alerts: prevState.alerts.filter((alert) => alert.id !== alertId),
        totalResults: prevState.totalResults - 1,
      }));
    } catch (error) {
      console.error('Error marking alert as read:', error);
    }
  };

  toggle = () => {
    const { toggle } = this.state;
    this.setState(
      (prevState) => ({
        toggle: !prevState.toggle,
        viewAll: prevState.toggle ? false : prevState.viewAll,
        page: 1, // Reset page when toggling
      }),
      () => {
        if (toggle) {
          this.fetchAlerts(true); // Fetch initial alerts when opening
        }
      },
    );
  }

  handleAlertClick = () => {
    const { page } = this.state;
    const { history } = this.props;
    history.push({
      pathname: '/alerts',
      state: { page },
    });
  };

  formatLastMessageTime = (timestamp) => moment(timestamp).fromNow()

  getAlertHeading = (alertType, messageType) => generateAlertTypeMessage(alertType, messageType)

  handleMouseEnter = (alertId) => {
    this.setState({ hoverAlertId: alertId });
  };

  handleMouseLeave = () => {
    this.setState({ hoverAlertId: null });
  };

  // Load more alerts
  handleLoadMore = () => {
    const { page, totalPages } = this.state;
    if (page < totalPages) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
        }),
        () => {
          this.fetchAlerts(); // Fetch the next page of alerts
        },
      );
    }
  }

  render() {
    const {
      viewAll, hoverAlertId, alerts, totalResults, totalPages, page, toggle,
    } = this.state;
    const { t } = this.props;
    const alertCount = totalResults > 0 ? totalResults : '';
    return (
      <Dropdown
        isOpen={toggle}
        toggle={this.toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
          <span className="badge bg-primary rounded-pill">
            {alertCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">
                  {' '}
                  {t('Alerts')}
                  {' '}
                </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar className="noti-simplebar">
            {alerts && alerts.length > 0 ? (
              alerts.map((alert) => (
                <Link
                  to="#"
                  className="text-reset notification-item"
                  key={`${alert.id}`}
                  onClick={() => this.handleAlertClick(alert)}
                  onMouseEnter={() => this.handleMouseEnter(alert.id)}
                  onMouseLeave={this.handleMouseLeave}
                >
                  <div className="d-flex align-items-center p-3">
                    <div className="flex-shrink-0 me-3">
                      <i
                        className={`font-size-24 ${MESSAGE_TYPE_ICONS[alert.messageType]
                        }`}
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1">
                        {this.getAlertHeading(
                          alert.alertType,
                          alert.messageType,
                        )}
                      </h6>
                      <p className="mb-0 font-size-12 text-muted">
                        {sliceStringWithEllipsis(alert)}
                      </p>
                    </div>
                    <div className="flex-shrink-0 text-end">
                      {hoverAlertId === alert.id ? (
                        <i
                          className="font-size-22 mdi mdi-checkbox-marked-circle-outline text-secondary me-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.markAsRead(alert.id);
                          }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Mark as read"
                          id="read"
                        />
                      ) : (
                        <p className="mb-0 font-size-10 text-muted">
                          {this.formatLastMessageTime(alert.createdAt)}
                        </p>
                      )}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="text-center p-3 text-muted">No alerts yet.</div>
            )}
            {!viewAll && page < totalPages && (
              <div className="text-center p-2 border-top">
                <button
                  className="btn btn-link text-muted"
                  onClick={this.handleLoadMore}
                  type="button"
                >
                  {t('Load more')}
                </button>
              </div>
            )}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

AlertNotificationDropdown.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  onUpdateAlert: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  onUpdateAlert: (alertObj) => dispatch(updateAlert(alertObj)),
});

const mapStateToProps = (state) => ({
  alerts: state.notification.alerts,
  totalResults: state.notification.totalResults,
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(AlertNotificationDropdown)),
);
