import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getTemplates } from '../../../services/api/bulkSms.service';
import { PAGE, SIZE_PER_PAGE } from '../../../constants/constants';
import Select from '../../Select';
import { showToast } from '../../../services/toastService';
import { SOMETHING_WENT_WRONG } from '../../../constants/error.constant';

function TemplateSelect({ onSelect, selectedValue, templateId }) {
  const [{
    options, selectedOption, isLoading, page, limit, totalPages, searchQuery,
  }, setStates] = useState({
    options: [],
    selectedOption: selectedValue ? {
      label: selectedValue?.name,
      value: selectedValue?.id,
      template: selectedValue,
    } : null,
    isLoading: false,
    page: PAGE,
    limit: SIZE_PER_PAGE,
    totalPages: 0,
    searchQuery: '',
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 'unset',
    }),
  };

  const handleState = (key, value) => {
    setStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChange = (newValue) => {
    handleState('selectedOption', newValue);
    onSelect(newValue?.template);
  };

  const handleGetTemplates = async () => {
    try {
      handleState('isLoading', true);
      const response = await getTemplates({
        page,
        limit,
        searchQuery,
      });

      if (response?.status) {
        const resultOptions = response?.data?.results?.map((template) => ({
          label: template?.name,
          value: template?.id,
          template,
        }));

        setStates((prevState) => {
          // Filter out duplicates
          const newOptions = resultOptions.filter(
            (option) => !prevState.options?.some((template) => template?.value === option.value),
          );

          const updatedOptions = [...prevState.options, ...newOptions];

          // If we have a templateId but no selectedOption, try to find and select it
          if (templateId && !prevState.selectedOption) {
            const matchedTemplate = updatedOptions.find((template) => template?.value === templateId);
            if (matchedTemplate) {
              onSelect(matchedTemplate.template);
              return {
                ...prevState,
                options: updatedOptions,
                selectedOption: matchedTemplate,
                isLoading: false,
                page: prevState.page + 1,
                totalPages: response.data.totalPages,
              };
            }
          }

          return {
            ...prevState,
            options: updatedOptions,
            isLoading: false,
            page: prevState.page + 1,
            totalPages: response.data.totalPages,
          };
        });
      } else {
        showToast({
          type: 'error',
          content: response?.message || SOMETHING_WENT_WRONG,
        });
      }
    } catch (error) {
      showToast({
        type: 'error',
        content: error?.message || SOMETHING_WENT_WRONG,
      });
    } finally {
      handleState('isLoading', false);
    }
  };

  const customNoOptionsMessage = () => <span className="">No Template Available</span>;

  const handleMenuScrollToBottom = () => {
    if (!isLoading && page <= totalPages) {
      handleGetTemplates();
    }
  };

  const handleSearch = (searchValue) => {
    setStates((prevState) => ({
      ...prevState,
      page: 1,
      searchQuery: searchValue,
    }));
  };

  // Effect to handle templateId changes
  useEffect(() => {
    if (templateId) {
      const matchedTemplateRecord = options?.find((template) => template?.value === templateId);
      if (matchedTemplateRecord) {
        setStates((prev) => ({
          ...prev,
          selectedOption: matchedTemplateRecord,
        }));
        onSelect(matchedTemplateRecord?.template);
      }
    }
  }, [templateId]);

  // Effect to fetch templates
  useEffect(() => {
    handleGetTemplates();
  }, [searchQuery]);

  return (
    <Select
      label="Select Template"
      options={options}
      value={selectedOption}
      onChange={handleChange}
      styles={customStyles}
      placeholder="Select template..."
      noOptionsMessage={customNoOptionsMessage}
      isLoading={isLoading}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onInputChange={handleSearch}
      className="mb-3"
      containerStyle={{
        width: '100%',
      }}
    />
  );
}

TemplateSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedValue: PropTypes.object,
  templateId: PropTypes.string,
};

export default TemplateSelect;
