/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import CountUp from 'react-countup';
import Onboarding from '../../components/Features/Onboarding/Onboarding';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { setLoader } from '../../store/actions';
import { axiosGet } from '../../services/http.service';
import Pie from '../../components/Charts/PieChart';
import { UserContext } from '../../components/UserProvider/UserProvider';
import emptyContacts from '../../assets/images/contacts/emptyContact.png';
import { getInitials } from '../../helpers/commonHelpers';

class Dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedCampaign: {},
      dataInCard: [],
      messageDeliveryStatus: [],
      messageReplyStatus: [],
      contactsInTags: [],
      campaigns: [],
      page: 1,
      totalPages: null,
      loading: true,
    };
  }

  componentDidMount() {
    document.title = 'Dashboard';
    this.initializeData();
  }

  handleScroll = _debounce((e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1;
    const { loading, page, totalPages } = this.state;

    if (isBottom && !loading && page <= totalPages) {
      this.getAllCampaignsByWorkspaceId();
    }
  }, 300);

  initializeData = async () => {
    const { userData } = this.context;
    const { onSetLoader } = this.props;
    if (userData?.activeWorkspace?.workspaceId) {
      await this.getAllCampaignsByWorkspaceId();
    } else {
      // Set loader and retry
      onSetLoader(true);
      setTimeout(this.initializeData, 1000);
    }
    onSetLoader(false);
  };

  getAllCampaignsByWorkspaceId = async () => {
    const { page, campaigns } = this.state;
    const { onSetLoader } = this.props;

    try {
      const response = await axiosGet(`campaign?page=${page}&sortBy=createdAt:desc`);

      if (response?.status) {
        const newCampaigns = response.data.results;
        const { totalPages } = response.data;

        this.setState(
          (prevState) => ({
            campaigns: [...prevState.campaigns, ...newCampaigns],
            totalPages,
            loading: false,
            page: prevState.page + 1,
          }),
          () => {
            if (campaigns.length === 0 && newCampaigns.length > 0) {
              this.handleSelectCampaign(newCampaigns[0]);
            }
          },
        );
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      onSetLoader(false);
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  getCampaignReportById = async (campaign) => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);
      const response = await axiosGet(`campaign/analytics/${campaign.id}`);

      if (response?.status) {
        const { messageStatistics, contactStatistics, tagStatistics } = response.data;

        const dataInCard = [
          {
            name: 'Total Contacts',
            value: contactStatistics.totalContacts,
            icon: 'mdi mdi-account-multiple',
            color: 'primary',
          },
          {
            name: 'Opt-out Contacts',
            value: contactStatistics.optOut,
            icon: 'mdi mdi-block-helper',
            color: 'danger',
          },
          {
            name: 'Read Messages',
            value: messageStatistics.readStatus.read,
            icon: 'mdi mdi-email-open',
            color: 'success',
          },
          {
            name: 'Unread Messages',
            value: messageStatistics.readStatus.unread,
            icon: 'mdi mdi-email',
            color: 'warning',
          },
        ];

        const messageDeliveryStatus = [
          { name: 'Read', value: messageStatistics.readStatus.read },
          {
            name: 'Delivered',
            value: messageStatistics.deliveryStatus.delivered,
          },
          { name: 'Failed', value: messageStatistics.deliveryStatus.failed },
          { name: 'Sent', value: messageStatistics.deliveryStatus.totalSent },
        ];

        const messageReplyStatus = [
          { name: 'Replied', value: messageStatistics.replyStatus.replied },
          {
            name: 'Not Replied',
            value: messageStatistics.replyStatus.notReplied,
          },
        ];

        const contactsInTags = tagStatistics.tagsCount.map((tag) => ({
          name: tag.tagName,
          value: tag.contactCount,
        }));

        this.setState({
          dataInCard,
          messageDeliveryStatus,
          messageReplyStatus,
          contactsInTags,
        });
      }
    } catch (error) {
      console.error('Error fetching campaign report:', error);
    } finally {
      onSetLoader(false);
    }
  };

  handleSelectCampaign = (campaign) => {
    this.setState({ selectedCampaign: campaign });
    this.getCampaignReportById(campaign);
  };

  // eslint-disable-next-line react/destructuring-assignment
  renderDataCards = () => this.state.dataInCard.map((data, key) => (
    <Col md="3" key={`_col_${key}`}>
      <Card className="mini-stats-wid shadow-sm border border-light-subtle">
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              <p className="text-muted fw-medium">{data.name}</p>
              <h4 className="mb-0">
                <CountUp end={data.value} duration={2.5} separator="," />
              </h4>
            </div>
            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
              <span className={`avatar-title bg-${data.color}`}>
                <i className={`bx ${data.icon} font-size-24`} />
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  ));

  renderPieCharts = () => {
    const { messageDeliveryStatus, messageReplyStatus, contactsInTags } = this.state;
    const pieCharts = [
      {
        title: 'Message Delivery Overview',
        data: messageDeliveryStatus,
        name: 'Total Messages',
      },
      {
        title: 'Message Reply Analysis',
        data: messageReplyStatus,
        name: 'Total Messages',
      },
      {
        title: 'Contact Distribution by Tags',
        data: contactsInTags,
        name: 'Total Contacts',
      },
    ];

    return pieCharts.map((chart, index) => (
      <Col lg="6" key={index}>
        <Card className="shadow-sm border border-light-subtle">
          <CardBody>
            <div id={`pie-chart-${index}`} className="e-chart">
              <Pie data={chart.data} title={chart.title} name={chart.name} />
            </div>
          </CardBody>
        </Card>
      </Col>
    ));
  };

  render() {
    const {
      dropdownOpen, campaigns, selectedCampaign, loading, page, totalPages,
    } = this.state;
    const { isOnboarding } = this.props;
    if (isOnboarding) {
      return (
        <Onboarding />
      );
    }

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={<i className="bx bx-home-circle" />} breadcrumbItem="Dashboard" />
          {campaigns.length ? (
            <>
              <Row className="mb-3">
                <Col className="d-flex justify-content-end align-items-end gap-3">
                  <Label className="font-size-14" for="campaignDropdown">
                    Select a Campaign
                  </Label>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={this.toggleDropdown}
                    id="campaignDropdown"
                  >
                    <DropdownToggle
                      tag="button"
                      className="bg-light-subtle bg-opacity-50 bg-gradient border border-light-subtle shadow-sm rounded d-flex align-items-center p-2"
                    >
                      <div className="d-flex align-items-center">
                        <div className="text-center me-3">
                          <div className="avatar-xs mx-auto">
                            <span className="avatar-title rounded-circle bg-soft primary text font-size-16">
                              {getInitials(selectedCampaign?.name)}
                            </span>
                          </div>
                        </div>
                        <span className="font-size-14">
                          {selectedCampaign?.name || 'Select a Campaign'}
                        </span>
                      </div>
                      <i className="mdi mdi-chevron-down ms-2" />
                    </DropdownToggle>
                    <DropdownMenu className="dashboard-dropdown-menu" onScroll={this.handleScroll}>
                      {campaigns.length ? (
                        campaigns.map((campaign) => (
                          <DropdownItem
                            key={campaign.id}
                            onClick={() => this.handleSelectCampaign(campaign)}
                            className="d-flex align-items-center"
                          >
                            <div className="text-center me-3">
                              <div className="avatar-xs mx-auto mt-1 mb-1">
                                <span className="avatar-title rounded-circle bg-soft primary text font-size-16">
                                  {getInitials(campaign?.name)}
                                </span>
                              </div>
                            </div>
                            <span className="font-size-14">{campaign.name}</span>
                          </DropdownItem>
                        ))
                      ) : (
                        <DropdownItem disabled>No options available</DropdownItem>
                      )}
                      {(loading || page <= totalPages) && (
                        <DropdownItem disabled>
                          <Spinner size="sm" color="secondary" />
                          {' '}
                          Loading...
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>{this.renderDataCards()}</Row>
              <Row>{this.renderPieCharts()}</Row>
            </>
          ) : (
            <Card>
              <div className="d-flex justify-content-center">
                <img src={emptyContacts} alt="empty-contact" />
              </div>
            </Card>
          )}
        </Container>
      </div>
    );
  }
}

Dashboard.propTypes = {
  onSetLoader: PropTypes.func,
  isOnboarding: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ onboarding }) => ({
  isOnboarding: onboarding.isOnboarding,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
