/* eslint-disable import/prefer-default-export */
import { axiosGet, axiosPost } from '../http.service';

// Function to exchange WhatsApp token
export const getAllWhatsappNumbers = async () => {
  try {
    const response = await axiosGet('whatsapp-numbers');
    return response;
  } catch (error) {
    console.error('error at getAllWhatsappNumbers :', error);
    throw error;
  }
};

export const getAllWhatsappTemplates = async (phoneNumberId) => {
  try {
    const response = await axiosGet(
      `whatsapp-template/all?phoneNumberId=${phoneNumberId}`,
    );
    return response;
  } catch (error) {
    console.error('error at getAllWhatsappTemplates :', error);
    throw error;
  }
};

export const getAllContactGroups = async () => {
  try {
    const response = await axiosGet('contact-groups/all');
    return response;
  } catch (error) {
    console.error('error at getAllContactGroups :', error);
    throw error;
  }
};

export const getTemplatesFromMeta = async (workspaceId, wabaId) => {
  try {
    const response = await axiosGet(
      `whatsapp-template/available-templates/${workspaceId}?whatsappBusinessAccountId=${wabaId}`,
    );
    return response;
  } catch (error) {
    console.error('error at getTemplatesFromMeta :', error);
    throw error;
  }
};

export const storeWhatsappTemplate = async (templateData) => {
  try {
    const response = await axiosPost('whatsapp-template', templateData);
    return response;
  } catch (error) {
    console.error('error at getTemplatesFromMeta :', error);
    throw error;
  }
};

export const createWhatsappCampaign = async (campaignData) => {
  try {
    const response = await axiosPost('campaign/bulk-whatsapp-message', campaignData);
    return response;
  } catch (error) {
    console.error('error at createWhatsappCampaign', error);
    throw error;
  }
};

export const getWhatsappTemplate = async (templateName) => {
  try {
    const response = await axiosGet(`whatsapp-template?templateName=${templateName}`);
    return response;
  } catch (error) {
    console.error('error at getTemplatesFromMeta :', error);
    throw error;
  }
};
