import React, {
  useEffect, useContext, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { CHANNEL_CONNECT_STATUS, CHANNEL_TYPES } from '../../../../constants/channels/channels.constant';
import { setShowOpenAiCredentialsModal } from '../../../../store/actions';
import { UserContext } from '../../../UserProvider/UserProvider';
import { getChannels } from '../../../../utils/channels';
import { updateOrganization } from '../../../../services/api/organization.service';

const StyledDialog = styled(Dialog)`
  .p-dialog-content {
    padding: 0 1.5rem 1.5rem;
    width: 30rem;
  }
`;

const FormRow = styled.div`
  margin-bottom: 1.5rem
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-right: 0.5rem;
`;

const ErrorMessage = styled.small`
  color: #f44336;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

function OpenAiCredentials({ getPrivateCredentials, privateCreds }) {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.channels.showOpenAiCredentialsModal);
  const { userData } = useContext(UserContext);
  const toast = useRef(null);

  const initialValues = {
    apiKey: '',
    name: '',
  };

  const toggleModal = () => {
    dispatch(setShowOpenAiCredentialsModal());
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleUpdateOpenAiCredentials = async (updateBody) => {
    try {
      const organizationId = userData?.organizationId;
      const response = await updateOrganization(organizationId, updateBody);
      if (response?.status) {
        getPrivateCredentials();
        showToast('success', 'Success', response?.message || 'OpenAI credentials updated successfully!');
        toggleModal();
      } else {
        showToast('error', 'Error', response?.message || 'Oops! Something went wrong');
      }
    } catch (error) {
      console.error('Error while updating OpenAI credentials:', error);
      showToast('error', 'Error', 'Failed to update OpenAI credentials');
    }
  };
  function createUpdateBody(config, status) {
    return {
      channels: [
        {
          type: CHANNEL_TYPES.OPENAI,
          status,
          config,
        },
      ],
    };
  }
  function handleSubmit(values) {
    const twilioCreds = {
      sid: values.accountSid,
      authToken: values.authToken,
      copilotId: values.coPilotToken,
    };

    const updateBody = createUpdateBody(twilioCreds, CHANNEL_CONNECT_STATUS.CONNECTED);
    handleUpdateOpenAiCredentials(updateBody);
  }

  const validationSchema = Yup.object().shape({
    apiKey: Yup.string().required('API Key is required'),
    name: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const setCredentials = () => {
    const { openai } = getChannels(privateCreds);
    formik.setValues({
      apiKey: openai?.config?.apiKey || '',
      name: openai?.config?.name || '',
    });
  };

  useEffect(() => {
    setCredentials();
  }, [privateCreds]);

  const handleDisconnectOpenAi = async () => {
    const disconnectBody = createUpdateBody({
      apiKey: '',
      name: '',
    }, CHANNEL_CONNECT_STATUS.DISCONNECTED);
    handleUpdateOpenAiCredentials(disconnectBody);
  };

  const renderFormField = (fieldName, label, tooltipText) => (
    <FormRow>
      <LabelWrapper>
        <Label htmlFor={fieldName}>{label}</Label>
        <Tooltip target={`.${fieldName}-info`}>
          {tooltipText}
        </Tooltip>
        <i className={`bx bx-info-circle mb-2 ${fieldName}-info`} />
      </LabelWrapper>
      <InputText
        style={{ width: '100%' }}
        id={fieldName}
        name={fieldName}
        value={formik.values[fieldName]}
        onChange={formik.handleChange}
        className={formik.errors[fieldName] && formik.touched[fieldName] ? 'p-invalid' : ''}
      />
      {formik.errors[fieldName] && formik.touched[fieldName] && (
        <ErrorMessage>{formik.errors[fieldName]}</ErrorMessage>
      )}
    </FormRow>
  );

  const renderFooter = () => {
    const { openai } = getChannels(privateCreds);
    return (
      <ButtonWrapper>
        {openai?.status === CHANNEL_CONNECT_STATUS.CONNECTED && (
          <Button
            label="Disconnect"
            icon="bx bx-disconnect"
            className="p-button-outlined p-button-danger"
            onClick={handleDisconnectOpenAi}
          />
        )}
        <Button
          label="Save"
          size="small"
          className="p-button-success"
          onClick={formik.handleSubmit}
          style={{ borderRadius: 'var(--border-radius)' }}
        />
      </ButtonWrapper>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <StyledDialog
        header="OpenAI Credentials"
        visible={isModalOpen}
        onHide={toggleModal}
        footer={renderFooter()}
      >
        <form onSubmit={formik.handleSubmit}>
          {renderFormField('name', 'Name', 'Enter your OpenAI Name here.')}
          {renderFormField('apiKey', 'API Key', 'Enter your OpenAI API Key here.')}
        </form>
      </StyledDialog>
    </>
  );
}

OpenAiCredentials.propTypes = {
  getPrivateCredentials: PropTypes.func.isRequired,
  privateCreds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OpenAiCredentials;
