import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import WizardSteps from '../../../../components/Features/WizardSteps';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import { setActiveIndexOfBMessageForm, setStepCompletionBMessage } from '../../../../store/actions';
import * as bulkSmsServices from '../../../../services/api/bulkSms.service';

function BulkMessage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { stepCompletionArray, activeIndex } = useSelector((state) => state.bulkMessage);
  const [templateObj, setTemplateObj] = useState(null); // State to hold the template object
  const [contactGroupId, setContactGroupId] = useState(null); // State to hold the template object
  const [step4EditData, setStep4EditData] = useState(null);
  const itemRenderer = (item, itemIndex, currentStep = 0) => {
    const { icon, label } = item;
    const StepCompletedIcon = 'bx bx-check';
    return (
      <div
        className={`p-menuitem-link ${currentStep > itemIndex ? 'p-completed' : ''} cursor-pointer`}
        tabIndex="-1"
        data-pc-section="action"
        onClick={() => dispatch(setActiveIndexOfBMessageForm(itemIndex))}
      >
        <span className="p-steps-number" data-pc-section="step">
          {currentStep > itemIndex ? (
            <i className={StepCompletedIcon} />
          ) : (
            <i className={icon} />
          )}
        </span>
        <span className="p-steps-title" data-pc-section="label">{label}</span>
      </div>
    );
  };

  const [items, setItems] = useState(
    [
      {
        label: 'Choose Template',
        icon: 'bx bxs-detail',
        disabled: false,
        template: (item) => itemRenderer(item, 0, activeIndex),
      },
      {
        label: 'Choose Segment',
        icon: 'bx bx-group',
        disabled: true,
        template: (item) => itemRenderer(item, 1, activeIndex),
      },
      {
        label: 'Broadcast',
        icon: 'mdi mdi-bullhorn',
        disabled: true,
        template: (item) => itemRenderer(item, 2, activeIndex),
      },
    ],
  );

  const handleGetTextMsgTemplate = async () => {
    try {
      const { broadcastData } = location.state;
      const {
        textTemplateData, contactGroupId: contactGroupid, broadcastName, broadcastTimeZone, broadcastDateTime, usingCopilot, senderGroupId,
      } = broadcastData;
      const hasBroadcastDetails = broadcastName || broadcastTimeZone || broadcastDateTime;
      if (contactGroupid) {
        setContactGroupId(contactGroupid);
      }
      if (hasBroadcastDetails) {
        setStep4EditData({
          broadcastName,
          broadcastDateTime,
          broadcastTimeZone,
        });
      }
      const response = await bulkSmsServices.getTextMessageTemplate();

      if (response?.status) {
        const fetchedTemplate = response.data.results.find(
          (template) => template.name === textTemplateData.name,
        );
        if (fetchedTemplate) {
          fetchedTemplate.usingCopilot = usingCopilot; // Add usingCopilot to the template object
          fetchedTemplate.senderGroupId = senderGroupId; // Add senderGroupId to the template object
          setTemplateObj(fetchedTemplate); // Update the state with the template object
        } else {
          toast.error('Template not found');
        }
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('Error in handleGetTextMessageTemplate:', error);
    }
  };

  const manageTextTemplateData = () => {
    const { actionType } = location.state || {};
    const isEdit = actionType === 'edit';
    if (isEdit) {
      handleGetTextMsgTemplate();
    }
  };

  const handleFormCompletion = (stepIndex, isCompleted) => {
    const completionArray = stepCompletionArray?.map((stepCompletion) => (stepCompletion?.stepIndex === stepIndex
      ? { ...stepCompletion, completed: isCompleted }
      : stepCompletion));

    dispatch(setStepCompletionBMessage(completionArray));
  };

  const getStepComponent = (index) => {
    switch (index) {
      case 0:
        return <Step1 formCompletion={(isCompleted) => handleFormCompletion(0, isCompleted)} broadCastEditData={templateObj} />;
      case 1:
        return <Step2 formCompletion={(isCompleted) => handleFormCompletion(1, isCompleted)} contactGroupID={contactGroupId} />;
      case 2:
        return <Step3 broadCastEditData={step4EditData} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    document.title = 'Text Campaign';
    manageTextTemplateData();
  }, []);

  useEffect(() => {
    setItems(items.map((item, index) => {
      const updatedItem = { ...item };
      if (index === activeIndex) {
        updatedItem.disabled = false;
      }
      const templateNode = itemRenderer(updatedItem, index, activeIndex);
      updatedItem.template = templateNode;
      return updatedItem;
    }));
  }, [activeIndex]);

  const nextButtonEnable = activeIndex !== items.length - 1 && stepCompletionArray?.reduce(
    (acc, stepCompletion) => (
      stepCompletion?.stepIndex === activeIndex ? stepCompletion?.completed : acc
    ),
    false,
  );

  const previousButtonEnable = activeIndex > 0;

  const handleStepChange = (currentStep) => {
    dispatch(setActiveIndexOfBMessageForm(currentStep));
  };

  return (
    <WizardSteps
      containerClassName="mb-3"
      items={items}
      activeIndex={activeIndex}
      readOnly={false}
      onStepChange={handleStepChange}
      buttonsEnable
      previousButtonDisabled={!previousButtonEnable}
      nextButtonDisabled={!nextButtonEnable}
    >
      {getStepComponent(activeIndex)}
    </WizardSteps>
  );
}

export default BulkMessage;
