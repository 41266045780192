import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmDialog as PrimeConfirmDialog } from 'primereact/confirmdialog';
import styled from 'styled-components';
import { handleError } from '../../services/errorHandler';

const StyledConfirmDialog = styled(PrimeConfirmDialog)`
  .p-confirm-dialog {
    min-width: 300px;
    .p-dialog-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .p-dialog-content {
      font-size: 1rem;
    }
    .p-dialog-footer {
      display: flex;
      justify-content: flex-end;
    }
    .p-button {
      margin: 0 0.5rem;
    }
  }
`;

function ConfirmDialog({
  visible,
  message,
  header,
  icon,
  acceptLabel,
  rejectLabel,
  acceptClassName,
  rejectClassName,
  accept,
  reject,
  onHide,
  blockScroll,
  breakpoints,
  className,
  dismissableMask,
  closable,
  footer,
  position,
  style,
}) {
  const handleAccept = () => {
    try {
      accept();
    } catch (error) {
      handleError(error);
    }
  };

  const handleReject = () => {
    try {
      reject();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledConfirmDialog
      visible={visible}
      message={message}
      header={header}
      icon={icon}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      acceptClassName={acceptClassName}
      rejectClassName={rejectClassName}
      accept={handleAccept}
      reject={handleReject}
      onHide={onHide}
      blockScroll={blockScroll}
      breakpoints={breakpoints}
      className={className}
      dismissableMask={dismissableMask}
      closable={closable}
      footer={footer}
      position={position}
      style={style}
    />
  );
}

ConfirmDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  header: PropTypes.string,
  icon: PropTypes.string,
  acceptLabel: PropTypes.string,
  rejectLabel: PropTypes.string,
  acceptClassName: PropTypes.string,
  rejectClassName: PropTypes.string,
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  blockScroll: PropTypes.bool,
  breakpoints: PropTypes.object,
  className: PropTypes.string,
  dismissableMask: PropTypes.bool,
  closable: PropTypes.bool,
  footer: PropTypes.node,
  position: PropTypes.string,
  style: PropTypes.object,
};

ConfirmDialog.defaultProps = {
  header: 'Confirmation',
  icon: 'bx bx-error-circle',
  acceptLabel: 'Yes',
  rejectLabel: 'No',
  acceptClassName: 'p-button-primary',
  rejectClassName: 'p-button-secondary',
  blockScroll: false,
  breakpoints: null,
  className: '',
  dismissableMask: true,
  closable: true,
  footer: null,
  position: 'center',
  style: {},
};

export default ConfirmDialog;
