import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Badge, Button,
} from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { WHATSAPP_ACTION_TYPES } from '../../../constants/channels/whatsApp.constant';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import DataGrid from '../../../components/DataTable';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';
import { axiosDel, axiosGet } from '../../../services/http.service';
import DeleteModal from '../../../components/Common/DeleteModal';
import { setLoader } from '../../../store/actions';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';
// Constants
const STATUS_TYPES = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  ERROR: 'error',
};

function StatusBadge({ status }) {
  const config = {
    [STATUS_TYPES.COMPLETED]: { color: 'success-subtle', textColor: 'fs-6 text-success' },
    [STATUS_TYPES.PENDING]: { color: 'warning-subtle', textColor: 'fs-6 text-warning' },
    [STATUS_TYPES.ERROR]: { color: 'danger-subtle', textColor: 'fs-6 text-danger' },
  };

  const { color, textColor } = config[status] || { color: 'secondary-subtle', textColor: 'fs-6 text-secondary' };

  return (
    <Badge color={color} pill className={textColor}>
      {status}
    </Badge>
  );
}
function DateCell({ value }) {
  const formattedDate = moment(value).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
  const formattedTime = moment(value).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);

  return (
    <div>
      {formattedDate}
      <br />
      {formattedTime}
    </div>
  );
}

function ActionButtons({
  report, channel, onDelete, page,
}) {
  // Check if the channel is WhatsApp
  const isWhatsappChannel = report.channel === CHANNEL_TYPES.WHATSAPP;

  // Determine the appropriate page based on the channel type
  const navigateToBroadCastPage = isWhatsappChannel ? '/send-whatsapp-message' : '/send-message';

  // Common data shared by both WhatsApp and text channels
  const commonData = {
    contactGroupId: report?.contactGroupId?.id,
    broadcastName: report?.name,
    broadcastTimeZone: report?.timezone,
    broadcastDateTime: report?.dateTime,
  };

  // WhatsApp-specific data
  const whatsappData = {
    senderId: report.senderNumberId,
    templateName: report.whatsappTemplate?.templateName,
    templateKey: `${report?.whatsappTemplate?.templateName}-${report?.whatsappTemplate?.template?.language?.code}`,
  };

  // Text-specific data
  const textData = {
    senderGroupId: report?.senderGroupId,
    usingCopilot: report?.usingCopilot,
    textTemplateData: report.template?.[0],
  };

  // Combine common data with channel-specific data based on the channel type
  const broadcastData = {
    ...commonData,
    ...(isWhatsappChannel ? whatsappData : textData),
  };
  // Function to determine the action state
  const getBroadcastActionState = useMemo(() => (report
    ? { actionType: WHATSAPP_ACTION_TYPES.EDIT, broadcastData }
    : { actionType: WHATSAPP_ACTION_TYPES.CREATE, broadcastData: {} }), [report, broadcastData]);

  return (
    <div className="d-flex gap-3">
      <Link
        to={{
          pathname: `/view-report/${report.id}?page=${page}`,
          state: { channel },
        }}
      >
        <Badge color="success-subtle" className="text-success">
          <i
            className="mdi mdi-eye-outline font-size-18"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Analytics"
            id="view"
          />
        </Badge>
      </Link>
      <Link
        to={{
          pathname: navigateToBroadCastPage,
          state: getBroadcastActionState,
        }}
      >
        <Badge color="info-subtle" className="text-info">
          <i
            className="mdi mdi-repeat font-size-18"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Repeat Broadcast"
            id="repeat"
          />
        </Badge>
      </Link>
      <div>
        <Badge color="primary-subtle" className="text-primary">
          <i
            className="mdi mdi-download font-size-18"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Download Report"
            id="download"
          />
        </Badge>
      </div>
      <div>
        <Badge color="danger-subtle" className="text-danger">
          <i
            className="mdi mdi-trash-can-outline font-size-18"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete"
            onClick={() => onDelete(report)}
          />
        </Badge>
      </div>
    </div>
  );
}

function DateCellRenderer({ renderedCellValue }) {
  return <DateCell value={renderedCellValue} />;
}

function StatusCellRenderer({ renderedCellValue }) {
  return <StatusBadge status={renderedCellValue} />;
}

// Main component
function BroadcastList({ onSetLoader }) {
  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(PAGE);
  const [size, setSize] = useState(SIZE_PER_PAGE);
  const [totalSize, setTotalSize] = useState(TOTAL_SIZE);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const { channel } = location?.state || {};

  const handleGetAllCampaign = useCallback(async (pageNumber, pageSize = size) => {
    const channelLowerCase = channel?.toLowerCase();
    let url = `campaign?page=${pageNumber}&limit=${pageSize}&sortBy=createdAt:desc&channel=${channelLowerCase}`;
    if (searchQuery) {
      url += `&q=${searchQuery}`;
    }

    try {
      onSetLoader(true);
      const response = await axiosGet(url);
      if (response?.status) {
        setCampaigns(response.data.results);
        setTotalSize(response.data.totalPages);
      }
    } catch (error) {
      toast.error('Failed to fetch campaigns');
      console.error('Error fetching campaigns:', error);
    } finally {
      onSetLoader(false);
    }
  }, [channel, searchQuery, size, onSetLoader]);

  const handleDeleteCampaign = useCallback(async () => {
    try {
      const response = await axiosDel(`campaign/${campaign?.id}`);
      if (response?.status) {
        toast.success(response?.message || 'Campaign deleted successfully!');
        handleGetAllCampaign(page);
        setDeleteModal(false);
      } else {
        toast.error(response?.message || 'Failed to delete campaign');
      }
    } catch (error) {
      toast.error('Error deleting campaign');
      console.error('Error deleting campaign:', error);
    }
  }, [campaign?.id, page, handleGetAllCampaign]);

  const reportListColumns = [
    {
      header: 'No',
      accessorKey: 'id',
      Cell: ({ row }) => <span>{parseInt(row?.id || 0, 10) + 1}</span>,
    },
    {
      header: 'Broadcast Name',
      accessorKey: 'name',
    },
    {
      header: 'Date',
      accessorKey: 'createdAt',
      Cell: DateCellRenderer,
    },
    {
      header: 'Audiences',
      accessorKey: 'contactGroupId', // Reference the parent object
      Cell: ({ row }) => {
        const length = row?.original?.contactGroupId?.contactIds?.length || 0;
        return <span>{length}</span>;
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      Cell: StatusCellRenderer,
    },
    {
      accessorKey: 'menu',
      header: 'Actions',
      enableSorting: false,
      Cell: ({ row }) => (
        <ActionButtons
          report={row?.original}
          channel={channel}
          onDelete={(report) => {
            setCampaign(report);
            setDeleteModal(true);
          }}
          page={page}
        />
      ),
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const contactGroupPage = queryParams.get('page');
    const currentPage = contactGroupPage ?? page;
    // meta title
    document.title = 'Broadcast List';
    setPage(currentPage);
    handleGetAllCampaign(currentPage);
  }, []);

  const destinationPath = channel?.toLowerCase() === CHANNEL_TYPES.WHATSAPP
    ? '/send-whatsapp-message'
    : '/send-message';

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Campaign" breadcrumbItem="Broadcast List" />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCampaign}
          onCloseClick={() => setDeleteModal(false)}
        />

        <Row className="mt-2">
          <Col lg="12">
            <Card>
              <CardBody>
                <Col sm="12" className="d-flex justify-content-end align-items-center">
                  <div className="text-sm-end">
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary"
                      onClick={() => history.push(destinationPath)}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Create Broadcast
                    </Button>
                  </div>
                </Col>
                <div className="mt-3">
                  <DataGrid
                    data={campaigns}
                    columns={reportListColumns}
                    enableColumnResizing={false}
                    enableColumnFilterModes
                    enableGlobalFilter
                    enableEditing={false}
                    selectedRows={selectedRows}
                    onRowSelectionChange={setSelectedRows}
                    totalSize={totalSize}
                    onPageChange={(selectedPage) => {
                      setPage(selectedPage);
                      handleGetAllCampaign(selectedPage);
                    }}
                    onPageSizeChange={(newPageSize) => {
                      setSize(newPageSize);
                      setPage(1);
                      handleGetAllCampaign(1, newPageSize);
                    }}
                    initialPage={0}
                    initialPageSize={10}
                    onSearch={(value) => {
                      setSearchQuery(value);
                      handleGetAllCampaign(page);
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
// Prop Types for main-component
BroadcastList.propTypes = {
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
// Prop Types for sub-components
DateCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};
StatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUS_TYPES)).isRequired,
};
ActionButtons.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    channel: PropTypes.string.isRequired,
    contactGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    dateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    senderNumberId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    senderGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    usingCopilot: PropTypes.bool,
    template: PropTypes.arrayOf(PropTypes.object),
    whatsappTemplate: PropTypes.shape({
      templateName: PropTypes.string,
      template: PropTypes.shape({
        language: PropTypes.shape({
          code: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  channel: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};
// Add prop validation for cell renderer components
const CellRendererPropTypes = {
  renderedCellValue: PropTypes.any,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
DateCellRenderer.propTypes = CellRendererPropTypes;
StatusCellRenderer.propTypes = CellRendererPropTypes;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastList);
