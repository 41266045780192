/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';
import styled from 'styled-components';
import { CHANNEL_CONNECT_STATUS, CHANNEL_TYPES_SETTINGS } from '../../../../constants/channels/channels.constant';
import { setShowWhatsappCredentialsModal } from '../../../../store/actions';
import { UserContext } from '../../../UserProvider/UserProvider';
import { getChannels } from '../../../../utils/channels';
import { updateOrganization } from '../../../../services/api/organization.service';

const StyledDialog = styled(Dialog)`
  .p-dialog-content {
    padding: 2rem;
  }
`;

const FormRow = styled.div`
  margin-bottom: 1.5rem;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

function WhatsappCredentialsModal({ getPrivateCredentials, privateCreds }) {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.channels.showWhatsappCredentialsModal);
  const { userData } = useContext(UserContext);
  const [toast, setToast] = useState(null);

  const initialValues = {
    metaSystemUserAccessToken: '',
  };

  const toggle = () => {
    dispatch(setShowWhatsappCredentialsModal());
  };

  const validationSchema = Yup.object().shape({
    metaSystemUserAccessToken: Yup.string().required('Meta System User Access Token is required'),
  });

  const handleUpdateWhatsappCredentials = async (updateBody) => {
    try {
      const organizationId = userData?.organizationId;
      const response = await updateOrganization(organizationId, updateBody);
      if (response?.status) {
        getPrivateCredentials();
        toast.current.show({ severity: 'success', summary: 'Success', detail: response?.message || 'Whatsapp credentials updated successfully!' });
        toggle();
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response?.message || 'Oops! Something went wrong' });
      }
    } catch (error) {
      console.error('Error while updating Whatsapp credentials:', error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred' });
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    formik.resetForm();

    const whatsappCreds = {
      metaSystemUserAccessToken: values.metaSystemUserAccessToken,
    };

    const updateBody = {
      channels: [
        {
          type: CHANNEL_TYPES_SETTINGS.WHATSAPP,
          status: CHANNEL_CONNECT_STATUS.CONNECTED,
          config: whatsappCreds,
        },
      ],
    };

    if (Object.keys(whatsappCreds).length > 0) {
      handleUpdateWhatsappCredentials(updateBody);
    }
  }

  const setCredentials = () => {
    const { whatsapp } = getChannels(privateCreds);
    formik.setValues({
      metaSystemUserAccessToken: whatsapp?.config?.metaSystemUserAccessToken || '',
    });
  };

  useEffect(() => {
    setCredentials();
  }, [privateCreds]);

  const handleDisconnectWhatsapp = async () => {
    const whatsappCreds = {
      metaSystemUserAccessToken: '',
    };
    const updateBody = {
      channels: [
        {
          type: CHANNEL_TYPES_SETTINGS.WHATSAPP,
          status: CHANNEL_CONNECT_STATUS.DISCONNECTED,
          config: whatsappCreds,
        },
      ],
    };

    handleUpdateWhatsappCredentials(updateBody);
  };

  const { whatsapp } = getChannels(privateCreds);

  return (
    <>
      <Toast ref={setToast} />
      <StyledDialog
        header="Whatsapp Credentials"
        visible={isModalOpen}
        onHide={toggle}
        modal
      >
        <form onSubmit={formik.handleSubmit}>
          <FormRow>
            <span className="p-float-label">
              <InputText
                id="metaSystemUserAccessToken"
                name="metaSystemUserAccessToken"
                value={formik.values.metaSystemUserAccessToken}
                onChange={formik.handleChange}
                className={formik.errors.metaSystemUserAccessToken && formik.touched.metaSystemUserAccessToken ? 'p-invalid' : ''}
              />
              <label htmlFor="metaSystemUserAccessToken">Meta System User Access Token</label>
            </span>
            <Tooltip target=".meta-token-info" />
            <i className="bx bx-info-circle meta-token-info" data-pr-tooltip="Enter your Meta System User Access Token here." />
            {formik.errors.metaSystemUserAccessToken && formik.touched.metaSystemUserAccessToken && (
              <small className="p-error">{formik.errors.metaSystemUserAccessToken}</small>
            )}
          </FormRow>

          <ButtonRow>
            {whatsapp?.status === CHANNEL_CONNECT_STATUS.CONNECTED && (
              <Button
                type="button"
                onClick={handleDisconnectWhatsapp}
                className="p-button-outlined p-button-danger"
                icon="bx bx-disconnect"
                label="Disconnect"
              />
            )}
            <Button
              type="submit"
              className="p-button-success"
              icon="bx bx-save"
              label="Save"
            />
          </ButtonRow>
        </form>
      </StyledDialog>
    </>
  );
}

WhatsappCredentialsModal.propTypes = {
  getPrivateCredentials: PropTypes.func.isRequired,
  privateCreds: PropTypes.array.isRequired,
};

export default WhatsappCredentialsModal;
