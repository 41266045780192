import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';
import { CHANNEL_TYPES } from '../../../../constants/channels/channels.constant';
import { fetchAssignedPhoneNumbers } from '../../../../services/api/phoneNumber.service';
import { fetchAssignedWhatsappNumbers } from '../../../../services/api/whatsappNumber.service';
import {
  addContactToGroup,
  deleteContactFromGroup,
} from '../../../../services/api/contactGroups.service';
import { toggleCallWidgetVisibility as toggleCallWidgetVisibilityAction } from '../../../../store/actions';
import { UserContext } from '../../../../components/UserProvider/UserProvider';
import StyledSwitch from '../../../../components/Switch';
import {
  axiosDel, axiosGet, axiosPatch, axiosPost,
} from '../../../../services/http.service';
import { PAGE, SIZE_PER_PAGE } from '../../../../constants/constants';
import { getInitials, getTimezoneDisplay } from '../../../../helpers/commonHelpers';
import { updateContact } from '../../../../services/api/contacts.service';
import {
  ADDRESS_ICONS,
  LEAD_STAGE_OPTIONS,
  OPT_IN_OPTION,
  OPT_OUT_OPTION,
} from '../../../../constants/contacts.constant';
import Select from '../../../../components/Select';
import EditableFieldWrapper from '../../../../components/EditableFieldWrapper';
import { getProfileNameFromUrl } from '../../../../utils/url';
import StyledContainerWithLabel from '../../../../components/ContainerWithLabel';
import PhoneNumberWithFlag from '../../../../components/PhoneNumberWithFlag/index';
import TextField from '../../../../components/EditableFieldWrapper/TextField';
import CustomButton from '../../../../components/PrimeButton';
import Dropdown from '../../../../components/Dropdown';
import { capitalizeFirstLetter } from '../../../../utils/general';
import { queryConversation } from '../../../../services/api/conversation.service';
import CustomListBox from '../../../../components/ListBox';

export class View extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
      options: [],
      isLoading: false,
      size: SIZE_PER_PAGE,
      page: PAGE,
      totalPages: 0,
      contactGroupOptions: [],
      selectedContactGroups: [],
      contactGroupPage: PAGE,
      totalContactGroupPages: 0,
      isLoadingContacts: false,
      leadStage: null,
      marketingOptIn: false,
      socialProfiles: null,
      company: null,
      address: null,
      email: null,
      firstName: null,
      lastName: null,
      dnd: null,
      showSenderNumbers: false,
      availableSenderNumbers: [],
      recipientNumber: null,
      currentChannel: null,
    };
  }

  componentDidMount() {
    this.componentDidMountAsync();
  }

  componentDidUpdate(prevProps) {
    const { contact } = this.props;
    if (prevProps.contact !== contact) {
      this.getAllTags();
      this.initializeTags(contact);
      this.getAllContactGroups();
      if (contact && contact.id) {
        this.getAllContactGroupsByContactId(contact?.id);
      }
    }
  }

  componentDidMountAsync = async () => {
    const { contact } = this.props;
    this.setState({
      leadStage: {
        label: LEAD_STAGE_OPTIONS.find((option) => option.value === contact?.leadStage)?.label,
        value: contact?.leadStage,
      },
      marketingOptIn: contact?.marketingOptIn,
      socialProfiles: contact?.socialProfiles,
      company: contact?.company,
      address: {
        address: contact?.address,
        city: contact?.city,
        state: contact?.state,
        zip: contact?.zip,
      },
      email: contact?.email,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      dnd: contact?.dnd,
    });
    await Promise.allSettled([
      this.getAllTags(),
      this.initializeTags(contact),
      this.getAllContactGroups(),
      this.getAllContactGroupsByContactId(contact?.id),
    ]);
  };

  handleChatNavigation = async (channel, phoneNumber, senderNumber) => {
    const { history } = this.props;
    try {
      const sanitizeString = (value) => value?.trim().replace(/\s+/g, '');

      const query = sanitizeString(phoneNumber?.replace(/\D/g, ''));
      const sanitizedSenderNumber = sanitizeString(senderNumber);
      const filter = `channel=${channel}`.replace(/\s+/g, '');
      const tabData = {
        key: 'from',
        value: sanitizedSenderNumber,
      };
      const existingConversations = await queryConversation(1, '', query, tabData, filter);
      const conversationExists = existingConversations?.data?.results?.length > 0;
      if (conversationExists) {
        history.push(
          `/inbox?activeTab=${channel}&to=${phoneNumber}&from=${senderNumber.replace(/\D/g, '')}&initiate=${false}`,
        );
      } else {
        history.push(
          `/inbox?activeTab=${channel}&to=${phoneNumber}&from=${senderNumber.replace(/\D/g, '')}&initiate=${true}`,
        );
      }
    } catch (error) {
      console.error('Error navigating to chat:', error);
      toast.error('Error initiating conversation');
    }
  }

  handleWhatsAppClick = async () => {
    const { contact } = this.props;
    const phoneNumber = `${contact.countryCode}${contact.phoneNumber}`;
    try {
      const whatsappNumbers = await fetchAssignedWhatsappNumbers();
      if (!whatsappNumbers?.length) {
        toast.error('No WhatsApp number available');
        return;
      }

      if (whatsappNumbers.length === 1) {
        this.handleChatNavigation(CHANNEL_TYPES.WHATSAPP, phoneNumber, whatsappNumbers[0].phoneNumber);
        return;
      }

      this.setState({
        showSenderNumbers: true,
        availableSenderNumbers: whatsappNumbers,
        recipientNumber: phoneNumber,
        currentChannel: CHANNEL_TYPES.WHATSAPP,
      });
    } catch (error) {
      console.error('Error fetching WhatsApp numbers:', error);
      toast.error('Error initiating WhatsApp conversation');
    }
  }

  handleSMSClick = async () => {
    const { contact } = this.props;
    const phoneNumber = `${contact.countryCode}${contact.phoneNumber}`;
    try {
      const textNumbers = await fetchAssignedPhoneNumbers();
      if (!textNumbers?.length) {
        toast.error('No SMS number available');
        return;
      }

      if (textNumbers.length === 1) {
        this.handleChatNavigation(CHANNEL_TYPES.TEXT, phoneNumber, textNumbers[0].phoneNumber);
        return;
      }

      this.setState({
        showSenderNumbers: true,
        availableSenderNumbers: textNumbers,
        recipientNumber: phoneNumber,
        currentChannel: CHANNEL_TYPES.TEXT,
      });
    } catch (error) {
      console.error('Error fetching SMS numbers:', error);
      toast.error('Error initiating SMS conversation');
    }
  }

  handleSenderNumberSelect = (senderNumber) => {
    const { recipientNumber, currentChannel } = this.state;
    this.setState({
      showSenderNumbers: false,
      currentChannel: null,
    });
    this.handleChatNavigation(currentChannel, recipientNumber, senderNumber);
  }

  handleGenericChange = async (key, value) => {
    const response = await this.handleUpdateContact({ [key]: value?.value || value });
    if (response) {
      this.setState({ [key]: value });
    }
  };

  handleNestedChange = async (parentKey, childKey, value) => {
    const response = await this.handleUpdateContact({
      [parentKey]: {
        // eslint-disable-next-line react/destructuring-assignment
        ...this.state[parentKey],
        [childKey]: value,
      },
    });
    if (response) {
      this.setState((prevState) => ({
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    }
  };

  handleAddressInfo = async (key, value) => {
    const response = await this.handleUpdateContact({ [key]: value });
    if (response) {
      this.setState((prevState) => ({
        address: {
          ...prevState.address,
          [key]: value,
        },
      }));
    }
  };

  getAllTags = async () => {
    const { page, size } = this.state;
    const { userData } = this.context;
    const activeWorkspace = userData.activeWorkspace.workspaceId;
    try {
      this.setState({ isLoading: true });
      const response = await axiosGet(
        `/workspace/${activeWorkspace}/tags?page=${page}&limit=${size}`,
      );
      if (response?.status) {
        const options = response?.data?.results?.map((tag) => ({
          label: tag?.name,
          value: tag?._id,
        }));
        this.setState((prevState) => {
          const newOptions = options.filter(
            (option) => !prevState.options?.some((tag) => tag?.value === option.value),
          );
          return {
            options: [...prevState.options, ...newOptions],
            isLoading: false,
            page: prevState.page + 1,
            totalPages: response.data.totalPages,
          };
        }, this.filterOptions);
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
      this.setState({
        isLoading: false,
      });
    }
  };

  filterOptions = () => {
    const { selectedOptions, options } = this.state;
    const selectedValues = selectedOptions.map((option) => option.value);
    const filteredOptions = options.filter((option) => !selectedValues.includes(option.value));
    this.setState({ options: filteredOptions });
  };

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option' || actionMeta.action === 'select-option') {
      const newOption = newValue[newValue.length - 1];
      this.setState((prevState) => ({
        options: prevState.options.filter((option) => option.value !== newOption.value),
      }));
      this.handleCreateTag(newOption);
    } else {
      if (actionMeta.action === 'remove-value') {
        const removedTag = actionMeta.removedValue;
        this.setState(
          () => ({
            selectedOptions: newValue,
          }),
          () => this.handleRemoveTag(removedTag),
        );
      }
      this.setState({ selectedOptions: newValue });
    }
  };

  handleUpdateContact = async (updatedData) => {
    const { getAllContacts, contact } = this.props;
    try {
      const response = await updateContact(contact?.id, updatedData);
      if (response?.status) {
        toast.success(response?.message || 'Updated!', {
          position: 'top-right',
        });
        getAllContacts();
        return response?.status;
      }
      toast.error(response?.message || 'Oops! something went wrong', {
        position: 'top-right',
      });
      return false;
    } catch (error) {
      console.error('error while updating a contact:', error);
      return false;
    }
  };

  handleCreateTag = async (inputValue) => {
    const { contact, getAllContacts } = this.props;
    const contactId = contact?.id;
    try {
      this.setState({ isLoading: true });
      const input = inputValue?.label.toLowerCase();
      const tagBody = { name: input };
      const updatedContact = await axiosPatch(`/contacts/${contactId}/tags`, tagBody);
      if (updatedContact?.status) {
        const { tags } = updatedContact?.data;
        toast.success(updatedContact?.message || 'Tag created successfully for the contact');
        if (getAllContacts) {
          getAllContacts();
        }
        this.setState(
          (prevState) => ({
            selectedOptions: [
              ...prevState.selectedOptions,
              {
                label: inputValue.label,
                value: tags[tags.length - 1].tagId,
              },
            ],
            isLoading: false,
          }),
          this.filterOptions,
        );
      } else {
        toast.error(updatedContact?.message || 'Oops something went wrong');
        this.setState({ isLoading: false });
      }
    } catch (error) {
      toast.error('error at handleCreateTag:', error);
    }
  };

  handleRemoveTag = async (tag) => {
    const { contact, getAllContacts } = this.props;
    const contactId = contact?.id;
    const tagId = tag.value;
    try {
      await axiosDel(`/contacts/${contactId}/tags/${tagId}`);
      toast.success('Tag removed successfully from the contact');
      if (getAllContacts) {
        getAllContacts();
      }
      this.setState((prevState) => ({
        options: [...prevState.options, tag],
        isLoading: false,
      }));
    } catch (error) {
      toast.error(`Error removing tag: ${error}`);
      this.setState({ isLoading: false });
    }
  };

  customNoOptionsMessage = (inputValue) => (
    <span className="">{`Create new tag: ${inputValue.inputValue}`}</span>
  );

  handleMenuScrollToBottom = () => {
    const { page, totalPages, isLoading } = this.state;
    if (!isLoading && page <= totalPages) {
      this.getAllTags();
    }
  };

  filterSelectedOptions = (allOptions) => {
    const { selectedContactGroups } = this.state;
    return allOptions.filter(
      (option) => !selectedContactGroups.some((selected) => selected.value === option.value),
    );
  };

  getAllContactGroups = async () => {
    const { contactGroupPage, size } = this.state;
    try {
      this.setState({ isLoadingContacts: true });
      const response = await axiosGet(
        `/contact-groups?page=${contactGroupPage}&limit=${size}&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        const options = response.data.results.map((group) => ({
          label: group.name,
          value: group.id,
        }));
        const newOptions = this.filterSelectedOptions(options);
        this.setState((prevState) => ({
          contactGroupOptions: [...prevState.contactGroupOptions, ...newOptions],
          contactGroupPage: prevState.contactGroupPage + 1,
          totalContactGroupPages: response.data.totalPages,
          isLoadingContacts: false,
        }));
      } else {
        toast.error(response?.message || 'Oops something went wrong');
        this.setState({ isLoading: false });
      }
    } catch (error) {
      toast.error('Error fetching contact groups:', error);
      this.setState({ isLoading: false });
    }
  };

  getAllContactGroupsByContactId = async (contactId) => {
    try {
      const response = await axiosGet(`/contact-groups/contacts/${contactId}`);
      if (response?.status) {
        const selectedGroups = response.data.results.map((group) => ({
          label: group.name,
          value: group.id,
        }));
        this.setState({
          selectedContactGroups: selectedGroups,
        });
      } else {
        toast.error(response?.message || 'Oops something went wrong');
        this.setState({ isLoadingContacts: false });
      }
    } catch (error) {
      console.error('Error fetching contact groups by contact id:', error);
      this.setState({ isLoadingContacts: false });
    }
  };

  handleChangeContactGroups = async (newValue, actionMeta) => {
    const { contact } = this.props;
    switch (actionMeta.action) {
      case 'create-option':
        this.setState({}, () => this.handleCreateGroup(newValue[newValue.length - 1]));
        break;
      case 'select-option':
        this.setState({}, () => this.addContactToContactGroup(newValue[newValue.length - 1], contact?.id));
        break;
      case 'remove-value':
        this.setState({ selectedContactGroups: newValue }, () => this.handleRemoveGroup(actionMeta.removedValue, contact?.id));
        break;
      default:
        break;
    }
  };

  handleCreateGroup = async (inputValue) => {
    const { contact } = this.props;
    try {
      this.setState({ isLoadingContacts: true });
      const input = inputValue.label.toLowerCase();
      const groupBody = { name: input /* contacts:[contactDetails] */ }; // Define your group structure
      const response = await axiosPost('/contact-groups', groupBody);

      if (response?.status) {
        toast.success(response?.message || 'Group created successfully');
        const newGroup = response.data;
        const newGroupOption = {
          label: newGroup.name,
          value: newGroup.id,
        };
        this.setState(() => ({
          isLoadingContacts: false,
        }));
        await this.addContactToContactGroup(newGroupOption, contact.id);
      } else {
        toast.error(response?.message || 'Oops something went wrong');
        this.setState({ isLoadingContacts: false });
      }
    } catch (error) {
      toast.error('Error at handleCreateGroup:', error);
      this.setState({ isLoadingContacts: false });
    }
  };

  handleRemoveGroup = async (group) => {
    const { contact } = this.props;
    try {
      await deleteContactFromGroup(contact.id, group.value);
      this.setState((prevState) => {
        const updatedSelectedGroups = prevState.selectedContactGroups.filter(
          (sg) => sg.value !== group.value,
        );
        const updatedOptions = prevState.contactGroupOptions.filter(
          (option) => option.value !== group.value,
        );

        return {
          selectedContactGroups: updatedSelectedGroups,
          contactGroupOptions: [...updatedOptions, group],
          isLoadingContacts: false,
        };
      });
    } catch (error) {
      console.error('Error removing contact from group', error);
    } finally {
      this.setState({ isLoadingContacts: false });
    }
  };

  addContactToContactGroup = async (groupData, contactId) => {
    const contactGroupId = groupData.value;
    try {
      const contactData = {
        contactId,
      };
      const response = await addContactToGroup(contactGroupId, contactData);
      if (response.status) {
        this.setState((prevState) => ({
          selectedContactGroups: [...prevState.selectedContactGroups, groupData],
          isLoadingContacts: false,
        }));
      }
    } catch (error) {
      console.error(`Error adding contact to group: ${error}`);
    }
  };

  handleMenuScrollToBottomGroups = () => {
    const { contactGroupPage, totalContactGroupPages, isLoadingContacts } = this.state;
    if (!isLoadingContacts && contactGroupPage <= totalContactGroupPages) {
      this.getAllContactGroups();
    }
  };

  customNoOptionsMessageGroups = (inputValue) => (
    <span>{`Create new group: ${inputValue.inputValue}`}</span>
  );

  handleCallIconClick = (phoneNumber) => {
    const { toggleCallWidgetVisibility } = this.props;
    toggleCallWidgetVisibility(phoneNumber);
  };

  onEmailClick = () => {
    const { email } = this.state;
    window.location.href = `mailto:${email}`;
  };

  initializeTags = (contact) => {
    if (contact && contact.tags) {
      const selectedOptions = contact?.tags?.map((tag) => ({
        label: tag?.tagName,
        value: tag?.tagId,
      }));
      this.setState({ selectedOptions }, this.filterOptions);
    }
  };

  // Template to render items with icons, labels, and numbers
  itemTemplate = (option) => (
    <div className="p-dropdown-item d-flex p-0">
      <div className="d-flex align-items-center me-2">
        {option.icon && <i className={`item-icon ${option.icon}`} />}
      </div>
      <div className="d-flex flex-column">
        <span className="item-label">{option?.label}</span>
      </div>
    </div>
  );

  // Template for the selected value
  valueTemplate = (option) => {
    if (option) {
      return (
        <div className="p-dropdown-value d-flex flex-column p-0">
          <div className="d-flex align-items-center me-3">
            {option.icon && <i className={`item-icon ${option?.icon}`} />}
          </div>
          <div className="d-flex flex-column">
            <span className="item-label">{option?.label}</span>
          </div>
        </div>
      );
    }
    return (
      <span>
        <i className="mdi mdi-dots-vertical font-size-18 text-primary" />
      </span>
    );
  };

  render() {
    const { contact } = this.props;

    const {
      selectedOptions,
      isLoading,
      selectedContactGroups,
      isLoadingContacts,
      contactGroupOptions,
      options,
      leadStage,
      marketingOptIn,
      socialProfiles,
      company,
      address,
      email,
      firstName,
      lastName,
      dnd,
      currentChannel,
      availableSenderNumbers,
      showSenderNumbers,
    } = this.state;
    const name = `${contact?.firstName} ${contact?.lastName}`;
    const customStyles = {
      control: (base) => ({
        ...base,
        minHeight: 'unset',
      }),
    };
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {contact?.id && (
          <div className="mt-3">
            <Container fluid>
              <Card className="border-bottom border-primary">
                <CardBody>
                  <Row className="">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle avatar-md bg-primary d-flex align-items-center justify-content-center me-3">
                        <span className="fw-bold text-light fs-3">{getInitials(name)}</span>
                      </div>
                      <div>
                        <h5 className="fw-bold mb-1">
                          {capitalizeFirstLetter(firstName)}
                          {' '}
                          {capitalizeFirstLetter(lastName)}
                        </h5>
                        <TextField
                          displayValue={(
                            <PhoneNumberWithFlag
                              phoneNumber={contact?.phoneNumberString}
                              className="font-size-14"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col className="ps-3 pt-2">
                      <div className="d-flex flex-column text-secondary">
                        {email && (
                          <EditableFieldWrapper
                            value={email}
                            displayValue={email}
                            iconClass="mdi mdi-email font-size-18 me-1"
                            className="text-wrap d-flex font-size-14 mb-1"
                            onEdit={(newValue) => this.handleGenericChange('email', newValue)}
                            placeholderInput="Email"
                            placeholderValue="Email"
                            classNameInput="mb-2"
                          />
                        )}
                        {address && (
                          <EditableFieldWrapper
                            value={address?.city}
                            displayValue={capitalizeFirstLetter(address?.city)}
                            className="d-flex font-size-14 mb-1"
                            iconClass="d-flex mdi mdi-home-map-marker font-size-20"
                            onEdit={(newValue) => this.handleAddressInfo('city', newValue)}
                            placeholderInput="City"
                            placeholderValue="City"
                          />
                        )}
                        <TextField
                          displayValue={getTimezoneDisplay(contact?.phoneNumberString)}
                          className="text-nowrap font-size-14"
                          iconClass="mdi mdi-web-clock font-size-18 me-2"
                          gap={0}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center mt-3">
                    <div className="d-flex justify-content-evenly align-items-center gap-3">
                      <CustomButton
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Call"
                        className="bg-primary-subtle rounded-5 border-0 m-0"
                        icon="mdi mdi-phone font-size-18 text-primary"
                        onClick={() => this.handleCallIconClick(`+${contact.countryCode}${contact.phoneNumber}`)}
                      />
                      <CustomButton
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Whatsapp"
                        className="bg-primary-subtle rounded-5 border-0 m-0"
                        onClick={this.handleWhatsAppClick}
                        icon="mdi mdi-whatsapp font-size-18 text-primary"
                      />
                      <CustomButton
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Message"
                        className="bg-primary-subtle rounded-5 border-0 m-0"
                        onClick={this.handleSMSClick}
                        icon="mdi mdi-message-text font-size-18 text-primary"
                      />
                      <CustomButton
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Email"
                        className="bg-primary-subtle rounded-5 border-0 m-0"
                        onClick={this.onEmailClick}
                        icon="mdi mdi-email font-size-18 text-primary"
                      />
                      <Dropdown
                        value={{ label: '', value: '' }}
                        options={dnd ? OPT_IN_OPTION : OPT_OUT_OPTION}
                        onChange={() => this.handleGenericChange('dnd', !dnd)}
                        optionLabel="label"
                        placeholder={
                          <i className="mdi mdi-dots-vertical font-size-18 text-primary" />
                        }
                        className="more-options"
                        dropdownIcon=""
                        valueTemplate={this.valueTemplate}
                        itemTemplate={this.itemTemplate}
                      />
                    </div>
                    {showSenderNumbers && availableSenderNumbers.length > 0 && (
                      <div className="mt-3 d-flex flex-column gap-2">
                        <p className="text-muted mb-2">
                          Select a number to send
                          {' '}
                          {currentChannel === CHANNEL_TYPES.WHATSAPP ? 'Whatsapp' : 'Text'}
                          {' '}
                          from:
                        </p>
                        <CustomListBox
                          options={availableSenderNumbers.map((number) => ({
                            label: number.phoneNumber,
                            value: number.phoneNumber,
                          }))}
                          onChange={(e) => this.handleSenderNumberSelect(e.value)}
                          itemTemplate={(option) => (
                            <PhoneNumberWithFlag phoneNumber={option.label} className="font-size-14" />
                          )}
                          className="border rounded"
                          mdWidth="100%"
                        />
                      </div>
                    )}
                  </Row>
                </CardBody>
              </Card>
              <UncontrolledAccordion defaultOpen="1" className="">
                <Row className="pb-2 d-flex align-items-center">
                  <Col className="col-12 d-flex flex-column">
                    <Card>
                      <AccordionItem className="border-0">
                        <div>
                          <AccordionHeader targetId="1">
                            <div className="d-flex align-items-center gap-2 w-100">
                              <span className="fw-semibold">About This Contact</span>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="1" className="">
                            <StyledContainerWithLabel
                              label="Name"
                              className="mt-0"
                              classNameLabel="font-size-16"
                            >
                              {firstName && (
                                <EditableFieldWrapper
                                  value={firstName}
                                  displayValue={capitalizeFirstLetter(firstName)}
                                  onEdit={(newValue) => this.handleGenericChange('firstName', newValue)}
                                  placeholderInput="First Name"
                                  iconClass="mdi mdi-account-edit me-2 font-size-18"
                                  className="text-nowrap mb-2 font-size-14"
                                  placeholderValue="First Name"
                                  classNameInput="mb-4"
                                />
                              )}
                              {lastName && (
                                <EditableFieldWrapper
                                  value={lastName}
                                  displayValue={capitalizeFirstLetter(lastName)}
                                  onEdit={(newValue) => this.handleGenericChange('lastName', newValue)}
                                  placeholderInput="Last Name"
                                  iconClass="mdi mdi-account-edit me-2 font-size-18"
                                  className="text-nowrap font-size-14"
                                  placeholderValue="First Name"
                                  classNameInput="mb-3"
                                />
                              )}
                            </StyledContainerWithLabel>
                            <Select
                              label="Tags"
                              iconClass="mdi mdi-tag-multiple fs-5 me-2"
                              optionIcon="mdi mdi-tag-text font-size-16"
                              isCreatable
                              isMulti
                              options={options}
                              value={selectedOptions}
                              onChange={this.handleChange}
                              styles={customStyles}
                              placeholder="Select or create tags..."
                              noOptionsMessage={this.customNoOptionsMessage}
                              isLoading={isLoading}
                              isDisabled={isLoading}
                              onMenuScrollToBottom={this.handleMenuScrollToBottom}
                              className="mb-3"
                            />
                            <Select
                              label="Contact Groups"
                              iconClass="mdi mdi-account-group fs-5 me-2"
                              isCreatable
                              isMulti
                              options={contactGroupOptions}
                              value={selectedContactGroups}
                              onChange={this.handleChangeContactGroups}
                              styles={customStyles}
                              placeholder="Select or create groups..."
                              noOptionsMessage={this.customNoOptionsMessageGroups}
                              isLoading={isLoadingContacts}
                              isDisabled={isLoadingContacts}
                              onMenuScrollToBottom={this.handleMenuScrollToBottomGroups}
                              maxMenuHeight={200}
                              className="mb-3"
                            />
                            <Select
                              label="Lead Stage"
                              iconClass="bx bx-user fs-5 me-2"
                              options={LEAD_STAGE_OPTIONS}
                              value={leadStage}
                              onChange={(selectedOption) => this.handleGenericChange('leadStage', selectedOption)}
                              placeholder="Lead Stage..."
                              isMulti={false}
                              isCreatable={false}
                              styles={customStyles}
                              className="mb-3"
                            />
                            <StyledSwitch
                              label="Marketing Opt-In"
                              checked={marketingOptIn}
                              onChange={(event) => this.handleGenericChange('marketingOptIn', event.value)}
                              name={marketingOptIn ? 'Yes' : 'No'}
                            />
                            {socialProfiles && (
                              <StyledContainerWithLabel
                                label="Social Profiles"
                                classNameLabel="font-size-16"
                              >
                                {Object.keys(socialProfiles)?.map((profile) => {
                                  const profileUrl = socialProfiles[profile];
                                  const profileName = getProfileNameFromUrl(profileUrl);
                                  return (
                                    <EditableFieldWrapper
                                      key={profile}
                                      value={profileUrl}
                                      iconClass={`mdi mdi-${profile.toLowerCase()} me-2 font-size-18`}
                                      onEdit={(newUrl) => this.handleNestedChange('socialProfiles', profile, newUrl)}
                                      displayValue={profileName}
                                      placeholderValue={`${`${capitalizeFirstLetter(
                                        profile,
                                      )} url`}`}
                                      placeholderInput={`${`${capitalizeFirstLetter(
                                        profile,
                                      )} url`}`}
                                      link
                                      classNameInput="mb-4"
                                      classNameLink="mb-2 font-size-14"
                                    />
                                  );
                                })}
                              </StyledContainerWithLabel>
                            )}
                            {address && (
                              <StyledContainerWithLabel
                                label="Address"
                                classNameLabel="font-size-16"
                              >
                                {Object.keys(address).map((key) => (
                                  <EditableFieldWrapper
                                    key={key}
                                    value={address[key]}
                                    iconClass={`${
                                      ADDRESS_ICONS[key] || 'mdi mdi-home'
                                    } me-2 font-size-18`}
                                    displayValue={`${capitalizeFirstLetter(address[key])}`}
                                    placeholderValue={`${capitalizeFirstLetter(key)}`}
                                    onEdit={(newValue) => this.handleAddressInfo(key, newValue)}
                                    placeholderInput={`${capitalizeFirstLetter(key)}`}
                                    classNameInput="mb-4"
                                    className="mb-2 font-size-14"
                                  />
                                ))}
                              </StyledContainerWithLabel>
                            )}
                          </AccordionBody>
                        </div>
                      </AccordionItem>
                    </Card>
                    <Card>
                      <AccordionItem className="border-0">
                        <div>
                          <AccordionHeader targetId="2">
                            <div className="d-flex align-items-center gap-2 w-100">
                              <span className="fw-semibold font-size-16">Company</span>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            {company && (
                              <StyledContainerWithLabel label="Company" className="mt-0">
                                <EditableFieldWrapper
                                  value={company?.name}
                                  displayValue={company?.name}
                                  placeholderValue="Company Name"
                                  iconClass="mdi mdi-office-building me-2 font-size-18"
                                  onEdit={(newValue) => this.handleNestedChange('company', 'name', newValue)}
                                  placeholderInput="Company Name"
                                  classNameInput="mb-4"
                                  className="mb-2 font-size-14"
                                />
                                <EditableFieldWrapper
                                  value={company?.website}
                                  displayValue={company?.website}
                                  placeholderValue="Website Url"
                                  iconClass="mdi mdi-web  font-size-18 me-2"
                                  onEdit={(newUrl) => this.handleNestedChange('company', 'website', newUrl)}
                                  placeholderInput="Website Url"
                                  link
                                  classNameLink="mb-3 font-size-14"
                                />
                              </StyledContainerWithLabel>
                            )}
                          </AccordionBody>
                        </div>
                      </AccordionItem>
                    </Card>
                  </Col>
                </Row>
              </UncontrolledAccordion>
            </Container>
          </div>
        )}
      </>
    );
  }
}

View.propTypes = {
  contact: PropTypes.object,
  getAllContacts: PropTypes.func,
  toggleCallWidgetVisibility: PropTypes.func,
  history: PropTypes.any,
};

const mapStateToProps = (state) => ({
  phoneNumber: state.CallWidget.phoneNumber,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCallWidgetVisibility: (phoneNumber) => dispatch(toggleCallWidgetVisibilityAction(phoneNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(View));
