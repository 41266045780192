/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Card, CardBody, Col, Container, Row, Label,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

// actions

// import images
import { axiosGet, axiosPost } from '../../services/http.service';
import { getDataFromLocalStorage, setDataInLocalStorage } from '../../helpers/commonHelpers';
import { UserContext } from '../../components/UserProvider/UserProvider';
import profile from '../../assets/images/common/profile-img.png';
import {
  apiError as apiErrorAction,
  socialLogin,
  addNotification as addNotificationAction,
  setTwilioVoiceToken as setTwilioVoiceTokenAction,
  toggleInitializeNewSocket as toggleInitializeNewSocketAction,
} from '../../store/actions';
import LogoIcon from '../../assets/images/logo-icon.png';
import { SOMETHING_WENT_WRONG } from '../../constants/error.constant';

class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  // static contextType = UserContext;

  componentDidMount() {
    const { history, match, apiError } = this.props;
    const { userId } = match.params;
    apiError('');
    const accessToken = getDataFromLocalStorage('accessToken');
    if (accessToken) {
      history.push('/');
    }
    if (userId) {
      this.handleLoginAdminAsUser(userId);
    }
  }

  handleLoginAdminAsUser = async (userId) => {
    try {
      const { getUserinfo } = this.context;
      const { toggleInitializeNewSocket, history, apiError } = this.props;
      setDataInLocalStorage('adminAccessToken', getDataFromLocalStorage('accessToken'));
      setDataInLocalStorage('adminRefreshToken', getDataFromLocalStorage('refreshToken'));

      const response = await axiosGet(`auth/admin-to-user/${userId}`);
      if (response?.status) {
        setDataInLocalStorage('accessToken', response?.data?.tokens?.access?.token);
        setDataInLocalStorage('refreshToken', response?.data?.tokens?.refresh?.token);

        await getUserinfo();
        this.handleGetUnreadMessages();
        if (response?.data?.organizationId?.channels?.length > 0) {
          await this.handleGetTwilioVoiceToken();
        }

        toggleInitializeNewSocket();

        history.push('/');
      } else {
        console.error('Login API error: ', response?.message);
        apiError(response?.message || SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error('Login error: ', error);
      apiErrorAction(error?.message || SOMETHING_WENT_WRONG);
    }
  };

  handleGetUnreadMessages = async () => {
    try {
      const { addNotification } = this.props;
      const response = await axiosGet('/conversations/unread-messages');
      if (response?.status) {
        response.data.forEach((notification) => {
          addNotification(notification);
        });
      }
    } catch (error) {
      console.error('error at handleNotificationClick', error);
    }
  };

  handleLogin = async (values) => {
    const { apiError, history } = this.props;
    try {
      const { toggleInitializeNewSocket } = this.props;
      const { getUserinfo } = this.context;

      const response = await axiosPost('/auth/login', {
        email: values?.email,
        password: values?.password,
      });
      if (response?.status) {
        setDataInLocalStorage('accessToken', response?.data?.tokens?.access?.token);
        setDataInLocalStorage('refreshToken', response?.data?.tokens?.refresh?.token);
        setDataInLocalStorage('accessToken', response?.data?.tokens?.access?.token);
        setDataInLocalStorage('refreshToken', response?.data?.tokens?.refresh?.token);
        this.handleGetUnreadMessages();
        if (response?.data?.organizationId?.channels?.length > 0) {
          await this.handleGetTwilioVoiceToken();
        }

        history.push('/');

        toggleInitializeNewSocket();

        await getUserinfo();
      } else {
        console.error('Login API error: ', response?.message);
        apiError(response?.message || SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error('Login error: ', error);
      apiError(error?.message || SOMETHING_WENT_WRONG);
    }
  };

  handleGetTwilioVoiceToken = async () => {
    try {
      const { userData } = this.context;
      const { setTwilioVoiceToken } = this.props;
      const organizationId = userData?.organizationId;
      const response = await axiosGet(`organization/${organizationId}/voice-access-token`);
      if (response.status) {
        setTwilioVoiceToken(response?.data);
      }
    } catch (error) {
      console.error('error at handleGetTwilioVoiceToken', error);
    }
  };

  render() {
    const { showPassword, email, password } = this.state;
    const { error } = this.props;

    return (
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Auto Campaign.AI</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={LogoIcon} alt="auto-campaign-ai-logo-icon" height="50" />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={LogoIcon} alt="auto-campaign-ai-logo-icon" height="50" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {this.props && error ? <Alert color="danger">{error}</Alert> : null}
                    <Formik
                      enableReinitialize
                      initialValues={{
                        email: this.state && email,
                        password: this.state && password,
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required('Please Enter Your Email'),
                        password: Yup.string().required('Please Enter Valid Password'),
                      })}
                      onSubmit={this.handleLogin}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              Email
                            </Label>
                            <Field
                              name="email"
                              type="text"
                              className={`form-control${errors.email && touched.email ? ' is-invalid' : ''
                              }`}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="password" className="form-label">
                              Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Field
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="true"
                                className={`form-control${errors.password && touched.password ? ' is-invalid' : ''
                                }`}
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                                onClick={() => this.setState({
                                  showPassword: !showPassword,
                                })}
                              >
                                {showPassword ? (
                                  <i className="mdi mdi-eye-outline" />
                                ) : (
                                  <i className="mdi mdi-eye-off-outline" />
                                )}
                              </button>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label className="form-check-label" htmlFor="customControlInline">
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button className="btn btn-primary btn-block" type="submit">
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center" />

                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              {' '}
                              Forgot your password?
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&apos;t have an account ?
                  <Link to="register" className="fw-medium text-primary">
                    Signup Now
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  toggleInitializeNewSocket: PropTypes.func,
  match: PropTypes.any,
  addNotification: PropTypes.func,
  setTwilioVoiceToken: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  const { initializeSocket } = state.notification;
  return { error, initializeSocket };
};

export default withRouter(
  connect(mapStateToProps, {
    apiError: apiErrorAction,
    socialLogin,
    addNotification: addNotificationAction,
    setTwilioVoiceToken: setTwilioVoiceTokenAction,
    toggleInitializeNewSocket: toggleInitializeNewSocketAction,
  })(Login),
);
