import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Button from '../../../components/Button';
import { toggleCallVisibility } from '../../../store/onboarding/actions';
import CommonDialog from '../../../components/Dialog';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
const title = 'Welcome to Call Feature';
const description = 'Please enable the call feature to connect with Twilio and use calling functionality for seamless communication.';

function CallFeatureEnable({ show, onClose }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleNavigateToCallWidget = () => {
    history.push('/communication-settings');
    dispatch(toggleCallVisibility());
  };

  const footer = (
    <ButtonWrapper>
      <Button
        label="Connect"
        className="p-button-success"
        onClick={handleNavigateToCallWidget}
        style={{
          borderRadius: 'var(--border-radius)',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      />
    </ButtonWrapper>
  );

  return (
    <CommonDialog
      title={title}
      visible={show}
      onClose={onClose}
      footer={footer}
      width="30rem"
    >
      <p>{description}</p>
    </CommonDialog>
  );
}

CallFeatureEnable.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CallFeatureEnable;
