/* eslint-disable import/prefer-default-export */
import { handleError } from '../errorHandler';
import { axiosGet, axiosPatch } from '../http.service';

export const fetchAssignedWhatsappNumbers = async () => {
  try {
    const response = await axiosGet('/whatsapp-numbers/assigned');
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to phone numbers');
  } catch (error) {
    handleError('Error fetching phone numbers:', error);
    throw error;
  }
};

export const fetchAvailableWhatsappNumbers = async (whatsappBusinessAccountId) => {
  try {
    const response = await axiosGet(`/whatsapp-numbers/available-numbers?whatsappBusinessAccountId=${whatsappBusinessAccountId}`);
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to fetch available numbers');
  } catch (error) {
    handleError('Error fetching available numbers:', error);
    throw error;
  }
};

export const updateWhatsappNumbersStatus = async (status) => {
  try {
    const response = await axiosPatch('/whatsapp-numbers/status', { status });
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to update WhatsApp numbers status');
  } catch (error) {
    handleError('Error updating WhatsApp numbers status:', error);
    throw error;
  }
};
