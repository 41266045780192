import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button';

const ChannelIconWrapper = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const ChannelName = styled.h4`
  margin-bottom: 0.5rem;
`;

const ChannelStatus = styled.p`
  color: #6c757d;
  margin-bottom: 1rem;
`;
function ChannelCard({
  icon,
  bgClass,
  name,
  isConnected,
  connecting,
  type,
  onClick,
  buttonText,
  buttonClass,
  customStyle = {},
}) {
  return (
    <div className="d-flex flex-column align-items-center">
      <ChannelIconWrapper className={bgClass}>
        {type === 'icon' ? (
          <i className={`${icon}`} style={{ fontSize: '2rem' }} />
        ) : (
          <img src={icon} alt={name} height="40" />
        )}
      </ChannelIconWrapper>
      <ChannelName>{name}</ChannelName>
      <ChannelStatus>{isConnected ? 'Connected' : 'Connect Now'}</ChannelStatus>
      <Button
        disabled={connecting}
        className={`${buttonClass} p-button-rounded`}
        onClick={onClick}
        label={buttonText}
        icon="bx bx-link"
        style={customStyle}
      />
    </div>
  );
}
// PropTypes
ChannelCard.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ]).isRequired,
  bgClass: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  connecting: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
  customStyle: PropTypes.object,
  type: PropTypes.string,
};

ChannelCard.defaultProps = {
  connecting: false,
  buttonClass: 'p-button-rounded',
  customStyle: {},
};

export default ChannelCard;
