/* eslint-disable react/prop-types */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MaterialReactTable } from 'material-react-table';
import {
  Search as SearchIcon,
  ArrowUpwardRounded as ArrowUpward,
  ArrowDownwardRounded as ArrowDownward,
  SwapVertOutlined,
  FilterAltOutlined as FilterAltOutlinedIcon,
  FilterAltOffOutlined as FilterAltOffIcon,
  DragIndicatorRounded as DragIndicatorIcon,
  PushPinOutlined,
  ClearRounded as ClearRoundedIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { WAITING_TIMING } from '../../constants/constants';

const StyledDataGridWrapper = styled.div`
  .MuiTableContainer-root {
    width: 100%;
  }

  .MuiTableCell-root {
    padding: 8px 16px;
  }

  .MuiTableBody-root .MuiTableRow-root:hover {
    background-color: #f0f0f0;
  }

  .MuiInputBase-root {
    background-color: transparent;
  }

  .search-box {
    margin-bottom: 16px;
  }

  .filter-menu .MuiFormControl-root {
    margin-top: 8px;
  }

  .MuiTableHead-root .MuiTableCell-root {
    font-weight: 600;
    background-color: #eff2f7;
  }

  .mrt-table {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }

  .mrt-thead {
    background-color: #f5f5f5;
  }

  .mrt-thead-row {
    border-bottom: 2px solid #e0e0e0;
  }

  .mrt-thead-cell {
    font-weight: 600;
    color: #333;
    padding: 12px 16px;
  }

  .mrt-tbody-row {
    &:nth-of-type(even) {
      background-color: #fafafa;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .mrt-tbody-cell {
    padding: 8px 16px;
    border-bottom: 1px solid #e0e0e0;
  }

  .mrt-table-head-cell-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mrt-column-actions {
    display: flex;
    align-items: center;
  }

  .MuiIconButton-root {
    padding: 6px;
  }

  .filter-popover {
    padding: 16px;
  }

  .filter-mode-icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .mrt-filter-text-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  .mrt-top-toolbar {
    justify-content: space-between;
    padding: 8px 16px;
    background-color: #f5f5f5;
  }
  #layout-wrapper
    > div.main-content
    > div.page-content
    > div
    > div:nth-child(2)
    > div
    > div.card
    > div
    > div:nth-child(2)
    > div.sc-StzaE.cHRHlA
    > div
    > div.MuiTableContainer-root.css-1s0fkub-MuiTableContainer-root
    > table
    > thead
    > tr
    > th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-1vrmfep-MuiTableCell-root
    > div
    > div.Mui-TableHeadCell-Content-Labels.MuiBox-root.css-68rqdf
    > span {
    display: none;
  }

  /* Style for the header actions container */
  .MuiTableCell-root.MuiTableCell-head .Mui-TableHeadCell-Content-Actions {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  /* Hide all icons in the table header by default */
  .MuiTableCell-root.MuiTableCell-head .Mui-TableHeadCell-Content-Actions button {
    display: none !important;
  }

  /* Show the three-dot menu and make it appear first */
  .MuiTableCell-root.MuiTableCell-head .Mui-TableHeadCell-Content-Actions {
    flex-direction: row-reverse;
  }

  .MuiTableCell-root.MuiTableCell-head .Mui-TableHeadCell-Content-Actions button:last-child {
    display: flex !important;
    order: -1;
  }

  /* Show move column option on hover */
  .MuiTableCell-root.MuiTableCell-head:hover .Mui-TableHeadCell-Content-Actions button:first-child {
    display: flex !important;
  }

  /* Ensure consistent button size */
  .MuiTableCell-root.MuiTableCell-head .Mui-TableHeadCell-Content-Actions button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  Hide default sort icons .MuiTableSortLabel-icon {
    display: none !important;
  }

  /* Remove content and space for sort indicators */
  .MuiTableSortLabel-root.Mui-active.MuiTableSortLabel-directionAsc::after,
  .MuiTableSortLabel-root.Mui-active.MuiTableSortLabel-directionDesc::after,
  .MuiTableSortLabel-root:not(.Mui-active)::after {
    content: none;
    display: none;
  }

  /* Remove any remaining whitespace */
  .MuiTableSortLabel-root {
    display: none !important;
    align-items: center;
  }

  /* Pull options and buttons to the right */
  .mrt-top-toolbar {
    justify-content: flex-end;
  }

  /* New styles for Material-UI icons */
  .MuiIconButton-root {
    padding: 4px;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`;

const StyledDragHandle = styled(IconButton)`
  && {
    padding: 4px;
    margin-right: 4px;
    color: #757575;
    cursor: move;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
      font-size: 18px;
    }
  }
`;

function DragHandleIcon() {
  return (
    <StyledDragHandle size="small" disableRipple>
      <DragIndicatorIcon />
    </StyledDragHandle>
  );
}

function DataGrid({
  data,
  columns,
  enableColumnResizing = true,
  enableColumnFilterModes = true,
  enableGlobalFilter = true,
  enableColumnOrdering = true,
  enableColumnPinning = true,
  enableColumnFilters = true,
  enableFilters = true,
  enableColumnActions = true,
  enableRowSelection = true,
  enableHiding = true,
  enableColumnDragging = true,
  enableEditing = true,
  enablePagination = true,
  manualPagination = true,
  enableDensityToggle = false,
  enableFullScreenToggle = false,
  enableMultiRowSelection = true,
  editingMode = 'cell',
  onColumnVisibilityChange,
  onEditingRowSave,
  onRowSelectionChange,
  selectedRows = [],
  // onColumnFiltersChange,
  state,
  totalSize,
  onPageChange,
  onPageSizeChange,
  initialPage = 0,
  initialPageSize = 10,
  onSearch,
  initialSearchQuery = '',
  onRowClick,
}) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const searchTimeoutRef = useRef(null);
  const [, setRowSelection] = useState(selectedRows);
  const [pagination, setPagination] = useState({
    pageIndex: initialPage,
    pageSize: initialPageSize,
  });

  const handleSearchChange = useCallback(
    (value) => {
      setSearchQuery(value);

      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        if (onSearch) {
          onSearch(value);
        }
      }, WAITING_TIMING);
    },
    [onSearch],
  );

  useEffect(
    () => () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    },
    [],
  );

  useEffect(() => {
    setPagination({
      pageIndex: initialPage,
      pageSize: initialPageSize,
    });
  }, [initialPage]);

  const handlePaginationChange = useCallback(
    (updaterOrValue) => {
      setPagination((old) => {
        const newPagination = typeof updaterOrValue === 'function' ? updaterOrValue(old) : updaterOrValue;

        if (onPageChange && newPagination.pageIndex !== old.pageIndex) {
          onPageChange(newPagination.pageIndex + 1); // Add 1 because MaterialReactTable uses 0-based indexing
        }

        if (onPageSizeChange && newPagination.pageSize !== old.pageSize) {
          onPageSizeChange(newPagination.pageSize);
        }

        return newPagination;
      });
    },
    [onPageChange, onPageSizeChange],
  );

  useEffect(() => {
    if (!firstLoad) {
      // Reset to first page when totalSize changes (e.g., when filter is applied)
      setPagination((old) => ({ ...old, pageIndex: 0 }));
    } else {
      setFirstLoad(false);
    }
  }, [totalSize]);

  const handleRowSelection = useCallback(
    (updaterOrValue) => {
      setRowSelection((oldSelection) => {
        let newSelection;
        if (typeof updaterOrValue === 'function') {
          newSelection = updaterOrValue(oldSelection);
        } else {
          newSelection = updaterOrValue;
        }

        if (onRowSelectionChange) {
          const selectedRowsData = Object.keys(newSelection)
            .filter((key) => newSelection[key])
            .map((key) => data[parseInt(key, 10)]);
          onRowSelectionChange(selectedRowsData);
        }

        return newSelection;
      });
    },
    [data, onRowSelectionChange],
  );

  const renderSortIcon = (column) => {
    const sortDirection = column.getIsSorted();
    if (sortDirection === 'asc') {
      return <ArrowUpward fontSize="small" />;
    }
    if (sortDirection === 'desc') {
      return <ArrowDownward fontSize="small" />;
    }
    return <SwapVertOutlined fontSize="small" />;
  };

  const getSortTooltip = (column) => {
    const sortDirection = column.getIsSorted();
    if (sortDirection === 'asc') {
      return 'Sort Descending';
    }
    if (sortDirection === 'desc') {
      return 'Sort Ascending';
    }
    return 'Sort';
  };

  const handleSortClick = (column) => {
    const sortDirection = column.getIsSorted();
    if (sortDirection === false) {
      column.toggleSorting(false);
    } else {
      column.toggleSorting(!sortDirection);
    }
  };

  const tableColumns = useMemo(
    () => columns.map((col) => ({
      ...col,
      enableSorting: col?.enableSorting !== false,
      Header: ({ column }) => (
        <div className="mrt-table-head-cell-content">
          {column.columnDef.header}
          {col?.enableSorting !== false && (
          <div className="mrt-column-actions">
            <Tooltip title={getSortTooltip(column)}>
              <IconButton onClick={() => handleSortClick(column)} size="small">
                {renderSortIcon(column)}
              </IconButton>
            </Tooltip>
          </div>
          )}
        </div>
      ),
    })),
    [columns],
  );

  return (
    <StyledDataGridWrapper>
      <MaterialReactTable
        columns={tableColumns}
        data={data}
        enableSorting
        enableColumnResizing={enableColumnResizing}
        enableColumnFilterModes={enableColumnFilterModes}
        enableGlobalFilter={enableGlobalFilter}
        enableColumnOrdering={enableColumnOrdering}
        enableColumnPinning={enableColumnPinning}
        enableColumnFilters={enableColumnFilters}
        enableFilters={enableFilters}
        enableColumnActions={enableColumnActions}
        enableRowSelection={enableRowSelection}
        enableHiding={enableHiding}
        enableColumnDragging={enableColumnDragging}
        enableEditing={enableEditing}
        enablePagination={enablePagination}
        enableDensityToggle={enableDensityToggle}
        enableFullScreenToggle={enableFullScreenToggle}
        enableMultiRowSelection={enableMultiRowSelection}
        manualPagination={manualPagination}
        editingMode={editingMode}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onEditingRowSave={onEditingRowSave}
        onRowSelectionChange={handleRowSelection}
        // onColumnFiltersChange={onColumnFiltersChange}
        rowCount={totalSize * pagination?.pageSize}
        onPaginationChange={handlePaginationChange}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100],
          showFirstButton: true,
          showLastButton: true,
          SelectProps: { native: true },
          count: totalSize * pagination?.pageSize,
          page: pagination.pageIndex,
          rowsPerPage: pagination.pageSize,
          onPageChange: (_, newPage) => handlePaginationChange({ pageIndex: newPage, pageSize: pagination.pageSize }),
          onRowsPerPageChange: (event) => handlePaginationChange({ pageIndex: 0, pageSize: Number(event.target.value) }),
        }}
        state={{
          ...state,
          pagination,
          rowSelection: selectedRows,
          globalFilter: searchQuery,

        }}
        positionToolbarAlertBanner="bottom"
        muiTableBodyRowProps={({ row }) => ({
          hover: true,
          onClick: () => onRowClick?.(row.original),
          sx: { cursor: 'pointer' },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '4px',
            border: '1px solid #e0e0e0',
          },
        }}
        muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
        onGlobalFilterChange={handleSearchChange}
        globalFilterFn="contains"
        muiSearchTextFieldProps={{
          placeholder: 'Search...',
          startAdornment: <SearchIcon />,
          variant: 'outlined',
          onChange: (event) => handleSearchChange(event.target.value),
          value: searchQuery,
        }}
        paginationDisplayMode="pages"
        positionPagination="bottom"
        rowsPerPageOptions={[10, 20, 50, 100]}
        initialState={{
          showColumnFilters: false,
          density: 'comfortable',
          pagination,
        }}
        icons={{
          DragHandleIcon,
          PinToRightIcon: PushPinOutlined,
          FilterListIcon: FilterAltOutlinedIcon,
          FilterListOffIcon: FilterAltOffIcon,
          SortIcon: SwapVertOutlined,
          ArrowUpwardIcon: ArrowUpward,
          ArrowDownwardIcon: ArrowDownward,
          PinToLeftIcon: PushPinOutlined,
          ClearAllIcon: ClearRoundedIcon,
        }}
      />
    </StyledDataGridWrapper>
  );
}

DataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
    }),
  ).isRequired,
  enableColumnResizing: PropTypes.bool,
  enableColumnFilterModes: PropTypes.bool,
  enableGlobalFilter: PropTypes.bool,
  enableColumnOrdering: PropTypes.bool,
  enableColumnPinning: PropTypes.bool,
  enableColumnFilters: PropTypes.bool,
  enableFilters: PropTypes.bool,
  enableColumnActions: PropTypes.bool,
  enableRowSelection: PropTypes.bool,
  enableHiding: PropTypes.bool,
  enableColumnDragging: PropTypes.bool,
  enableEditing: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableDensityToggle: PropTypes.bool,
  enableFullScreenToggle: PropTypes.bool,
  enableMultiRowSelection: PropTypes.bool,
  manualPagination: PropTypes.bool,
  editingMode: PropTypes.oneOf(['cell', 'row']),
  onColumnVisibilityChange: PropTypes.func,
  onEditingRowSave: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  selectedRows: PropTypes.array,
  // onColumnFiltersChange: PropTypes.func,
  state: PropTypes.object,
  totalSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  initialPageSize: PropTypes.number,
  onSearch: PropTypes.func,
  initialSearchQuery: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default DataGrid;
