import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Formik, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { toast } from 'react-toastify';
import Card from '../../../../../components/Card';
import {
  setLoader, setMetaWhatsappTemplates, setSenderNumbers, setStepOneData,
} from '../../../../../store/actions';
import WhatsAppTemplate from '../../../../../components/BulkWhatsappMessage/WhatsappTemplate';
import WhatsappBackground from '../../../../../assets/images/inbox/whatsapp-background.png';
import Select from '../../../../../components/Select';
import Row from '../../../../../components/Row';
import Col from '../../../../../components/Col';
import Label from '../../../../../components/Label';
import Button from '../../../../../components/Button';
import * as bulkWhatsappServices from '../../../../../services/api/bulkWhatsapp.service';
import { UserContext } from '../../../../../components/UserProvider/UserProvider';

// Styled-components for styling
const StyledRow = styled(Row)`
  position: relative;
  background-color: #e5ddd5;
  margin: 0.2rem;
  padding-left: 1rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${WhatsappBackground});
    background-repeat: repeat;
    background-size: auto;
    opacity: 0.05;
    z-index: 1;
  }
`;

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledButton = styled(Button)`
  background: transparent;
  color: var(--bs-gray-700);
  border: 1px solid var(--bs-gray-700);
  padding: 0.1rem 0.7rem;
  
  &:hover, &:active {
    color: var(--bs-gray-900) !important;
    border: 1px solid var(--bs-gray-900) !important;
    background: transparent !important;
  }

  & > i {
    font-size: 1.2rem;
    margin: 0;
  }
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`;

class Step1 extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const {
      senderNumberId,
      templateKey,
      selectedWhatsappNumberData,
    } = props;

    this.state = {
      initialValues: {
        senderId: senderNumberId || '',
        templateKey: templateKey || '',
        selectedWhatsappNumberData: selectedWhatsappNumberData || {},
      },
      senderChecked: false, // Track if sender ID has been checked

    };

    this.getWhatsappNumbers = this.getWhatsappNumbers.bind(this);
    this.getTemplatesFromMeta = this.getTemplatesFromMeta.bind(this);
  }

  async componentDidMount() {
    const { senderNumbers } = this.props;
    // Fetch sender numbers if not already available
    if (!senderNumbers?.length) {
      await this.getWhatsappNumbers();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      senderNumbers, transformedWhatsappTemplates,
    } = this.props;
    const { senderChecked } = this.state;

    // Check if senderNumbers or transformedWhatsappTemplates have changed
    if (
      (!senderChecked && senderNumbers !== prevProps.senderNumbers)
      || transformedWhatsappTemplates !== prevProps.transformedWhatsappTemplates
    ) {
      this.handleUrlSenderId();
    }
  }

  getWhatsappNumbers = async () => {
    const { onSetLoader } = this.props;

    try {
      onSetLoader(true);
      const response = await bulkWhatsappServices.getAllWhatsappNumbers();
      if (response?.status) {
        const senderNumbers = response?.data?.results?.map((number) => ({
          value: `${number?.id}`,
          label: `${number?.phoneNumber}`,
          id: `${number?.id}`,
          wabaId: `${number?.whatsappBusinessAccountId}`,
          numberId: `${number?.numberId}`,
          name: `${number?.name}`,
        }));

        const { onSetSenderNumbers } = this.props;
        onSetSenderNumbers(senderNumbers);
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
      onSetLoader(false);
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('error at handleGetAllSenderGroups :', error);
      onSetLoader(false);
    }
  };

  handleWhatsappNumberSelect = (senderId, setFieldValue) => {
    if (!senderId) return;

    setFieldValue('senderId', senderId?.value);
    setFieldValue('selectedWhatsappNumberData', senderId);
    const { senderNumbers } = this.props;
    const [id] = senderId?.value?.split('-');
    let wabaId = '';

    senderNumbers.forEach((number) => {
      if (wabaId) return;
      if (number?.id === id) {
        wabaId = number?.wabaId;
      }
    });
    if (!wabaId) {
      toast.error('Whatsapp Id not found');
      return;
    }
    this.getTemplatesFromMeta(wabaId, setFieldValue);
  };

  // to get templates from meta for the particular selected whatsapp number
  // wabaId : WhatsApp Business Account ID
  getTemplatesFromMeta = async (wabaId, setFieldValue) => {
    const { userData } = this.context;
    const { onSetLoader, onSetMetaWhatsappTemplates, broadCastEditData } = this.props;

    const workspaceId = userData?.activeWorkspace?.workspaceId;
    try {
      onSetLoader(true);
      const response = await bulkWhatsappServices.getTemplatesFromMeta(workspaceId, wabaId);

      if (response?.status) {
        if (broadCastEditData) {
          setFieldValue('templateKey', broadCastEditData.templateKey);
        }
        onSetMetaWhatsappTemplates(response?.data);
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
      onSetLoader(false);
    } catch (error) {
      onSetLoader(false);
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('error at handleGetTemplatesFromMeta :', error);
    }
  };

  handleUrlSenderId = async () => {
    const {
      senderNumbers, broadCastEditData,
    } = this.props;
    const { senderChecked } = this.state;
    // Prevent redundant calls
    if (senderChecked) return;
    if (broadCastEditData) {
      const selectedNumber = senderNumbers?.find((number) => number.id === broadCastEditData.senderId);

      if (selectedNumber) {
        this.handleWhatsappNumberSelect(selectedNumber, (field, value) => this.setState((prevState) => ({
          initialValues: {
            ...prevState.initialValues,
            [field]: value,
          },
        })));
        // Mark sender as checked to avoid redundant processing
        this.setState({ senderChecked: true });
      }
    }
  };

  setSenderIdValue = (senderNumbers, values) => senderNumbers?.reduce((acc, numberObj) => (numberObj?.value === values.senderId ? numberObj : acc), {})

  render() {
    const {
      senderNumbers,
      transformedWhatsappTemplates,
      selectedWhatsappNumberData,
    } = this.props;

    const { initialValues } = this.state;

    const validationSchema = Yup.object().shape({
      senderId: Yup.string().required('Please select sender id'),
      selectedWhatsappNumberData: Yup.object().required('sender number data is required'),
      templateKey: Yup.string().required('Please select template'),
    });

    const { formCompletion, onSetStepOneData } = this.props;
    return (
      <StyledCard>
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={async (values) => {
            try {
              const isValid = await validationSchema.isValid(values);
              formCompletion(isValid);
              onSetStepOneData(values);
            } catch (error) {
              console.error('Validation error: ', error);
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Sender ID</Label>
                    <Select
                      optionIcon="mdi mdi-phone fs-6"
                      value={this.setSenderIdValue(senderNumbers, values)}
                      onChange={(selectedOption) => {
                        const newSenderId = selectedOption?.value;
                        if (newSenderId !== values.senderId) {
                          this.handleWhatsappNumberSelect(selectedOption, setFieldValue);
                          setFieldValue('templateKey', '');
                        }
                      }}
                      options={senderNumbers}
                    />
                    <ErrorMessage name="senderId" component="div" className="invalid-feedback" />
                  </div>

                  {values.senderId && (
                    <div className="mb-3 mt-4">
                      <FlexBetween>
                        <StyledLabel className="form-label">Templates Preview</StyledLabel>
                        <StyledButton
                          label=""
                          icon="mdi mdi-sync"
                          onClick={() => this.getTemplatesFromMeta(selectedWhatsappNumberData?.wabaId)}
                        />
                      </FlexBetween>

                      <ErrorMessage
                        name="templateKey"
                        component="div"
                        className="invalid-feedback"
                      />
                      <StyledRow className="rounded">
                        {transformedWhatsappTemplates?.map((template) => (
                          <Col key={template?.key} xxl={3} xl={4} lg={6} md={6} sm={12}>
                            <WhatsAppTemplate
                              templateViewObj={template}
                              checked={values.templateKey === template?.key}
                              checkboxId={`selectedTemplate-${template?.key}`}
                              onTemplateSelect={(isSelected) => {
                                setFieldValue('templateKey', isSelected ? template?.key : '');
                              }}
                            />
                          </Col>
                        ))}
                      </StyledRow>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </StyledCard>

    );
  }
}

Step1.propTypes = {
  senderNumberId: PropTypes.string,
  templateKey: PropTypes.string,
  selectedWhatsappNumberData: PropTypes.object,
  broadCastEditData: PropTypes.object,
  senderNumbers: PropTypes.array,
  transformedWhatsappTemplates: PropTypes.array,
  onSetLoader: PropTypes.func,
  formCompletion: PropTypes.func,
  onSetStepOneData: PropTypes.func,
  onSetSenderNumbers: PropTypes.func,
  onSetMetaWhatsappTemplates: PropTypes.func,
};

const mapStateToProps = ({ bulkWhatsapp }) => ({
  senderNumberId: bulkWhatsapp.step1.senderNumberId,
  templateKey: bulkWhatsapp.step1.templateKey,
  selectedWhatsappNumberData: bulkWhatsapp.step1.selectedWhatsappNumberData,
  senderNumbers: bulkWhatsapp.step1.senderNumbers,
  whatsappTemplates: bulkWhatsapp.step1.whatsappTemplates,
  transformedWhatsappTemplates: bulkWhatsapp.step1.transformedWhatsappTemplates || [],
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  onSetStepOneData: (formData) => dispatch(setStepOneData(formData)),
  onSetSenderNumbers: (senderNumbers) => dispatch(setSenderNumbers(senderNumbers)),
  onSetMetaWhatsappTemplates: (templates) => dispatch(setMetaWhatsappTemplates(templates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step1));
