import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';

const StyledTextarea = styled(InputTextarea)`
  border-radius: 4px;
  border: 1px solid #ced4da;
  resize: ${(props) => (props.autoResize ? 'none' : 'auto')};
  min-height: ${(props) => `${props.rows * 24}px`};
  height: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

function Textarea({
  name,
  value,
  placeholder = '',
  onChange,
  className = '',
  disabled = false,
  invalid = false,
  valid = false,
  rows = 3,
  cols = 24,
  autoResize = false,
  onKeyPress,
}) {
  const textareaRef = useRef(null);

  // Reset height and scroll position when value is empty
  useEffect(() => {
    if (textareaRef.current && value === '') {
      const element = textareaRef.current;
      if (element) {
        element.style.height = `${rows * 24}px`;
        element.scrollTop = 0;
      }
    }
  }, [value, rows]);

  // Custom handler for key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default newline
    }
    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  // Custom change handler to adjust height
  const handleChange = (e) => {
    const element = e.target;

    // Auto-adjust height only if not empty
    if (e.target.value !== '') {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    } else {
      element.style.height = `${rows * 24}px`;
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <StyledTextarea
      inputRef={textareaRef}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      className={className}
      disabled={disabled}
      invalid={invalid}
      valid={valid}
      rows={rows}
      cols={cols}
      autoResize={autoResize}
    />
  );
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  valid: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  autoResize: PropTypes.bool,
};

export default Textarea;
