import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Button, UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { axiosGet, axiosPost } from '../../../services/http.service';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';
import { setLoader } from '../../../store/actions';
import { sliceStringWithEllipsis } from '../../../helpers/commonHelpers';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import { DATE_CONSTANTS } from '../../../constants/datetime.constant';
import {
  BROADCAST_OVERVIEW, CARD_FILTER, CARD_FILTER_TYPES, DATE_RANGE_OPTIONS, FILTER_OPTIONS, REPORTS,
} from '../../../constants/analytics/analytics.constant';
import DataGrid from '../../../components/DataTable';

function ToNumberCell({ renderedCellValue }) {
  return <PhoneNumberWithFlag phoneNumber={renderedCellValue} />;
}
function FromNumberCell({ renderedCellValue, usingCopilot }) {
  if (usingCopilot) {
    if (renderedCellValue) {
      return <span>{`co-pilot-${renderedCellValue}`}</span>;
    }
    return <span>co-pilot</span>;
  }
  return <PhoneNumberWithFlag phoneNumber={renderedCellValue} />;
}

function MessageCell({ row }) {
  const report = row?.original;
  const rowIndex = row?.id;
  const contentToDisplay = report?.whatsappMessage?.templateContent || report?.message || '';
  const tooltipId = `UncontrolledTooltip_${rowIndex}`;

  return (
    <>
      <UncontrolledTooltip placement="bottom" target={tooltipId}>
        {contentToDisplay}
      </UncontrolledTooltip>
      <span id={tooltipId}>{sliceStringWithEllipsis(30, contentToDisplay)}</span>
    </>
  );
}

function FailureReasonCell({ renderedCellValue, row }) {
  const rowIndex = row?.id;
  const contentToDisplay = renderedCellValue;
  const tooltipId = `FailureReasonUncontrolledTooltip_${rowIndex}`;

  return (
    <>
      <UncontrolledTooltip placement="bottom" target={tooltipId}>
        {contentToDisplay}
      </UncontrolledTooltip>
      <span id={tooltipId}>{sliceStringWithEllipsis(30, contentToDisplay) || '-'}</span>
    </>
  );
}

function CostCell({ renderedCellValue }) {
  return <span>{renderedCellValue}</span>;
}

function DateCell({ row }) {
  const report = row?.original;
  const formattedDate = moment(report?.createdAt).format(
    DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
  );
  const formattedTime = moment(report?.createdAt).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
  return (
    <div>
      {formattedDate}
      <br />
      {formattedTime}
    </div>
  );
}

// main component
function ViewReport({ onSetLoader }) {
  const [campaignReport, setCampaignReport] = useState([]);
  const [campaignData, setCampaignData] = useState({});
  const [campaignStatistics, setCampaignStatistics] = useState({});
  const [broadCastOverview, setBroadCastOverview] = useState({});
  const [page, setPage] = useState(PAGE);
  const [size, setSize] = useState(SIZE_PER_PAGE);
  const [totalSize, setTotalSize] = useState(TOTAL_SIZE);
  const [, setSelectedRows] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const [selectedCardFilter, setSelectedCardFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [, setWhatsappNumber] = useState(null);
  const location = useLocation();
  const { id: rawId } = useParams();
  const id = rawId?.split('?')[0]; // Remove any query parameters from the `id`
  const { channel } = location?.state || {};
  const [params, setParams] = useState({ campaignId: id });
  const [chartData, setChartData] = useState([]);
  const [dateRangeDays, setDateRangeDays] = useState(6);

  const campaignReportColumns = [
    {
      header: 'To Number',
      accessorKey: 'to',
      Cell: ToNumberCell,
    },
    {
      header: 'From Number',
      accessorKey: 'from',
      Cell: function FromNumberCellWrapper({ renderedCellValue }) {
        FromNumberCellWrapper.propTypes = {
          renderedCellValue: PropTypes.any,
        };
        return (
          <FromNumberCell
            renderedCellValue={renderedCellValue}
            usingCopilot={campaignData?.usingCopilot}
          />
        );
      },
    },
    {
      header: 'Message',
      accessorKey: 'whatsappMessage.templateContent' || 'message',
      Cell: MessageCell,
    },
    {
      header: 'Status',
      accessorKey: 'status',
    },
    {
      header: 'Failure Reason',
      accessorKey: 'failureReason',
      Cell: FailureReasonCell,
    },
    {
      header: 'Cost',
      accessorKey: 'cost',
      Cell: CostCell,
    },
    {
      header: 'Date',
      accessorKey: 'date',
      Cell: DateCell,
    },
  ];
  const handleGetWhatsappNumber = async (whatsappNumberId) => {
    try {
      const response = await axiosGet(`whatsapp-numbers/${whatsappNumberId}`);
      if (response?.status) {
        setWhatsappNumber(response?.data);
      }
    } catch (error) {
      console.error('error at handleGetWhatsappNumber:', error);
    }
  };

  const handleGetCampaignReportById = async (currentPage = page) => {
    try {
      onSetLoader(true);
      const response = await axiosGet(`campaign/report/${id}?limit=${size}&page=${currentPage}`);
      if (response?.status) {
        const isWhatsappChannel = response?.data?.campaignData?.channel === CHANNEL_TYPES.WHATSAPP;
        if (isWhatsappChannel) {
          await handleGetWhatsappNumber(response?.data?.campaignData?.senderNumberId);
        }
        setBroadCastOverview(response?.data?.broadCastOverview);
        setCampaignReport(response?.data?.campaignMessages?.messages);
        setCampaignData(response?.data?.campaignData);
        setCampaignStatistics(response?.data?.campaignStatistics);
        setTotalSize(response?.data?.campaignMessages?.totalPages);
      }
    } catch (error) {
      console.error('error at handleGetAllTemplates :', error);
    } finally {
      onSetLoader(false);
    }
  };
  const fetchAnalyticsData = async () => {
    try {
      const dateRange = {
        startDate: moment().subtract(dateRangeDays, 'days').startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf(),
      };
      const chartParams = {
        campaignId: id,
        dateRange,
      };
      const response = await axiosPost('campaign/analytics-chart', chartParams);
      if (response?.status) {
        setChartData(response?.data);
      }
    } catch (error) {
      console.error('error at fetchAnalyticsData :', error);
    }
  };
  const buildSelectCardParams = (name) => {
    const updatedParams = { campaignId: id };
    const statusMap = {
      [CARD_FILTER.SENT]: { status: 'sent' },
      [CARD_FILTER.DELIVERED]: { status: 'delivered' },
      [CARD_FILTER.READ]: { isRead: true },
      [CARD_FILTER.FAILED]: { status: 'failed' },
      [CARD_FILTER.QUEUED]: { status: 'queued' },
      [CARD_FILTER.NOT_RESPOND]: { hasReplied: false },
      [CARD_FILTER.SUCCESS_RESPOND]: { hasReplied: true },
    };

    return {
      ...updatedParams,
      ...statusMap[name],
    };
  };

  const buildFilterParams = (filterType) => {
    const filterMap = {
      [CARD_FILTER_TYPES.HAS_READ]: { isRead: true },
      [CARD_FILTER_TYPES.NOT_READ]: { isRead: false },
      [CARD_FILTER_TYPES.HAS_REPLIED]: { hasReplied: true },
      [CARD_FILTER_TYPES.NOT_REPLIED]: { hasReplied: false },
      [CARD_FILTER_TYPES.REPLIED_1H]: { repliedIn: 'REPLIED_1H' },
      [CARD_FILTER_TYPES.REPLIED_3H]: { repliedIn: 'REPLIED_3H' },
      [CARD_FILTER_TYPES.REPLIED_24H]: { repliedIn: 'REPLIED_24H' },
      [CARD_FILTER_TYPES.REPLIED_48H]: { repliedIn: 'REPLIED_48H' },
    };

    return filterMap[filterType] || {};
  };

  const handleGetCampaignReportByFilter = async (filters) => {
    try {
      const response = await axiosPost('campaign/analytics-filter', filters);
      if (response?.status) {
        setCampaignReport(response?.data);
      }
    } catch (error) {
      console.error('error at handleGetAllTemplates :', error);
    }
  };

  const handlePageClick = (selectedPage) => {
    setPage(selectedPage);
    handleGetCampaignReportById(selectedPage);
  };

  const handleLimitChange = (newPageSize) => {
    setSize(newPageSize);
    setPage(1);
    handleGetCampaignReportById(1, newPageSize);
  };

  const handleRowSelection = (selectedRowsData) => {
    setSelectedRows(selectedRowsData);
  };

  const getFilterOptions = (name) => FILTER_OPTIONS[name] || [];

  const handleCardClick = (selectCardName) => {
    const findReport = REPORTS.find((report) => report.selectCardName === selectCardName);
    const count = campaignStatistics?.[findReport?.key] || 0;
    const selectedCard = getFilterOptions(selectCardName);
    if (count === 0) {
      setSelectedCardFilter([]);
    } else {
      setSelectedCardFilter(selectedCard);
    }
    const newParams = buildSelectCardParams(selectCardName);
    setParams(newParams);
    setActiveCard(selectCardName);
    setSelectedFilter('all');
    handleGetCampaignReportByFilter(newParams);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    const filterParams = buildFilterParams(filter);
    handleGetCampaignReportByFilter({ ...params, ...filterParams });
  };
  const handleDateRangeChange = (e) => {
    const { value } = e.target;
    setDateRangeDays(value);
  };
  useEffect(() => {
    fetchAnalyticsData();
  }, [dateRangeDays]);

  useEffect(() => {
    document.title = 'campaign-report';
    handleGetCampaignReportById();
  }, []);

  const destinationPath = channel?.toLowerCase() === CHANNEL_TYPES.WHATSAPP ? '/whatsapp-broadcast' : '/text-broadcast';

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Campaign" breadcrumbItem="View Report" />
        <Row className="mt-2">
          <Col lg="12" sm="12" xs="12">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="row g-3 mb-3">
                  {BROADCAST_OVERVIEW.map((item) => (
                    <div className="flex-grow-1 col-6 col-sm-6 col-lg-2" key={item.key}>
                      <div className="d-flex">
                        <i className={`${item.iconClas} me-2`} style={{ fontSize: item.iconSize }} />
                        <div>
                          <h5 className="font-size-16">{item.title}</h5>
                          <p className="mb-0 font-size-14">{broadCastOverview?.[item.key]}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <div
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <h5 className="mb-0">Analytics Overview</h5>
              <select className="form-select" style={{ width: '11em' }} onChange={handleDateRangeChange}>
                {DATE_RANGE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <ResponsiveContainer width="100%" height={370}>
              <LineChart
                data={chartData}
                margin={{
                  top: 20,
                  right: 10,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_id" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line yAxisId="left" type="monotone" dataKey="sent" stroke="#4caf50" activeDot={{ r: 8 }} />
                <Line yAxisId="right" type="monotone" dataKey="read" stroke="#2196f3" />
                <Line yAxisId="left" type="monotone" dataKey="failed" stroke="#f44336" />
                <Line yAxisId="right" type="monotone" dataKey="replied" stroke="#ff9800" />
              </LineChart>
            </ResponsiveContainer>
          </CardBody>
        </Card>
        <Row>
          {REPORTS.map((report) => {
            const cardClass = `mini-stats-wid ${activeCard === report?.selectCardName ? 'shadow-md border border-primary' : ''}`;
            return (
              <Col md="3" key={report.id || report.key}>
                <Card
                  className={cardClass}
                  onClick={() => handleCardClick(report?.selectCardName)}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <div className="d-flex align-items-center">
                          <h4 className="mb-0 me-2">{campaignStatistics?.[report?.key] ?? '0'}</h4>
                          <h5 className="mb-0">{`(${campaignStatistics?.[report?.percentageKey] ?? '0'}%)`}</h5>
                        </div>
                      </div>
                      <div className="align-items-start">
                        <span>
                          <i className={report.iconClass} style={{ color: report.iconColor, fontSize: report.iconSize }} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    {Boolean(selectedCardFilter.length) && <h4 className="card-title mb-3">Filter : </h4>}
                    <div className="d-flex align-items-center flex-wrap">
                      {selectedCardFilter?.map((filter) => (
                        <div className="form-check form-check-inline mb-3 me-3 min-w-100" key={filter.id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={filter.id}
                            name="filter"
                            checked={selectedFilter === filter.id}
                            onChange={() => handleFilterChange(filter.id)}
                          />
                          <label style={{ marginTop: 1 }} className="form-check-label ms-2" htmlFor={filter.id}>
                            {filter.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <Link
                    className="text-success"
                    to={{
                      pathname: destinationPath,
                      state: { channel },
                    }}
                  >
                    <Button className="btn btn-secondary">Back</Button>
                  </Link>
                </div>
                <div className="mt-3">
                  <DataGrid
                    data={campaignReport}
                    columns={campaignReportColumns}
                    enableColumnResizing={false}
                    enableColumnFilterModes
                    enableEditing={false}
                    enableGlobalFilter={false}
                    onRowSelectionChange={handleRowSelection}
                    totalSize={totalSize}
                    onPageChange={handlePageClick}
                    onPageSizeChange={handleLimitChange}
                    initialPage={0}
                    initialPageSize={10}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
// Prop Types for main-component
ViewReport.propTypes = {
  onSetLoader: PropTypes.func,
};

// Prop Types for sub-components
ToNumberCell.propTypes = {
  renderedCellValue: PropTypes.any,
};
FromNumberCell.propTypes = {
  renderedCellValue: PropTypes.any,
  usingCopilot: PropTypes.bool,
};

MessageCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
FailureReasonCell.propTypes = {
  renderedCellValue: PropTypes.any,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

CostCell.propTypes = {
  renderedCellValue: PropTypes.any,
};
DateCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      createdAt: PropTypes.string,
    }),
  }),
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
