import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown, DropdownToggle, DropdownMenu, Row, Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SimpleBar from 'simplebar-react';
import {
  PAGE,
} from '../../constants/constants';

import {
  setActiveTab,
  setSelectedNotification,
  updateNotification,
} from '../../store/actions';
import { tabs } from '../../constants/tabs';
import LastMessageDisplay from '../../pages/Inbox/InboxCommonComponents/LastMessageDisplay';
import { CONVERSATION_TYPES } from '../../constants/channels/channels.constant';
import { capitalizeFirstLetter } from '../../utils/general';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      viewAll: false,
      hoverIndex: null,
      page: PAGE,
      notificationsPerPage: 5,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
      viewAll: prevState.toggle ? false : prevState.viewAll,
      page: 1, // Reset page when toggling
    }));
  }

  handleViewAll = () => {
    const { page, notificationsPerPage, notifications } = this.state;
    if (
      page
      < Math.ceil(
        notifications.length / notificationsPerPage,
      )
    ) {
      this.setState((prevState) => ({
        viewAll: true,
        page: prevState.page + 1, // Load next page
      }));
    }
  }

  handleMouseEnter = (index) => {
    this.setState({ hoverIndex: index });
  }

  handleMouseLeave = () => {
    this.setState({ hoverIndex: null });
  }

  handleNotificationClick = (data) => {
    const { onSetSelectedNotification, onSetActiveTab } = this.props;
    onSetActiveTab(data?.index);
    onSetSelectedNotification(data);
    const { notifications, onUpdateNotification } = this.props;
    const updatedNotificationArray = notifications.filter((notification) => {
      if (notification.from === data.from && notification.to === data.to) {
        return false;
      }
      return true;
    });
    onUpdateNotification(updatedNotificationArray);
  };

  formatLastMessageTime = (timestamp) => moment(timestamp).fromNow()

  render() {
    const { notifications, t } = this.props;
    const {
      viewAll, hoverIndex, page, notificationsPerPage, toggle,
    } = this.state;
    const endIndex = page * notificationsPerPage;
    const notificationsToShow = viewAll
      ? notifications.slice(0, endIndex)
      : notifications.slice(0, notificationsPerPage);
    const notificationCount = notifications?.length > 0 ? notifications?.length : '';
    return (
      <Dropdown
        isOpen={toggle}
        toggle={this.toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-message-outline" />
          <span className="badge bg-primary rounded-pill">
            {notificationCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">
                  {' '}
                  {t('Notifications')}
                  {' '}
                </h6>
              </Col>
            </Row>
          </div>
          {
            notificationsToShow.length > 0 ? (
              <SimpleBar className="noti-simplebar">
                {notificationsToShow.map((notification, index) => (
                  <Link
                    to="/inbox"
                    className="text-reset notification-item"
                    key={`${notification?.from}`}
                    onMouseEnter={() => this.handleMouseEnter(index)}
                    onMouseLeave={this.handleMouseLeave}
                  >
                    <div
                      className="d-flex p-3 align-items-center"
                      onClick={() => this.handleNotificationClick({
                        to: notification.to,
                        from: notification.from,
                        index:
                          notification.channel === CONVERSATION_TYPES.TEXT
                            ? tabs.TEXT
                            : tabs.WHATSAPP,
                        message: notification?.message,
                        timestamp: notification?.timestamp,
                      })}
                    >
                      <div className="flex-shrink-0 me-3">
                        <i
                          className={`font-size-24 ${notification?.channel === CONVERSATION_TYPES.TEXT
                            ? 'mdi mdi-message-text-outline text-primary'
                            : 'mdi mdi-whatsapp text-success'
                          }`}
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">
                          {capitalizeFirstLetter(notification?.name)
                            || notification?.from}
                        </h6>
                        <div className="font-size-15 text-muted">
                          <LastMessageDisplay lastMessage={notification?.message} lastMessageType={notification?.mediaType} />
                        </div>
                      </div>

                      {hoverIndex === index ? (
                        <i
                          className="font-size-22 mdi mdi-checkbox-marked-circle-outline text-secondary me-2"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Mark as read"
                          id="read"
                        />
                      ) : (
                        <div className="flex-shrink-0 text-end">
                          <p className="mb-0 font-size-10 text-muted">
                            {this.formatLastMessageTime(
                              notification?.timestamp,
                            )}
                          </p>
                          {notification?.totalCount > 0 && (
                            <span className="badge bg-danger rounded-pill">
                              {notification?.totalCount}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
                {
                  !viewAll && endIndex < notifications.length && (
                    <div className="text-center p-2 border-top">
                      <button
                        className="btn btn-link text-muted"
                        onClick={this.handleViewAll}
                        type="button"
                      >
                        Load more
                      </button>
                    </div>
                  )
                }
              </SimpleBar>
            ) : (
              <div className="p-3 text-center text-muted">
                No notifications yet.
              </div>
            )
          }
        </DropdownMenu>
      </Dropdown>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onSetSelectedNotification: PropTypes.func.isRequired,
  onUpdateNotification: PropTypes.func.isRequired,
  onSetActiveTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSetSelectedNotification: (data) => dispatch(setSelectedNotification(data)),
  onUpdateNotification: (data) => dispatch(updateNotification(data)),
  onSetActiveTab: (channel) => dispatch(setActiveTab(channel)),
});

const mapStateToProps = (state) => ({
  latestNotification: state.notification.latestNotification,
  notifications: state.notification.notifications,
  selectedNotification: state.notification.selectedNotification,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(NotificationDropdown));
