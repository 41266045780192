import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';

const StyledDialog = styled(Dialog)`
  .p-dialog-content {
    padding: 0 1.5rem 1.5rem;
  }
`;

function CommonDialog({
  title = 'Dialog',
  visible,
  onClose,
  footer,
  children,
  className = '',
  width = '30rem',
  ...rest
}) {
  return (
    <StyledDialog
      header={title}
      visible={visible}
      onHide={onClose}
      footer={footer}
      className={className}
      style={{ width }}
      {...rest}
    >
      {children}
    </StyledDialog>
  );
}

CommonDialog.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default CommonDialog;
