/* eslint-disable import/prefer-default-export */
import { CHATBOX_USER_STATUS } from '../constants/status.constant';
import { CHANNEL_TYPES_SETTINGS } from '../constants/channels/channels.constant';

export const getChannels = (channels) => {
  const twilioChannel = channels?.find((channel) => channel?.type === CHANNEL_TYPES_SETTINGS.TWILIO);
  const whatsappChannel = channels?.find((channel) => channel?.type === CHANNEL_TYPES_SETTINGS.WHATSAPP);

  return { twilio: twilioChannel, whatsapp: whatsappChannel };
};

export const getStatusClassName = (status) => {
  switch (status) {
    case CHATBOX_USER_STATUS.ONLINE:
      return 'mdi mdi-circle text-success align-middle me-1';
    case CHATBOX_USER_STATUS.INTERMEDIATE:
      return 'mdi mdi-circle text-warning align-middle me-1';
    case CHATBOX_USER_STATUS.OFFLINE:
    default:
      return 'mdi mdi-circle align-middle me-1';
  }
};
