export const WHATSAPP_TEMPLATES_PARAMETER_TYPES = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  VIDEO: 'VIDEO',
  LOCATION: 'LOCATION',
};

export const WHATSAPP_TEMPLATES_BUTTON_TYPES = {
  QUICK_REPLY: 'Quick Reply',
  COPY_CODE: 'Copy Code',
  URL: 'URL',
  PHONE_NUMBER: 'Phone Number',
  OTP: 'OTP',
  MPM: 'MPM',
  CATALOG: 'Catalog',
  FLOW: 'Flow',
  VOICE_CALL: 'Voice Call',
};

export const WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS = {
  QUICK_REPLY: 'QUICK_REPLY',
  COPY_CODE: 'COPY_CODE',
  URL: 'URL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  OTP: 'OTP',
  MPM: 'MPM',
  CATALOG: 'CATALOG',
  FLOW: 'FLOW',
  VOICE_CALL: 'VOICE_CALL',
};

export const TEMPLATE_COMPONENT_TYPE = {
  HEADER: 'HEADER',
  BODY: 'BODY',
  FOOTER: 'FOOTER',
  BUTTONS: 'BUTTONS',
  BUTTON: 'BUTTON',
};

export const TEMPLATE_LANGUAGES = {
  ENGLISH: 'en',
  ENGLISH_UK: 'en_UK',
  ENGLISH_US: 'en_US',
  SPANISH: 'es',
  SPANISH_SPA: 'es_ES',
  SPANISH_ARG: 'es_AR',
  SPANISH_MEX: 'es_MX',
};
export const WHATSAPP_VARIABLES = {
  name: 'NAME',
  address: 'ADDRESS',
  zip: 'ZIP',
  state: 'STATE',
  city: 'CITY',
  date: 'DATE',
};

export const TAG = {
  name: 'NAME',
  address: 'ADDRESS',
  zip: 'ZIP',
  state: 'STATE',
  city: 'CITY',
  date: 'DATE',
};

export const WHATSAPP_TEMPLATES_CATEGORY = {
  UTILITY: 'UTILITY',
  PROMOTIONAL: 'PROMOTIONAL',
  TRANSACTIONAL: 'TRANSACTIONAL',
};
export const WHATSAPP_ACTION_TYPES = {
  EDIT: 'edit',
  CREATE: 'create',
};
