/* eslint-disable camelcase */
import {
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from 'styled-components';
import Row from '../../../../../components/Row';
import Col from '../../../../../components/Col';
import Label from '../../../../../components/Label';
import Field from '../../../../../components/Field';
import VariableSelect from '../../../../../components/VariableSelect';
import Card from '../../../../../components/Card';
import VerticalScrollBar from '../../../../../components/VerticalScrollBar';
import { UserContext } from '../../../../../components/UserProvider/UserProvider';
import WhatsAppTemplate from '../../../../../components/BulkWhatsappMessage/WhatsappTemplate';
import TemplateParameters from '../../../../../components/TemplateParameters';
import { setStepTwoData, setTransformedTemplate } from '../../../../../store/actions';
import * as fileUploadServices from '../../../../../services/api/fileUpload.service';
import WhatsappBackground from '../../../../../assets/images/inbox/whatsapp-background.png';
import { removeKeysByPattern } from '../../../../../helpers/commonHelpers';
import {
  TEMPLATE_COMPONENT_TYPE, WHATSAPP_TEMPLATES_BUTTON_TYPES, WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS, WHATSAPP_TEMPLATES_CATEGORY, WHATSAPP_TEMPLATES_PARAMETER_TYPES,
} from '../../../../../constants/channels/whatsApp.constant';
import { ACCEPTED_FILE_TYPES, FILE_SIZE_ALLOWED, FILE_TYPES } from '../../../../../constants/file.constant';
import { bytesToMegabytes, capitalizeFirstLetter } from '../../../../../utils/general';

const StyledWhatsappTemplate = styled(WhatsAppTemplate)`
  max-width: none;
  margin: 1.5rem;
`;

const StyledCard = styled(Card)`
  .p-card-body {
    padding: 0;
  }
`;

const StyledVerticalScrollBar = styled(VerticalScrollBar)`
  padding: 1rem;
`;

const StyledField = styled(Field)`
  input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }
`;

const VariableLabel = styled(Label)`
  font-size: 0.9rem;
  font-weight: 400;
`;

const WhatsappTemplateCol = styled(Col)`
  background-color: #e5ddd5;
  border-radius: 0.2rem;
  height: fit-content;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${WhatsappBackground});
    background-repeat: repeat;
    background-size: auto;
    opacity: 0.05;
    z-index: 1;
  }
`;

const StyledDiv = styled.div`
`;

const StyledIcon = styled.i`
`;

const StyledB = styled.b`
`;

class Step2 extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      // uploadedFile: {},
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      buttonComponent: {},
      selectedVariable: {},
      initialValues: {
        templateName: '',
      },
      validationSchemaObj: {
        templateName: Yup.string().required('Please fill template name'),
      },
    };
  }

  componentDidMount() {
    this.initializeData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedTemplate, headerComponent, bodyComponent, buttonComponent,
    } = this.state;
    if (selectedTemplate !== prevState.selectedTemplate) {
      // Update all components when the selected template changes
      this.updateComponents();
    }

    if (headerComponent !== prevState.headerComponent) {
      this.updateHeaderComponent();
    }

    if (bodyComponent !== prevState.bodyComponent) {
      this.updateBodyComponent();
    }

    if (buttonComponent !== prevState.buttonComponent) {
      this.updateButtonComponent();
    }
  }

  initializeData = () => {
    const { templateValues, selectedWhatsappTemplate, broadCastEditData } = this.props;

    // Check for saved form values in Redux
    if (this.hasTemplateValues(templateValues)) {
      this.restoreTemplateValues(templateValues, selectedWhatsappTemplate);
    } else if (this.hasBroadCastEditData(broadCastEditData)) {
      this.loadTemplateData();
    } else {
      this.handleNoData(selectedWhatsappTemplate);
    }
  };

  // Check if template values exist
  hasTemplateValues = (templateValues) => templateValues && Object.keys(templateValues).length > 0;

  // Restore template values from Redux
  restoreTemplateValues = (templateValues, selectedWhatsappTemplate) => {
    this.setState((prevState) => ({
      selectedTemplate: selectedWhatsappTemplate || prevState.selectedTemplate,
      initialValues: {
        ...prevState.initialValues,
        ...templateValues,
      },
    }), () => {
      // Ensure template data is loaded after state is set
      this.loadTemplateData();
    });
  };

  // Check if broadCastEditData exists
  hasBroadCastEditData = (broadCastEditData) => broadCastEditData && Object.keys(broadCastEditData).length > 0;

  // Handle case where no data is available
  handleNoData = (selectedWhatsappTemplate) => {
    if (selectedWhatsappTemplate) {
      this.setState({ selectedTemplate: selectedWhatsappTemplate }); // Set selected template if available
    } else {
      this.setState({
        selectedTemplate: {}, // or any default value you want
        initialValues: {
          templateName: '', // Set default initial values if needed
        },
      });
    }
    this.loadTemplateData();
  };

  loadTemplateData = () => {
    const {
      selectedWhatsappTemplate, broadCastEditData,
    } = this.props;

    // Initialize initial values
    let initialValues = {
      templateName: '',
    };
    // Process components and populate initialValues
    if (broadCastEditData?.template?.name === selectedWhatsappTemplate?.name) {
      broadCastEditData?.template?.components?.forEach((component, index) => {
        if (component.type.toUpperCase() === TEMPLATE_COMPONENT_TYPE.HEADER) {
          component.parameters?.forEach((parameter, paramIndex) => {
            if (parameter.type === 'text') {
              const fieldName = `HEADER-${parameter.type}-${paramIndex + 1}`;
              initialValues[fieldName] = parameter.text || '';
            }
          });
        } else if (component.type.toUpperCase() === TEMPLATE_COMPONENT_TYPE.BODY) {
          component.parameters?.forEach((parameter, paramIndex) => {
            const fieldName = `BODY-text-${paramIndex + 1}`;
            initialValues[fieldName] = parameter.text || '';
          });
        } else if (component.type.toUpperCase() === TEMPLATE_COMPONENT_TYPE.BUTTON) {
          component.parameters?.forEach((parameter) => {
            const fieldName = `BUTTON-${component.sub_type.toUpperCase()}-${index}`;
            initialValues[fieldName] = parameter.coupon_code || '';
          });
        }
      });
      initialValues.templateName = broadCastEditData?.template?.name;

      // Handle additional logic for demo templates
      if (broadCastEditData?.templateViewObj && selectedWhatsappTemplate.category.toUpperCase() !== WHATSAPP_TEMPLATES_CATEGORY.UTILITY) {
        const demoTemplatePassData = broadCastEditData?.templateViewObj || {};
        demoTemplatePassData.name = demoTemplatePassData?.name || '';
        this.updateVariableDemoTemplate(initialValues, demoTemplatePassData);
      }
    } else {
      // Reset form values for new template selection
      initialValues = {
        templateName: '',
      };
    }
    this.setState((prevState) => {
      if (prevState.selectedTemplate !== selectedWhatsappTemplate) {
        return {
          selectedTemplate: selectedWhatsappTemplate || {},
          initialValues,
          headerComponent: {},
          bodyComponent: {},
          buttonComponent: {},
          selectedVariable: {},
        };
      }
      return null;
    });
  };

  updateVariableDemoTemplate = async (templateData, selectedTransformTemplate) => {
    const { onSetStepTwoData, onSetTransformedTemplate } = this.props;
    const [components, transformedTemplate] = await this.formatTemplateOnChange(templateData, selectedTransformTemplate);
    const storableTemplateData = await this.storableTemplateFormat(components, transformedTemplate, templateData);
    // Set values in Redux
    onSetStepTwoData(removeKeysByPattern(templateData, 'uploadedFile'));
    onSetTransformedTemplate({ transformedTemplate, storableTemplateData });
  }

  // API integration functions end here
  handleSetFieldValue = (fieldName, value, setFieldValue) => {
    setFieldValue(fieldName, value);
    this.setState((prevState) => ({
      selectedVariable: {
        ...prevState.selectedVariable,
        [fieldName]: value,
      },
    }));
  };

  // to get templates from meta for the particular selected whatsapp number
  // wabaId : WhatsApp Business Account ID

  handleSetSelectedVariable = (variable, fieldName, setFieldValue) => {
    if (variable) {
      this.setState(
        (prevState) => ({
          selectedVariable: {
            ...prevState.selectedVariable,
            [fieldName]: `${
              prevState.selectedVariable[fieldName]
                ? prevState.selectedVariable[fieldName]
                : ''
            }{{${variable}}}`,
          },
        }),
        () => {
          const { selectedVariable } = this.state;
          if (fieldName) {
            setFieldValue(fieldName, selectedVariable[fieldName]);
          }
        },
      );
    }
  };

  replacePlaceholders = (text, replacements) => {
    // Regular expression to match placeholders like {{1}}, {{2}}, etc.
    const placeholderRegex = /\{\{(\d+)\}\}/g;
    // Use replace method with a callback function to replace each placeholder
    const replacedText = text?.replace(placeholderRegex, (match, index) => {
      // Extract the index from the placeholder
      const placeholderIndex = parseInt(index, 10);
      // Check if the replacement value exists for the placeholder index
      if (Object.prototype.hasOwnProperty.call(replacements, `BODY-text-${placeholderIndex}`)) {
        // Return the replacement value
        return replacements[`BODY-text-${placeholderIndex}`];
      }
      // Return the original placeholder if replacement value doesn't exist
      return match;
    });

    return replacedText;
  };

  storableTemplateFormat = async (components, templateViewObj, values) => {
    const { bodyComponent } = this.state;
    const { selectedWhatsappTemplate, selectedWhatsappNumberData } = this.props;
    const { numberId } = selectedWhatsappNumberData;

    const updatedBody = this.replacePlaceholders(bodyComponent?.text, values);

    const whatsappTemplate = selectedWhatsappTemplate;

    const templateData = {};
    templateData.templateName = values?.templateName;
    templateData.templateContent = updatedBody;
    templateData.phoneNumberId = numberId;
    templateData.template = {};
    templateData.template.name = whatsappTemplate?.name;
    templateData.template.language = {};
    templateData.template.language.code = whatsappTemplate?.language;
    templateData.template.components = components;
    templateData.templateViewObj = Object.keys(templateViewObj).reduce((acc, key) => {
      if (typeof templateViewObj[key] === 'object' && Object.values(templateViewObj[key]).length) {
        return { ...acc, [key]: templateViewObj[key] };
      }
      return acc;
    }, {});
    templateData.templateViewObj.key = templateViewObj?.key;

    return templateData;
  };

  formatTemplateOnChange = async (templateData, selectedTransformTemplate, files) => {
    const components = [];
    const templateViewObj = cloneDeep(selectedTransformTemplate);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in templateData) {
      if (Object.prototype.hasOwnProperty.call(templateData, key)) {
        const [type, sub_type, index] = key?.split('-'); // Split the key into type and index
        const value = templateData[key];

        if (
          (type === TEMPLATE_COMPONENT_TYPE.HEADER
            && sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.LOCATION)
          || type === TEMPLATE_COMPONENT_TYPE.BODY
        ) {
          // Determine the type of component
          let uploadedFilelink = '';
          if (sub_type !== WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase()
          && files?.length
          ) {
            // eslint-disable-next-line no-await-in-loop
            const response = await fileUploadServices.uploadSingleFile(files?.[0]);
            uploadedFilelink = response?.data?.destination;
          }

          const componentType = type.toLowerCase();

          let existingComponentIndex = -1;
          // Check if a component of the same type already exists
          for (let i = 0; i < components.length; i += 1) {
            if (components[i] && components[i].type === componentType) {
              existingComponentIndex = i;
              break;
            }
          }

          const parameter = {};
          if (!templateViewObj[type.toLowerCase()]) {
            templateViewObj[type.toLowerCase()] = {
              type: WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT.toLowerCase(),
              text: '',
              url: '',
            };
          }
          if (!uploadedFilelink) {
            return [components, templateViewObj];
          }
          if (uploadedFilelink) {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = { link: uploadedFilelink };

            templateViewObj[type.toLowerCase()].type = sub_type.toLowerCase();
            templateViewObj[type.toLowerCase()].url = uploadedFilelink;
          } else {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = templateData[key];

            // If index is 1, it means `header` of `body` come first time, hence replace `text` with `actualText`
            if (index === '1') {
              templateViewObj[type.toLowerCase()].text = templateViewObj[type.toLowerCase()].actualText;
            }

            const text = templateViewObj[type.toLowerCase()].text || '';
            const replaceableValue = value || `{{${index}}}`;

            templateViewObj[type.toLowerCase()].text = text.replace(`{{${index}}}`, replaceableValue);
          }

          if (existingComponentIndex !== -1) {
            // If a component of the same type already exists, push the parameters to it
            components[existingComponentIndex].parameters.push(parameter);
          } else {
            // If no component of the same type exists, create a new one
            components.push({
              type: componentType,
              parameters: [parameter],
            });
          }
        } else if (type === TEMPLATE_COMPONENT_TYPE.BUTTON) {
          const { COPY_CODE, URL } = WHATSAPP_TEMPLATES_BUTTON_TYPES_KEYS;
          const button = {
            type: type.toLowerCase(),
            sub_type: sub_type.toLowerCase(),
            index,
            parameters: [],
          };

          if (sub_type === COPY_CODE) {
            button.parameters.push({
              type: 'coupon_code',
              coupon_code: templateData[key],
            });
          } else {
            button.parameters.push({
              type: sub_type === 'URL' ? 'text' : sub_type.toLowerCase(),
              text: templateData[key],
            });
          }

          const buttons = templateViewObj?.buttons || [];

          templateViewObj.buttons = buttons?.map((buttonObj) => {
            switch (buttonObj?.type) {
              case COPY_CODE.toLowerCase(): {
                return { ...buttonObj, code: value };
              }
              case URL.toLowerCase(): {
                return { ...buttonObj, url: buttonObj?.url?.replace(`{{${parseInt(index, 10) + 1}}}`, value) };
              }
              default: {
                return buttonObj;
              }
            }
          });
          components.push(button);
        }
      }
    }

    return [components, templateViewObj];
  };

  updateComponents = () => {
    const { selectedTemplate } = this.state;
    const componentTypes = {
      [TEMPLATE_COMPONENT_TYPE.HEADER]: 'headerComponent',
      [TEMPLATE_COMPONENT_TYPE.BODY]: 'bodyComponent',
      [TEMPLATE_COMPONENT_TYPE.FOOTER]: 'footerComponent',
      [TEMPLATE_COMPONENT_TYPE.BUTTONS]: 'buttonComponent',
    };

    selectedTemplate?.components?.forEach((component) => {
      const componentType = component.type;
      const stateVariable = componentTypes[componentType];
      if (stateVariable) {
        this.setState({
          [stateVariable]: component,
        });
      }
    });
  };

  updateHeaderComponent = () => {
    const { headerComponent } = this.state;
    const { DOCUMENT, VIDEO, IMAGE } = WHATSAPP_TEMPLATES_PARAMETER_TYPES;

    if (
      headerComponent.format === WHATSAPP_TEMPLATES_PARAMETER_TYPES.TEXT
      && Array.isArray(headerComponent.example?.header_text)
    ) {
      this.setState((prevState) => {
        const initialValues = { ...prevState.initialValues };
        const validationSchemaObj = { ...prevState.validationSchemaObj };
        const sub_type = headerComponent?.format?.toLowerCase();
        headerComponent.example.header_text.forEach((text, index) => {
          const fieldName = `${headerComponent.type}-${sub_type}-${index + 1}`;
          initialValues[fieldName] = initialValues[fieldName] || '';
          validationSchemaObj[fieldName] = Yup.string().required(
            `Please enter the value for {{${index + 1}}} for header`,
          );
        });

        return {
          initialValues,
          validationSchemaObj,
        };
      });
    } else if (
      headerComponent?.format === IMAGE
      || headerComponent?.format === DOCUMENT
      || headerComponent?.format === VIDEO
    ) {
      const sub_type = headerComponent?.format?.toLowerCase();
      const fieldName = `${headerComponent.type}-${sub_type}-uploadedFile`;

      this.setState((prevState) => ({
        initialValues: {
          ...prevState.initialValues,
          [fieldName]: prevState?.initialValues[fieldName] || '',
        },
        validationSchemaObj: {
          ...prevState.validationSchemaObj,
          [fieldName]: Yup.mixed().required('File is required'),
        },
      }));
    }
  };

  updateBodyComponent = () => {
    const { bodyComponent } = this.state;

    if (bodyComponent?.example?.body_text) {
      const bodyTexts = bodyComponent.example.body_text.flat();

      this.setState((prevState) => {
        const initialValues = { ...prevState.initialValues };
        const validationSchemaObj = { ...prevState.validationSchemaObj };

        bodyTexts.forEach((text, index) => {
          const fieldName = `${bodyComponent.type}-text-${index + 1}`;
          initialValues[fieldName] = initialValues[fieldName] || '';
          validationSchemaObj[fieldName] = Yup.string().required(
            `Please enter the value for {{${index + 1}}} for body`,
          );
        });

        return {
          initialValues,
          validationSchemaObj,
        };
      });
    }
  };

  updateButtonComponent = () => {
    const { buttonComponent } = this.state;

    if (Array.isArray(buttonComponent.buttons)) {
      const buttonsWithDynamicValue = buttonComponent.buttons.filter(
        (button, index) => {
          if (button.example) {
            return { ...button, index }; // Create a new object with the index
          }
          return null; // Return null for non-matching buttons
        },
      );
      if (buttonsWithDynamicValue.length) {
        this.setState((prevState) => {
          const initialValues = { ...prevState.initialValues };
          const validationSchemaObj = { ...prevState.validationSchemaObj };

          buttonsWithDynamicValue.forEach((button) => {
            const fieldName = `BUTTON-${button.type}-${button.index}`;
            initialValues[fieldName] = initialValues[fieldName] || '';
            validationSchemaObj[fieldName] = Yup.string().required(
              `Please enter the value for ${
                WHATSAPP_TEMPLATES_PARAMETER_TYPES[button.type]
              }`,
            );
          });

          return {
            initialValues,
            validationSchemaObj,
          };
        });
      }
    }
  };

  handleFileUpload = async (event, setFieldValue, fieldName, fileTypeParam) => {
    const fileSize = event.target.files?.[0]?.size;
    const sizeInMb = bytesToMegabytes(fileSize);
    const sizeAllowed = FILE_SIZE_ALLOWED?.[fileTypeParam.toUpperCase()];

    if (sizeInMb < sizeAllowed) {
      setFieldValue(fieldName, event.target.value);
    } else {
      setFieldValue(fieldName, '');
      toast.error(
        `${capitalizeFirstLetter(
          fileTypeParam,
        )} uploaded must be under ${sizeAllowed} MB in size`,
      );
    }
  };

  renderHeader = (component, values, errors, touched, setFieldValue) => {
    const formattedType = component.type.charAt(0).toUpperCase()
      + component.type.slice(1).toLowerCase();

    const {
      TEXT, DOCUMENT, VIDEO, IMAGE,
    } = WHATSAPP_TEMPLATES_PARAMETER_TYPES;
    if (
      component.format === TEXT
      && Array.isArray(component.example?.header_text)
    ) {
      const sub_type = component?.format?.toLowerCase();
      const getAcceptedFileTypes = (subType) => ACCEPTED_FILE_TYPES[subType];
      return (
        <TemplateParameters
          title={formattedType}
          subTitle={component.example?.header_text?.length ? 'Parameters' : ''}
          childrenClassName="pt-3"
          subTitleClassName="p-3"
        >
          {component.example?.header_text?.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledDiv className="mb-3" key={index}>
              <StyledDiv className="d-flex justify-content-between">
                <VariableLabel className="form-label mt-2">
                  {`{{${
                    index + 1
                  }}}`}
                </VariableLabel>
                <VariableSelect
                  setSelectedVariable={(value) => {
                    this.handleSetSelectedVariable(
                      value,
                      `${component?.type}-${sub_type}-${index + 1}`,
                      setFieldValue,
                    );
                  }}
                />
              </StyledDiv>

              <StyledField
                name={`${component?.type}-${sub_type}-${index + 1}`}
                innerRef={this.inputRef}
                type="text"
                placeholder={text}
                value={
                    values?.[`${component?.type}-${sub_type}-${index + 1}`]
                  }
                onChange={(event) => this.handleSetFieldValue(
                  `${component?.type}-${sub_type}-${index + 1}`,
                  event.target.value,
                  setFieldValue,
                )}
                fieldClassName={
                    `form-control${
                      errors[
                        `${component?.type}-${sub_type}-${index + 1}`
                      ]
                    && touched[`${component?.type}-${sub_type}-${index + 1}`]
                        ? ' is-invalid'
                        : ''}`
                  }
                accept={getAcceptedFileTypes(sub_type)}
              />
            </StyledDiv>
          ))}
        </TemplateParameters>
      );
    } if (
      component?.format === IMAGE
      || component?.format === DOCUMENT
      || component?.format === VIDEO
    ) {
      const sub_type = component?.format?.toLowerCase();
      const sampleFile = component?.example?.header_handle[0];

      let accept;

      switch (sub_type) {
        case FILE_TYPES.DOCUMENT:
          accept = 'application/pdf';
          break;
        case FILE_TYPES.IMAGE:
          accept = ['.jpeg', '.png', '.jpg'];
          break;
        case FILE_TYPES.VIDEO:
          accept = ['.mp4'];
          break;
        default:
          accept = null; // Handle the default case
      }
      return (
        <TemplateParameters
          title={formattedType}
          subTitle={`Upload ${sub_type}`}
          sampleFile={sampleFile}
          childrenClassName="p-3"
          subTitleClassName="p-3"
        >
          <StyledField
            type="file"
            id="file"
            accept={accept}
            name={`${component?.type}-${sub_type}-uploadedFile`}
            onChange={(event) => this.handleFileUpload(
              event,
              setFieldValue,
              `${component?.type}-${sub_type}-uploadedFile`,
              sub_type,
            )}
            fieldClassName={
                  `form-control${
                    errors[`${component?.type}-${sub_type}-uploadedFile`]
                  && touched[`${component?.type}-${sub_type}-uploadedFile`]
                      ? ' is-invalid'
                      : ''}`
                }
          />
        </TemplateParameters>
      );
    }
    return null;
  };

  renderBody = (component, values, errors, touched, setFieldValue) => {
    if (Array.isArray(component.example?.body_text)) {
      const formattedType = component.type.charAt(0).toUpperCase()
        + component.type.slice(1).toLowerCase();
      return (
        <TemplateParameters
          title={formattedType}
          subTitle={component.example?.body_text?.length ? 'Parameters' : ''}
          childrenClassName="pt-3"
          subTitleClassName="p-3"
        >
          {component.example.body_text.map((texts) => texts.map((text, index2) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledDiv className="mb-3" key={index2}>
              <StyledDiv className="d-flex justify-content-between">
                <VariableLabel className="form-label text-center mt-2">
                  {`{{${
                    index2 + 1
                  }}}`}
                </VariableLabel>
                <VariableSelect
                  setSelectedVariable={(value) => this.handleSetSelectedVariable(
                    value,
                    `${component?.type}-text-${index2 + 1}`,
                    setFieldValue,
                  )}
                />
              </StyledDiv>
              <StyledField
                name={`${component?.type}-text-${index2 + 1}`}
                type="text"
                placeholder={text}
                innerRef={this.inputRef}
                value={values?.[`${component?.type}-text-${index2 + 1}`]}
                onChange={(event) => this.handleSetFieldValue(
                  `${component?.type}-text-${index2 + 1}`,
                  event.target.value,
                  setFieldValue,
                )}
                fieldClassName={
                    `form-control${
                      errors[`${component?.type}-text-${index2 + 1}`]
                    && touched[`${component?.type}-text-${index2 + 1}`]
                        ? ' is-invalid'
                        : ''}`
                  }
              />
            </StyledDiv>
          )))}
        </TemplateParameters>
      );
    }
    return null;
  };

  renderButtons = (component, errors, touched) => {
    if (Array.isArray(component.buttons)) {
      const buttonsWithDynamicValue = component.buttons.filter(
        (button, index) => {
          if (button.example) {
            // eslint-disable-next-line no-param-reassign
            button.index = index;
            return true;
          }
          return false;
        },
      );

      if (buttonsWithDynamicValue.length) {
        const formattedType = component.type.charAt(0).toUpperCase()
          + component.type.slice(1).toLowerCase();
        return (
          <TemplateParameters
            title={formattedType}
            childrenClassName="p-0 border-0"
          >
            {buttonsWithDynamicValue.length
              ? buttonsWithDynamicValue?.map((button, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledDiv className="mb-3" key={`${button.type}-${index}`}>
                  <VariableLabel className="form-label mt-2">
                    {WHATSAPP_TEMPLATES_BUTTON_TYPES[button.type]}
                  </VariableLabel>
                  <StyledField
                    name={`BUTTON-${button.type}-${button.index}`}
                    type="text"
                    placeholder={button.example}
                    fieldClassName={
                        `form-control${
                          errors[`BUTTON-${button.type}-${button.index}`]
                        && touched[`BUTTON-${button.type}-${button.index}`]
                            ? ' is-invalid'
                            : ''}`
                      }
                  />
                </StyledDiv>
              ))
              : ''}
          </TemplateParameters>
        );
      }
      return null;
    }
    return null;
  };

  render() {
    const {
      selectedTemplate,
      initialValues,
      validationSchemaObj,
    } = this.state;
    const {
      formCompletion,
      onSetStepTwoData,
      selectedTransformTemplate,
      onSetTransformedTemplate,
    } = this.props;

    const validationSchema = Yup.object().shape(validationSchemaObj);
    return (
      <Row className="mb-3">
        <Col xxl={9} xl={8} lg={8} md={8} sm={12}>
          <StyledCard>
            <StyledVerticalScrollBar>
              <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                validate={async (values) => {
                  try {
                    const isValid = await validationSchema.isValid(values);
                    formCompletion(isValid);
                  } catch (error) {
                    console.error('error: ', error);
                  }
                }}
              >
                {({
                  errors, touched, values, setFieldValue,
                }) => (
                  <Form
                    onChange={async (event) => {
                      // To pick file name of the uploaded file & when single value it give same value
                      const value = event.target.value?.split('\\')?.pop() || '';

                      const templateData = { ...values, [event.target.name]: value };
                      const [components, transformedTemplate] = await this.formatTemplateOnChange(templateData, selectedTransformTemplate, event.target.files);

                      const storableTemplateData = await this.storableTemplateFormat(components, transformedTemplate, templateData);
                      // Set values in Redux
                      onSetStepTwoData(removeKeysByPattern(templateData, 'uploadedFile'));
                      onSetTransformedTemplate({ transformedTemplate, storableTemplateData });
                    }}
                  >
                    <Row>
                      <Col className="col-12">
                        {selectedTemplate?.components ? (
                          <StyledDiv>
                            <StyledDiv
                              className="alert alert-warning d-flex align-items-center"
                              role="alert"
                            >
                              <StyledIcon className="mdi mdi-alert font-size-24 me-3" />
                              <StyledDiv>
                                Please keep in mind that your template should
                                closely align with the example or sample
                                text/document provided. Deviating significantly
                                may result in rejection by the meta.
                              </StyledDiv>
                            </StyledDiv>
                            <StyledB className="lh-lg">
                              {selectedTemplate?.name.toUpperCase()}
                            </StyledB>
                            <StyledDiv className="mb-3 mt-2">
                              <Label className="form-label">Template Alias</Label>
                              <StyledField
                                name="templateName"
                                type="text"
                                placeholder="Enter template name here..."
                                fieldClassName={
                                    `form-control${
                                      errors.templateName && touched.templateName
                                        ? ' is-invalid'
                                        : ''}`
                                  }
                              />
                            </StyledDiv>
                            {selectedTemplate?.components?.map(
                              (component, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <StyledDiv key={index}>
                                  {component?.type === TEMPLATE_COMPONENT_TYPE.HEADER
                                      && this.renderHeader(
                                        component,
                                        values,
                                        errors,
                                        touched,
                                        setFieldValue,
                                      )}
                                  {component?.type === TEMPLATE_COMPONENT_TYPE.BODY
                                      && this.renderBody(
                                        component,
                                        values,
                                        errors,
                                        touched,
                                        setFieldValue,
                                      )}
                                  {component?.type === TEMPLATE_COMPONENT_TYPE.BUTTONS
                                      && this.renderButtons(
                                        component,
                                        errors,
                                        touched,
                                      )}
                                </StyledDiv>
                              ),
                            )}
                          </StyledDiv>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </StyledVerticalScrollBar>
          </StyledCard>
        </Col>
        <WhatsappTemplateCol xxl={3} xl={4} lg={4} md={4} sm={12}>
          <StyledWhatsappTemplate
            templateViewObj={selectedTransformTemplate}
            onlyPreview
          />
        </WhatsappTemplateCol>
      </Row>
    );
  }
}

Step2.propTypes = {
  // history: PropTypes.object,
  // isOnboarding: PropTypes.bool,
  // setOnboardingData: PropTypes.func
  selectedWhatsappTemplate: PropTypes.object,
  selectedTransformTemplate: PropTypes.object,
  selectedWhatsappNumberData: PropTypes.object,
  broadCastEditData: PropTypes.object,
  formCompletion: PropTypes.func,
  onSetStepTwoData: PropTypes.func,
  onSetTransformedTemplate: PropTypes.func,
  templateValues: PropTypes.object, // Add prop validation for templateValues

};

const mapStateToProps = ({ bulkWhatsapp }) => ({
  // isOnboarding: onboarding.isOnboarding,
  selectedWhatsappTemplate: bulkWhatsapp.step1.selectedWhatsappTemplate,
  selectedTransformTemplate: bulkWhatsapp.step1.selectedTransformTemplate,
  selectedWhatsappNumberData: bulkWhatsapp.step1.selectedWhatsappNumberData,
  templateValues: bulkWhatsapp.step2.templateValues,
});

const mapDispatchToProps = (dispatch) => ({
  onSetStepTwoData: (formData) => dispatch(setStepTwoData(formData)),
  onSetTransformedTemplate: (transformedTemplate) => dispatch(setTransformedTemplate(transformedTemplate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Step2));
