import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isNumber } from 'lodash';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CHANNEL_TYPES } from '../../../../../constants/channels/channels.constant';
import { handleOnboardingStepCompletion } from '../../../../../utils/onboarding';
import { UserContext } from '../../../../../components/UserProvider/UserProvider';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import HField from '../../../../../components/HField';
import LabelValue from '../../../../../components/LabelValue';
import Select from '../../../../../components/Select';
import DateTime from '../../../../../components/DateTime';
import Row from '../../../../../components/Row';
import Col from '../../../../../components/Col';
import IconText from '../../../../../components/IconText';
import CustomErrorMessage from '../../../../../components/CustomErrorMessage';
import {
  setLoader,
  setBMessageStepThreeData,
  resetBMessageForm,
  setOnboardingData,
} from '../../../../../store/actions';
import * as bulkSMSServices from '../../../../../services/api/bulkSms.service';
import * as contactGroupServices from '../../../../../services/api/contactGroups.service';
import {
  SCHEDULE_TYPES,
  SCHEDULE_TYPES_ARRAY,
  SCHEDULED_IMMEDIATELY_OBJECT,
  SCHEDULED_LATER_OBJECT,
} from '../../../../../constants/inbox/message.constant';
import { DEFAULT_TIMEZONE, TIME_ZONE_OPTIONS } from '../../../../../constants/datetime.constant';
import { COPILOT_OPTION, NUMBER_TYPES } from '../../../../../constants/channels/text.constant';
import { STEPS_NAME } from '../../../../../constants/onboarding.constant';
import { showToast } from '../../../../../services/toastService';
import { toastType } from '../../../../../constants/toast';

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const StyledRow = styled(Row)`
  width: 103%;
`;

const StyledIconText = styled(IconText)`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;

  i {
    font-size: 1.2rem;
    margin-bottom: 0;
    margin-right: 0.2rem;
  }
`;

const FormCard = styled(Card)`
  border: 1px solid var(--bs-gray-500);

  .p-card-body {
    padding-bottom: 0.5rem;
  }
`;

const StyledDiv = styled.div``;

const StyledSelect = styled(Select)`
  padding: 0;
`;

const StyledButton = styled(Button)`
  padding: 0.3rem 1rem 0.4rem;
  border-radius: 0.3rem;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Step3({ broadCastEditData }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userData } = useContext(UserContext);

  // Redux state
  const {
    selectedSenderGroup,
    usingCopilot,
    selectedTemplate,
    selectedContactGroup,
    broadcastData,
    isOnboarding,
  } = useSelector((state) => ({
    selectedSenderGroup: state.bulkMessage.step1.selectedSenderGroup,
    usingCopilot: state.bulkMessage.step1.usingCopilot,
    selectedTemplate: state.bulkMessage.step1.selectedTemplate,
    selectedContactGroup: state.bulkMessage.step2.selectedContactGroup,
    broadcastData: state.bulkMessage.step3.broadcastData,
    isOnboarding: state.onboarding.isOnboarding,
  }));

  // Local state
  const [initialValues, setInitialValues] = useState({
    broadcastName: '',
    scheduleType: SCHEDULE_TYPES.SCHEDULED_LATER,
    dateTime: '',
    timezone: DEFAULT_TIMEZONE.value,
    contactCount: 0,
  });
  const [contactCount, setContactCount] = useState(0);

  const getContactsCount = async (contactGroupId) => {
    try {
      dispatch(setLoader(true));
      const response = await contactGroupServices.getContactCount(contactGroupId);
      if (response?.status) {
        setContactCount(response?.data?.contactCount ?? 0);
      } else {
        showToast({
          content: response?.message || 'Oops! Something went wrong',
          type: toastType.error,
        });
      }
      dispatch(setLoader(false));
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! Something went wrong',
        type: toastType.error,
      });
      console.error('error at handleGetAllContactGroups :', error);
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    setInitialValues({
      broadcastName: broadCastEditData?.broadcastName || '',
      scheduleType: SCHEDULE_TYPES.SCHEDULED_LATER,
      dateTime: broadCastEditData?.broadcastDateTime || '',
      timezone: broadCastEditData?.broadcastTimeZone || '',
      ...broadcastData,
    });
    setContactCount(selectedContactGroup?.contactCount);

    if (!isNumber(selectedContactGroup?.contactCount)) {
      getContactsCount(selectedContactGroup?.id);
    }
  }, []);

  const handleOnSubmit = async (values) => {
    try {
      dispatch(setLoader(true));
      const {
        scheduleType, broadcastName, dateTime, timezone,
      } = values;

      // This key is introduced to ensure that the server's current time is used, rather than the user's local machine time, when sending immediately.
      const sendImmediate = scheduleType === SCHEDULE_TYPES.SEND_IMMEDIATELY;

      const campaignData = {
        name: broadcastName,
        senderIdType: NUMBER_TYPES.NA,
        senderGroupId: selectedSenderGroup?.id,
        usingCopilot,
        contactGroupId: selectedContactGroup?.id,
        templateIds: [selectedTemplate?.id],
        dateTime,
        timezone,
        sendImmediate,
      };

      const response = await bulkSMSServices.createBulkCampaign(campaignData);
      if (response?.status) {
        showToast({
          content: response?.message || 'SMS Campaign created successfully!',
          type: toastType.success,
        });
        // Reset all form values from redux
        dispatch(resetBMessageForm());
        if (isOnboarding) {
          const organizationId = userData?.organizationId;
          await handleOnboardingStepCompletion({
            stepName: STEPS_NAME.BROADCAST_MESSAGE,
            organizationId,
            history,
            setOnboardingData: (data) => dispatch(setOnboardingData(data)),
          });
        }
        history.push({
          pathname: '/text-broadcast',
          state: { channel: CHANNEL_TYPES.TEXT },
        });
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! something went wrong',
        type: toastType.error,
      });
    } finally {
      dispatch(setLoader(false));
    }
  };

  const setFormData = (formData) => {
    // Set values in Redux
    dispatch(setBMessageStepThreeData(formData));
  };

  const validationSchema = Yup.object().shape({
    broadcastName: Yup.string().required('Broadcast Name is required'),
    scheduleType: Yup.string().required('Send Broadcast is required'),
    dateTime: Yup.string().when('scheduleType', {
      is: SCHEDULE_TYPES.SCHEDULED_LATER,
      then: Yup.string().required('Date & Time is required'),
      otherwise: Yup.string(),
    }),
    timezone: Yup.string().when('scheduleType', {
      is: SCHEDULE_TYPES.SCHEDULED_LATER,
      then: Yup.string().required('Please select a timezone'),
      otherwise: Yup.string(),
    }),
  });

  const handleFormChange = async (event, values) => {
    const { value } = event.target;
    const broadcastDataObj = { ...values, [event.target.name]: value };
    setFormData(broadcastDataObj);
  };

  const handleScheduleTypeChange = (setFieldValue, values, scheduleType) => {
    setFieldValue('scheduleType', scheduleType?.value);
    setFormData({ ...values, scheduleType: scheduleType?.value });
  };

  const handleTimezoneChange = (setFieldValue, values, selectedValue) => {
    setFieldValue('timezone', selectedValue?.value);
    setFormData({ ...values, timezone: selectedValue?.value });
  };

  const handleDateTimeChange = (setFieldValue, values, dateTime) => {
    const formattedDateTime = new Date(dateTime).toISOString();
    setFieldValue('dateTime', formattedDateTime);
    setFormData({ ...values, dateTime: formattedDateTime });
  };

  return (
    <StyledCard>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          errors, touched, values, setFieldValue, setFieldError,
        }) => (
          <Form onChange={(event) => handleFormChange(event, values)}>
            <StyledDiv>
              <StyledIconText icon="mdi mdi-bullhorn" text="Broadcast details" />
              <FormCard>
                <HField
                  label="Broadcast name"
                  placeholder="Enter broadcast name"
                  type="text"
                  fieldClassName={`form-control ${
                    errors.broadcastName && touched.broadcastName ? 'is-invalid' : ''
                  }`}
                  name="broadcastName"
                  required
                  formikField
                />
                <HField
                  label="Send broadcast"
                  name="sendBroadcast"
                  fieldClassName={`form-control ${
                    errors.sendBroadcast && touched.sendBroadcast ? 'is-invalid' : ''
                  }`}
                >
                  <StyledRow>
                    <Col lg={4}>
                      <StyledSelect
                        value={SCHEDULE_TYPES_ARRAY.reduce(
                          (acc, scheduleType) => (scheduleType.value === values.scheduleType ? scheduleType : acc),
                          {},
                        )}
                        onChange={(scheduleType) => {
                          handleScheduleTypeChange(setFieldValue, values, scheduleType);
                        }}
                        options={SCHEDULE_TYPES_ARRAY}
                        optionIcon="mdi mdi-phone fs-6"
                      />
                    </Col>
                    {values.scheduleType === SCHEDULE_TYPES.SCHEDULED_LATER ? (
                      <>
                        <Col lg={4}>
                          <DateTime
                            id="myDateTimePicker"
                            onDateTimeChange={(dateTime) => {
                              handleDateTimeChange(setFieldValue, values, dateTime);
                            }}
                            minDate={new Date(new Date().setHours(0, 0, 0))}
                            initialValue={values.dateTime ? new Date(values.dateTime) : null}
                            onError={(errorMessage) => setFieldError('dateTime', errorMessage)}
                            hideOnDateTimeSelect
                            showButtonBar
                          />
                          <CustomErrorMessage
                            message={errors.dateTime}
                            showError={!!errors.dateTime}
                          />
                        </Col>
                        <Col lg={4}>
                          <StyledSelect
                            value={TIME_ZONE_OPTIONS.reduce(
                              (acc, timezoneOption) => (timezoneOption.value === values.timezone ? timezoneOption : acc),
                              {},
                            )}
                            onChange={(selectedValue) => {
                              handleTimezoneChange(setFieldValue, values, selectedValue);
                            }}
                            options={TIME_ZONE_OPTIONS}
                            placeholder={<div>Select timezone</div>}
                            className={`${errors.timezone && touched.timezone ? 'is-invalid' : ''}`}
                          />
                          <CustomErrorMessage
                            message={errors.timezone}
                            showError={!!errors.timezone}
                          />
                        </Col>
                      </>
                    ) : null}
                  </StyledRow>
                </HField>
              </FormCard>
            </StyledDiv>
            <StyledDiv>
              <StyledIconText icon="bx bx-group" text="Target Audience" />
              <FormCard>
                <LabelValue
                  label="Number of Audiences"
                  value={contactCount ?? 0}
                  icon="bx bx-group"
                />
                <LabelValue label="Segment" value={selectedContactGroup?.name} />
              </FormCard>
            </StyledDiv>
            <StyledDiv>
              <StyledIconText icon="mdi mdi-cellphone-information" text="Sender Info" />
              <FormCard>
                <LabelValue
                  label="Sender Id"
                  value={usingCopilot ? COPILOT_OPTION.label : selectedSenderGroup?.name}
                />
                <LabelValue label="Template Name" value={selectedTemplate?.name} />
              </FormCard>
            </StyledDiv>
            <FlexCenter>
              <StyledButton
                type="submit"
                label={
                  values.scheduleType === SCHEDULE_TYPES.SCHEDULED_LATER
                    ? SCHEDULED_LATER_OBJECT.text
                    : SCHEDULED_IMMEDIATELY_OBJECT.text
                }
                icon={
                  values.scheduleType === SCHEDULE_TYPES.SCHEDULED_LATER
                    ? SCHEDULED_LATER_OBJECT.icon
                    : SCHEDULED_IMMEDIATELY_OBJECT.icon
                }
              />
            </FlexCenter>
          </Form>
        )}
      </Formik>
    </StyledCard>
  );
}
Step3.propTypes = {
  broadCastEditData: PropTypes.object,

};

export default Step3;
