import {
  SHOW_TWILIO_CREDENTIALS_MODAL, SHOW_WHATSAPP_CREDENTIALS_MODAL, SHOW_OPENAI_CREDENTIALS_MODAL,
} from './actionTypes';

const INIT_STATE = {
  showTwilioCredentialsModal: false,
  showWhatsappCredentialsModal: false,
  showOpenAiCredentialsModal: false,
};

const channels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_TWILIO_CREDENTIALS_MODAL:
      return {
        ...state,
        showTwilioCredentialsModal: !state.showTwilioCredentialsModal,
      };

    case SHOW_WHATSAPP_CREDENTIALS_MODAL:
      return {
        ...state,
        showWhatsappCredentialsModal: !state.showWhatsappCredentialsModal,
      };

    case SHOW_OPENAI_CREDENTIALS_MODAL:
      return {
        ...state,
        showOpenAiCredentialsModal: !state.showOpenAiCredentialsModal,
      };

    default:
      return state;
  }
};

export default channels;
