import { isEmpty } from 'lodash';
import {
  axiosDel, axiosGet, axiosPatch, axiosPost,
} from '../http.service';
import { handleError } from '../errorHandler';
import { CHANNEL_TYPES } from '../../constants/channels/channels.constant';
import { showToast } from '../toastService';
import { CONVERSATIONS_LIMIT, CONVERSATIONS_MESSAGES_LIMIT } from '../../constants/channels/conversation.constant';
import { NUMBER_STATUS } from '../../constants/status.constant';

// Function to update onboarding data
export const updateConversation = async (conversationId, updateData) => {
  try {
    const response = await axiosPatch(
      `conversations/${conversationId}`,
      updateData,
    );
    return response;
  } catch (error) {
    handleError('Error updating conversation:', error);
    throw error;
  }
};

// function to get conversationMessages by conversation id
export const getConversationMessages = async (conversationId, page) => {
  try {
    const response = await axiosGet(
      `messages?page=${page}&limit=${CONVERSATIONS_MESSAGES_LIMIT}&conversationId=${conversationId}`,
    );
    if (response?.status) {
      return response;
    }
    throw new Error(response?.message || 'Failed to get conversation messages');
  } catch (error) {
    handleError('Error getting conversation messages:', error);
    throw error;
  }
};

export const queryConversation = async (page, isOpenedByUser, query, tabData, filter) => {
  try {
    const response = await axiosGet(
      `conversations?page=${page}&limit=${CONVERSATIONS_LIMIT}&sortBy=isOpenedByUser${isOpenedByUser ? `&isOpenedByUser=${isOpenedByUser}` : ''
      }${query ? `&q=${encodeURIComponent(query)}` : ''} ${!isEmpty(tabData) ? `&${tabData?.key}=${tabData?.value}` : ''} ${filter ? `&${filter}` : ''}`,
    );
    if (response?.status) {
      return response;
    }
    throw new Error(response?.message || 'Failed query conversation.');
  } catch (error) {
    handleError('Error quering conversation:', error);
    throw error;
  }
};

export const uploadMedia = async (formData, onError) => {
  try {
    const response = await axiosPost('upload', formData);
    if (response?.status) {
      return response.data;
    }
    onError();
    throw new Error(response?.message || 'Failed to upload media');
  } catch (error) {
    handleError('Error uploading media:', error);
    throw error;
  }
};

export const sendMessageOrMedia = async (conversationChannel, conversationId, updateData, onError) => {
  try {
    let endpoint;

    switch (conversationChannel) {
      case CHANNEL_TYPES.WHATSAPP:
        endpoint = 'send-whatsapp-message';
        break;
      case CHANNEL_TYPES.TEXT:
        endpoint = 'send-message';
        break;
      default:
        throw new Error(`Unsupported conversation channel: ${conversationChannel}`);
    }
    const response = await axiosPost(
      `conversations/${endpoint}/${conversationId}`,
      updateData,
    );
    if (response?.status) {
      return response.data;
    }
    if (!response.status && response?.message === NUMBER_STATUS.INACTIVE) {
      return { status: NUMBER_STATUS.INACTIVE }; // Return 'inactive' status
    }

    onError();
    showToast({
      content: response?.message || 'Oops! something went wrong',
      type: 'error',
    });
    throw new Error(response?.message || 'Failed to send media');
  } catch (error) {
    handleError('Error sending media:', error);
    throw error;
  }
};

export const deleteConversationNote = async (conversationId, noteId) => {
  try {
    const response = await axiosDel(`/conversations/${conversationId}/notes/${noteId}`);
    return response;
  } catch (error) {
    console.error('Error in deleteConversationNote:', error);
    throw error;
  }
};

export const fetchUsersByAssignedNumber = async (phoneNumber, channel) => {
  try {
    const response = await axiosGet(`/users/assignable/${phoneNumber}/${channel}`);
    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const initiateNewConversation = async (data) => {
  try {
    const response = await axiosPost(
      '/conversations/new-conversation',
      data,
    );
    if (response?.status) {
      showToast({
        content: response?.message || 'Conversation Initiated successfully!',
        type: 'success',
      });
      return response.data;
    }
    if (!response.status && response?.message === 'inactive') {
      return { status: NUMBER_STATUS.INACTIVE }; // Return 'inactive' status
    }
    showToast({
      content: response?.message || 'Oops! something went wrong',
      type: 'error',
    });
    throw new Error(response?.message || 'Failed to initiate a new conversation!');
  } catch (error) {
    handleError('Error initiating a new message:', error);
    throw error;
  }
};
