import { useDispatch } from 'react-redux';
import { getDataFromLocalStorage } from '../../helpers/commonHelpers';
import { setTwilioVoiceToken } from '../../store/actions';
import { axiosGet } from '../http.service';
import { handleError } from '../errorHandler';

export const getTwilioVoiceToken = async (organizationId) => {
  const dispatch = useDispatch();
  try {
    if (!organizationId) {
      throw new Error('Organization ID is required');
    }
    const response = await axiosGet(`organization/${organizationId}/voice-access-token`);
    if (response.status) {
      return response.data;
    }
    const accessToken = await getDataFromLocalStorage('accessToken');
    if (accessToken) {
      dispatch(setTwilioVoiceToken(accessToken));
    }
    throw new Error(response?.message || 'Failed to fetch Twilio voice token');
  } catch (error) {
    handleError('Error fetching Twilio voice token:', error);
    throw error;
  }
};

export default {
  getTwilioVoiceToken,
};
