export const CHANNELS_TITLE = 'Communication Settings';

export const CONNECT_STATUS = {
  CONNECTED: 'Connected',
  DISCONNECT: 'Disconnect',
  CONFIGURE: 'Configure',
  CONNECT: 'Connect',
  COMING_SOON: 'Coming Soon',
};

export const CHANNEL_CONNECT_STATUS = {
  TODO: 'todo',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
};

export const CHANNEL_TYPES_SETTINGS = {
  WHATSAPP: 'whatsapp',
  TWILIO: 'twilio',
};

export const CHANNEL_TYPES = {
  TEXT: 'text',
  WHATSAPP: 'whatsapp',
};

export const CONVERSATION_TYPES = {
  TEXT: 'text',
  WHATSAPP: 'whatsapp',
};
export const MEDIA_ICONS = {
  IMAGE: 'mdi mdi-image',
  VIDEO: 'mdi mdi-video',
  APPLICATION: 'mdi mdi-file-document',
  AUDIO: 'mdi mdi-microphone',
};

export const CHANNELS = {
  // Social Media
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  WHATSAPP: 'Whatsapp',
  WEBCHAT: 'Webchat',

  // Telephony
  TWILIO: 'Twilio',
  PLIVO: 'Plivo',

  // AI
  OPENAI: 'OpenAI',
  DIALOGFLOW: 'Dialogflow',
};

// Channel Categories
export const CHANNEL_CATEGORIES = {
  SOCIAL: 'Social',
  TELEPHONY: 'Telephony',
  GENERATIVE_AI: 'Generative AI',
};

export const CHANNEL_GROUPS = {
  SOCIAL: [CHANNELS.FACEBOOK, CHANNELS.INSTAGRAM, CHANNELS.WHATSAPP, CHANNELS.WEBCHAT],
  TELEPHONY: [CHANNELS.TWILIO, CHANNELS.PLIVO],
  GENERATIVE_AI: [CHANNELS.OPENAI, CHANNELS.DIALOGFLOW],
};
